import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { FormikHelpers } from "formik";
import * as yup from "yup";
import Loader from "../../common/Loader";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../Portal/HeaderPortal";
import properties from "../../../data/performance-settings.json";
import DynamicInputGroup from "../../Form/DynamicInputGroup";
import FormLayout from "../../Form/FormLayout";
import FormLayoutFooter from "../../Form/FormLayoutFooter";
import { getSettingsByGroup } from "../../Form/formUtilities";
import Switch from "../../Form/Switch";
import { IProperty } from "../../Form/models";
import {
  GetBusinessPerformanceSettingsQuery,
  UpdateBusinessPerformanceSettingsMutation,
} from "./PerformanceSettingsQueriesMutations";
import { useBusinessContext } from "../../Context/BusinessContext";
import { BusinessId } from "../../../models/common";
import {
  PerformanceSettingsQueriesMutations_PerformanceSettingsQuery,
  PerformanceSettingsQueriesMutations_PerformanceSettingsQueryResponse,
} from "./__generated__/PerformanceSettingsQueriesMutations_PerformanceSettingsQuery.graphql";
import { getHandleServerValidationErrorFn } from "../../../utils/utility";
import Checkbox from "../../Form/Checkbox";

// Hacky types to avoid false nullability in graphql typings
type PerformanceSettingsQueryResponses = Exclude<
  PerformanceSettingsQueriesMutations_PerformanceSettingsQueryResponse["businesses"]["edges"],
  null
>;
type PerformanceSettingsQueryResponse = Exclude<
  PerformanceSettingsQueryResponses[number],
  null
>;
export type PerformanceSettingResponse = Exclude<
  PerformanceSettingsQueryResponse["node"],
  null
>;

export default function PerformanceSettings() {
  const { t } = useTranslation("translation");
  const { businessInCache } = useBusinessContext();
  const business = useBusinessPerformanceSettingsQuery(businessInCache?.id);
  const filteredProperties = properties;

  const componentRules = {
    commendationEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      defaultValue: false,
      componentProps: {
        boldLabels: true,
        onLabel: t("switch.enable"),
        offLabel: t("switch.disable"),
      },
    },
    disciplinaryActionEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      defaultValue: true,
      componentProps: {
        boldLabels: true,
        onLabel: t("switch.enable"),
        offLabel: t("switch.disable"),
      },
    },
    approvalNeededForCommendation: {
      component: Checkbox,
      hideLabel: true,
      componentProps: {
        toBoolean: (v: boolean) => !v,
        fromBoolean: (v: boolean) => !v,
      },
      disabled: (values: PerformanceSettingResponse) =>
        !values.commendationEnabled,
    },
    commendationEmploymentNotifications: {
      disabled: (values: PerformanceSettingResponse) =>
        !values.commendationEnabled,
    },
    approvalNeededForDisciplinaryAction: {
      component: Checkbox,
      hideLabel: true,
      componentProps: {
        toBoolean: (v: boolean) => !v,
        fromBoolean: (v: boolean) => !v,
      },
      disabled: (values: PerformanceSettingResponse) =>
        !values.disciplinaryActionEnabled,
    },
    disciplinaryActionEmploymentNotifications: {
      disabled: (values: PerformanceSettingResponse) =>
        !values.disciplinaryActionEnabled,
    },
  };

  const validationRules = yup.object().shape(
    {
      feedbackCommentEnabled: yup
        .boolean()
        .label(t("feedback-reasons:settings.reason"))
        .when("feedbackReasonEnabled", {
          is: (val) => val !== true,
          then: yup
            .boolean()
            .test(
              "one_must_be_true",
              t("feedback-reasons:reason_comment_error"),
              (val) => val === true,
            ),
        }),
      feedbackReasonEnabled: yup
        .boolean()
        .label(t("feedback-reasons:settings.comments"))
        .when("feedbackCommentEnabled", {
          is: (val) => val !== true,
          then: yup
            .boolean()
            .test(
              "one_must_be_true",
              t("feedback-reasons:reason_comment_error"),
              (val) => val === true,
            ),
        }),
    },
    [["feedbackReasonEnabled", "feedbackCommentEnabled"]],
  );

  const [updatePerformanceSettingsMutation] = useMutation(
    UpdateBusinessPerformanceSettingsMutation,
  );

  const onSave = (
    changes: Partial<PerformanceSettingResponse>,
    errorHandler: (error: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: PerformanceSettingResponse,
    helpers?: FormikHelpers<PerformanceSettingResponse>,
  ) => {
    updatePerformanceSettingsMutation({
      variables: {
        id: business.id,
        input: changes,
      },
      onCompleted(response: any) {
        toast(t("form.notifications.saved_successfully"));
      },
      onError(error: Error) {
        if (!helpers) {
          return;
        }
        getHandleServerValidationErrorFn(helpers, {
          excludeLabelInError: true,
        })(error);
      },
    });
  };

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <span>{t("businesses:layout.nav.performanceSettings")}</span>,
          ]}
        />
      </HeaderPortal>
      <Suspense fallback={<Loader />}>
        <FormLayout<PerformanceSettingResponse>
          isCreate={false}
          base={business}
          propertyList={[]}
          componentRules={componentRules}
          validationRules={validationRules}
          onSave={onSave}
        >
          <Card body className="mb-5">
            <DynamicInputGroup
              fields={getSettingsByGroup(filteredProperties as IProperty[])}
            />
          </Card>
          <FormLayoutFooter />
        </FormLayout>
      </Suspense>
    </>
  );
}

export function useBusinessPerformanceSettingsQuery(
  businessId: BusinessId = "",
): PerformanceSettingResponse {
  const query =
    useLazyLoadQuery<PerformanceSettingsQueriesMutations_PerformanceSettingsQuery>(
      GetBusinessPerformanceSettingsQuery,
      {
        businessId,
      },
      { fetchPolicy: "network-only" },
    );

  if (!query?.businesses?.edges) {
    return {} as PerformanceSettingResponse;
  }

  return (
    query?.businesses?.edges[0]?.node ?? ({} as PerformanceSettingResponse)
  );
}
