/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RegisterStackMutationVariables = {
    domainName: string;
    stackCode: string;
};
export type RegisterStackMutationResponse = {
    readonly registerStack: {
        readonly id: string;
        readonly domainName: string;
        readonly stackCode: string;
    };
};
export type RegisterStackMutation = {
    readonly response: RegisterStackMutationResponse;
    readonly variables: RegisterStackMutationVariables;
};



/*
mutation RegisterStackMutation(
  $domainName: String!
  $stackCode: String!
) {
  registerStack(domainName: $domainName, stackCode: $stackCode) {
    id
    domainName
    stackCode
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stackCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "domainName",
        "variableName": "domainName"
      },
      {
        "kind": "Variable",
        "name": "stackCode",
        "variableName": "stackCode"
      }
    ],
    "concreteType": "Stack",
    "kind": "LinkedField",
    "name": "registerStack",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "domainName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stackCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterStackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterStackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "beb666ffc241945b6e32bf8bf9639509",
    "id": null,
    "metadata": {},
    "name": "RegisterStackMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterStackMutation(\n  $domainName: String!\n  $stackCode: String!\n) {\n  registerStack(domainName: $domainName, stackCode: $stackCode) {\n    id\n    domainName\n    stackCode\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cc19f4f4b0f430c47bacd6e0aac9d23b';
export default node;
