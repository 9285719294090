import React, { Component } from "react";
import { Environment, fetchQuery, graphql } from "react-relay";
import Card from "react-bootstrap/Card";
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLError } from "graphql";
import { Alert } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { Translation } from "react-i18next";

import UpdateRulesOnScheduleMutation, {
  UpdateRulesOnScheduleMutationInput,
} from "../../mutations/UpdateRulesOnScheduleMutation";
import { UpdateRulesOnScheduleMutationResponse } from "../../mutations/__generated__/UpdateRulesOnScheduleMutation.graphql";
import RemoveRulesOnScheduleMutation from "../../mutations/RemoveRulesOnScheduleMutation";
import { RemoveRulesOnScheduleMutationResponse } from "../../mutations/__generated__/RemoveRulesOnScheduleMutation.graphql";
import { RuleSetFetch, RuleSetFetchError, ScheduleRulesInfo } from "./models";
import RulesConfigurationForm, {
  RULES_CONFIG_VALIDATION,
} from "./RulesConfigurationForm";
import OkCancelModal from "../../../OkCancelModal";
import { BusinessContext } from "../../../Context/BusinessContext";
import Loader from "../../../common/Loader";
import { withModal, WithModalProps } from "../../../Context/ModalContextLegacy";
import HeaderPortal from "../../../Portal/HeaderPortal";

const scheduleRulesQuery = graphql`
  query RulesPage_Query($businessId: ID!, $scheduleId: ID!) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      edges {
        node {
          ruleSetName
          ruleSetVersion
          ruleSetSubscriptionStatus
          ruleSetMeta
        }
      }
    }
  }
`;

const allRulesQuery = graphql`
  query RulesPage_AllRules_Query {
    ruleSets {
      name
      description
      contents {
        version
        level
      }
    }
  }
`;

type Props = RouteComponentProps<MatchParams> & WithModalProps;

type State = {
  businessId: string;
  scheduleId: string;
  scheduleRulesInfo?: ScheduleRulesInfo;
  ruleSets: RuleSetFetch;
};

interface MatchParams {
  business_id: string;
  schedule_id: string;
}

class RulesPage extends Component<Props, State> {
  static contextType = BusinessContext;

  constructor(props: Props) {
    super(props);
    const {
      match: { params },
    } = props;

    const businessId = params.business_id;
    const scheduleId = params.schedule_id;

    this.state = {
      businessId,
      scheduleId,
      ruleSets: {
        loaded: false,
        error: null,
        loading: false,
        data: [],
      },
    };
  }

  componentDidMount() {
    const { businessId, scheduleId } = this.state;
    const { environment } = this.context;
    this.fetchConfiguredRules(environment, businessId, scheduleId);
  }

  private fetchConfiguredRules = (
    environment: Environment,
    businessId: string,
    scheduleId: string,
  ) => {
    fetchQuery(environment, scheduleRulesQuery, {
      businessId,
      scheduleId,
    })
      .toPromise()
      .then((data: any) => {
        // access the graphql response
        const schedule = data.schedules.edges[0].node;
        this.setSchedule(schedule);
      });
  };

  private fetchAllRules = (environment: Environment) => {
    if (this.state.ruleSets.loaded) {
      return;
    }
    this.setState({
      ruleSets: {
        loaded: false,
        loading: true,
        error: null,
        data: [],
      },
    });

    fetchQuery(environment, allRulesQuery, {})
      .toPromise()
      .then((data: any) => {
        this.setState({
          ruleSets: {
            loaded: true,
            loading: false,
            error: null,
            data: data.ruleSets,
          },
        });
      })
      .catch((err: any) => {
        const { source } = err;
        let errors: Array<RuleSetFetchError>;
        if (source && source.errors && source.errors.length > 0) {
          const rawErrors = source.errors as Array<GraphQLError>;
          errors = rawErrors.map((raw) => {
            return {
              message: raw.message,
              extensions: raw.extensions,
              original: raw,
            };
          });
        } else {
          errors = [{ message: "Server error", original: err }];
        }
        this.setState({
          ruleSets: {
            loaded: false,
            loading: false,
            error: errors,
            data: [],
          },
        });
      });
  };

  private setSchedule = (scheduleInfo: ScheduleRulesInfo) => {
    this.setState({
      scheduleRulesInfo: scheduleInfo,
    });
  };

  private handleSave = (values: ScheduleRulesInfo) => {
    const { environment } = this.context;
    if (values.ruleSetName == null || values.ruleSetVersion == null) {
      return;
    }
    const input: UpdateRulesOnScheduleMutationInput = {
      scheduleId: this.state.scheduleId,
      businessId: this.state.businessId,
      ruleSetName: values.ruleSetName,
      ruleSetVersion: values.ruleSetVersion,
      ruleSetSubscriptionStatus: values.ruleSetSubscriptionStatus,
    };
    UpdateRulesOnScheduleMutation(environment, input, this.onSaved);
  };

  private onSaved = (response: UpdateRulesOnScheduleMutationResponse) => {
    const schedule: ScheduleRulesInfo = {
      ruleSetName: response.updateRulesOnSchedule.ruleSetName,
      ruleSetVersion: response.updateRulesOnSchedule.ruleSetVersion,
      ruleSetSubscriptionStatus:
        response.updateRulesOnSchedule.ruleSetSubscriptionStatus,
      ruleSetMeta: response.updateRulesOnSchedule.ruleSetMeta as Record<
        string,
        any
      >,
    };
    this.setSchedule(schedule);
    toast("Saved successfully");
  };

  private handleRemoveRules = () => {
    const { showModal } = this.props;
    showModal();
  };

  private removeRules = () => {
    const { environment } = this.context;
    RemoveRulesOnScheduleMutation(
      environment,
      {
        scheduleId: this.state.scheduleId,
        businessId: this.state.businessId,
      },
      this.onRulesRemoval,
    );
  };

  private onRulesRemoval = (
    response: RemoveRulesOnScheduleMutationResponse,
  ) => {
    const schedule: ScheduleRulesInfo = {
      ruleSetName: response.removeRulesOnSchedule.ruleSetName,
      ruleSetVersion: response.removeRulesOnSchedule.ruleSetVersion,
      ruleSetSubscriptionStatus:
        response.removeRulesOnSchedule.ruleSetSubscriptionStatus,
      ruleSetMeta: response.removeRulesOnSchedule.ruleSetMeta as Record<
        string,
        any
      >,
    };
    this.setSchedule(schedule);
    toast("Rules removed successfully");
  };

  render() {
    const { ruleSets, scheduleRulesInfo: initialValues } = this.state;

    if (initialValues === undefined) {
      return <Loader />;
    }

    const { environment } = this.context;

    return (
      <>
        <HeaderPortal as="span" elementId="sub-header-portal">
          <span className="ml-2 mr-2">&gt;</span>
          <span>Rules</span>
        </HeaderPortal>
        <OkCancelModal
          title="Are you sure?"
          okLabel="Remove rules"
          onOk={this.removeRules}
        >
          <div>
            <span>
              Are you sure you want to remove rules from this schedule?
            </span>
          </div>
        </OkCancelModal>
        <Card body>
          <h1>Rules</h1>
          {ruleSets.error && (
            <Alert variant="danger">
              Error loading rules:
              <ul>
                {ruleSets.error.map((error, errorIndex) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`error-${errorIndex}`}>
                      {error.message}:{" "}
                      {error.extensions ? JSON.stringify(error.extensions) : ""}
                    </li>
                  );
                })}
              </ul>
            </Alert>
          )}
          <Translation>
            {(t) => (
              <Formik
                onSubmit={this.handleSave}
                initialValues={initialValues}
                enableReinitialize
                validationSchema={RULES_CONFIG_VALIDATION}
              >
                {(formikProps) => {
                  return (
                    <RulesConfigurationForm
                      t={t}
                      formik={formikProps}
                      fetchAllRules={() => this.fetchAllRules(environment)}
                      ruleSets={ruleSets}
                      removeRules={() => this.handleRemoveRules()}
                    />
                  );
                }}
              </Formik>
            )}
          </Translation>
        </Card>
      </>
    );
  }
}

export default withModal(RulesPage);
