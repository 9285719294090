import React from "react";
import { Environment } from "react-relay";
import { AppContextValue } from "../IDM/external/Context/AppContext";
import {
  RelayEnvironmentConfig,
  idmExternalEnvironment,
} from "../../environment";
import { StackContextLayout_QueryResponse } from "../Stack/__generated__/StackContextLayout_Query.graphql";

type NodeType = {} & StackContextLayout_QueryResponse["businesses"]["nodes"];
export type BusinessInContext = Exclude<NodeType[number], null>;

export type BusinessContextValue = {
  business?: BusinessInContext;
  businessInCache?: BusinessInContext;
  setBusiness: (business?: BusinessInContext) => void;
  appContext: AppContextValue;
  environment: Environment;
};

const BusinessContext = React.createContext<BusinessContextValue>({
  appContext: {
    setStackId: () => undefined,
    loadMyStacks: () => undefined,
  },
  environment: idmExternalEnvironment,
  setBusiness: () => undefined,
});

type Props = {
  business?: BusinessInContext;
  // May be worth replacing business with businessInCache, queries that update business object will automatically update the cache
  businessInCache?: BusinessInContext;
  appContext: AppContextValue;
  environment: Environment;
};

class BusinessContextProvider extends React.Component<
  Props,
  BusinessContextValue
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      business: props.business,
      setBusiness: this.setBusiness,
      appContext: props.appContext,
      environment: props.environment,
    };

    RelayEnvironmentConfig.BUSINESS_ID = props.business?.id;
  }

  setBusiness = (business?: BusinessInContext) => {
    this.setState({
      business,
    });
  };

  render() {
    const { children } = this.props;
    return (
      <BusinessContext.Provider
        value={{ ...this.state, businessInCache: this.props.business }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }
}

const useBusinessContext = () => React.useContext(BusinessContext);

export { BusinessContext, BusinessContextProvider, useBusinessContext };
