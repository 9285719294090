import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { FormikContext, FormikContextType } from "formik";
import { useMutation } from "react-relay";
import { useModal } from "../../Context/ModalContext";
import {
  searchCorporatesByName,
  GetCorporatesByName_Corporate,
} from "../../IDM/internal/Corporates/CorporatesQueryMutations";
import FormLayout from "../../Form/FormLayout";
import {
  GetCorporatesBusinessesByIdsQuery_Business,
  LinkBusinessToCorporate,
} from "../../IDM/internal/Businesses/BusinessesQueriesMutations";
import {
  BusinessesQueriesMutations_LinkBusinessToCorporate_MutationResponse,
  BusinessesQueriesMutations_LinkBusinessToCorporate_MutationVariables,
} from "../../IDM/internal/Businesses/__generated__/BusinessesQueriesMutations_LinkBusinessToCorporate_Mutation.graphql";

type Props = {
  idmBusiness: GetCorporatesBusinessesByIdsQuery_Business;
  onSuccess?: Function;
};

type FormData = GetCorporatesBusinessesByIdsQuery_Business;

enum DialogState {
  Default,
  Confirmation,
}

function SetCorporateModal({ idmBusiness: business, onSuccess }: Props) {
  const { t } = useTranslation("businesses");
  const { hideModal } = useModal();

  const [linkBusinessToCorporateMutation] = useMutation<{
    response: BusinessesQueriesMutations_LinkBusinessToCorporate_MutationResponse;
    variables: BusinessesQueriesMutations_LinkBusinessToCorporate_MutationVariables;
  }>(LinkBusinessToCorporate);

  const [dialogState, setDialogState] = useState<DialogState>(
    DialogState.Default,
  );

  const onBack = () => {
    setDialogState(DialogState.Default);
  };

  const onSave = (
    changes: Partial<FormData>,
    errorHandler: (error: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: FormData,
  ) => {
    if (dialogState === DialogState.Default) {
      setDialogState(DialogState.Confirmation);
      return;
    }

    // after clicking save on confirmation state
    const corporateId = values?.corporate?.id ?? null;
    linkBusinessToCorporateMutation({
      variables: {
        id: business.id,
        corporateId,
      },
      onCompleted: () => {
        toast(t("translation:form.notifications.created_successfully"));
        hideModal();
        if (onSuccess) {
          onSuccess();
        }
      },
      onError(error: Error) {
        errorHandler(error);
      },
    });
  };

  return (
    <Modal show onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("setCorporateModal.title")}</Modal.Title>
      </Modal.Header>
      <FormLayout<FormData>
        formId="set-corporate"
        base={business}
        onSave={onSave}
        propertyList={[]}
        hidePrompt
        isCreate
      >
        <FormikContext.Consumer>
          {({ values, setFieldValue, dirty }: FormikContextType<FormData>) => {
            return (
              <>
                <Modal.Body>
                  {dialogState === DialogState.Default ? (
                    <Form.Group>
                      <Form.Label>
                        {t("setCorporateModal.corporate.label")}
                      </Form.Label>
                      <AsyncSelect<GetCorporatesByName_Corporate>
                        isClearable
                        cacheOptions
                        defaultOptions
                        isSearchable
                        value={values.corporate}
                        onChange={(i) => {
                          setFieldValue("corporate", i);
                        }}
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(i) => i.id}
                        loadOptions={(search) => searchCorporatesByName(search)}
                        backspaceRemoves
                        placeholder={t(
                          "setCorporateModal.corporate.placeholder",
                        )}
                      />
                    </Form.Group>
                  ) : (
                    <div>{t("setCorporateModal.confirmation")}</div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  {dialogState === DialogState.Confirmation && (
                    <Button variant="link" className="mr-4" onClick={onBack}>
                      {t("setCorporateModal.buttons.back")}
                    </Button>
                  )}
                  <Button variant="link" className="mr-4" onClick={hideModal}>
                    {t("setCorporateModal.buttons.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    form="set-corporate"
                    disabled={!dirty}
                  >
                    {t(
                      dialogState === DialogState.Default
                        ? "setCorporateModal.buttons.save"
                        : "setCorporateModal.buttons.saveConfirmation",
                    )}
                  </Button>
                </Modal.Footer>
              </>
            );
          }}
        </FormikContext.Consumer>
      </FormLayout>
    </Modal>
  );
}

export default SetCorporateModal;
