import { fetchQuery, graphql } from "react-relay";
import { Id, IUser } from "../../../../models/common";

import { AccountService_Users_Query } from "./__generated__/AccountService_Users_Query.graphql";
import { AccountService_UsersSearch_Query } from "./__generated__/AccountService_UsersSearch_Query.graphql";

import { idmInternalEnvironment } from "../../../../environment";

const usersByIdsQuery = graphql`
  query AccountService_Users_Query($ids: [ID!]) {
    users(ids: $ids) {
      edges {
        node {
          id
          email
          confirmedAt
        }
      }
    }
  }
`;

const usersSearchByEmailQuery = graphql`
  query AccountService_UsersSearch_Query($email: String!) {
    users(email: $email) {
      edges {
        node {
          id
          email
          confirmedAt
          deleted
        }
      }
    }
  }
`;

export default class AccountService {
  /**
   * Fetch users with the specified ids
   * @param ids an array of user ids
   * @returns a map of users keyed by user id
   */
  public static async fetchUsersByIds(
    ids: Id[],
  ): Promise<Map<Id, IUser> | null> {
    const result = await fetchQuery<AccountService_Users_Query>(
      idmInternalEnvironment,
      usersByIdsQuery,
      {
        ids,
      },
    ).toPromise();

    if (!result || !result.users || !result.users.edges) {
      return null;
    }

    const usersById = new Map();
    result.users.edges.forEach((i) => {
      if (i && i.node) {
        usersById.set(i.node.id, i.node);
      }
    });

    return usersById;
  }

  /**
   * Fetch users with the specified id
   * @param id id of user to fetch
   * @returns User object matching specified id
   */
  public static async fetchUserById(id: Id): Promise<IUser | null> {
    const result = await fetchQuery<AccountService_Users_Query>(
      idmInternalEnvironment,
      usersByIdsQuery,
      {
        ids: [id],
      },
    ).toPromise();

    return result?.users?.edges?.[0]?.node ?? null;
  }

  /**
   * Search for users matching email
   * @param email part of email address to match
   * @returns a map of users keyed by user id
   */
  public static async searchUsersByEmail(
    email: string,
  ): Promise<Map<Id, IUser> | null> {
    const result = await fetchQuery<AccountService_UsersSearch_Query>(
      idmInternalEnvironment,
      usersSearchByEmailQuery,
      {
        email,
      },
    ).toPromise();

    if (!result || !result.users || !result.users.edges) {
      return null;
    }

    const usersById = new Map();
    result.users.edges.forEach((i) => {
      if (i && i.node) {
        usersById.set(i.node.id, i.node);
      }
    });

    return usersById;
  }
}
