import React, { FunctionComponent } from "react";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import Table from "react-bootstrap/Table";
import { match } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StackTable_viewer } from "./__generated__/StackTable_viewer.graphql";
import StackRow from "./StackRow";
import RelayLazyLoader from "../../../common/RelayLazyLoader";

const StackTablePageQuery = graphql`
  query StackTable_InternalQuery($pageSize: Int!, $after: String) {
    ...StackTable_viewer
  }
`;

type Props = {
  searchValue: string;
  viewer: StackTable_viewer;
  relay: RelayPaginationProp;
  match: match<any>;
};

const StackTableBase: FunctionComponent<Props> = ({
  relay,
  match: m,
  viewer,
  searchValue,
}) => {
  const { t } = useTranslation("stacks");
  const nodes = (viewer.stacks.edges || []).map((edge: any) => (
    <StackRow match={m} key={edge.node.__id} viewer={edge.node} />
  ));

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("stacksTable.headers.domainName")}</th>
            <th>{t("stacksTable.headers.code")}</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </Table>
      <RelayLazyLoader relay={relay} />
    </>
  );
};

export default createPaginationContainer(
  StackTableBase,
  {
    viewer: graphql`
      fragment StackTable_viewer on InternalQuery {
        stacks(first: $pageSize, after: $after)
          @connection(key: "StackTable_stacks") {
          edges {
            node {
              ...StackRow_viewer
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: StackTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.stacks,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      searchValue: props.searchValue,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
