import React, { Suspense, useEffect } from "react";
import { PreloadedQuery, useQueryLoader } from "react-relay";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../Portal/HeaderPortal";
import {
  GetSingleTimeOffTypeQuery,
  GetTimeOffBalancesForTypeQuery,
} from "../TimeOffTypesQueryMutations";
import Loader from "../../../common/Loader";
import { useBusinessContext } from "../../../Context/BusinessContext";
import TimeOffTypesProfileForm from "./TimeOffTypesProfileForm";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { GetEmploymentTypeConfigsQuery } from "../../EmploymentTypes/EmploymentTypesQueriesMutations";

type MatchParams = {
  time_off_type_id: string;
};

type Props = {
  initialQueryRef: PreloadedQuery<any>;
};

export default function TimeOffTypesProfile({ initialQueryRef }: Props) {
  const { t } = useTranslation("time-off-types");
  const businessContext = useBusinessContext();
  const router = useAppRouter<MatchParams>();

  const { time_off_type_id: timeOffTypeId } = router.params;
  const goBackUrl = router.getGoBackUrl("/time_off_types");

  const [timeOffTypeQueryRef, loadTimeOffTypesQuery, disposeTimeOffTypesQuery] =
    useQueryLoader(GetSingleTimeOffTypeQuery, initialQueryRef);
  const [
    timeOffTypeBalancesQueryRef,
    loadTimeOffBalancesQuery,
    disposeTimeOffBalancesQuery,
  ] = useQueryLoader(GetTimeOffBalancesForTypeQuery, initialQueryRef);
  const [
    employmentTypeConfigsQueryRef,
    loadEmploymentTypeConfigsQuery,
    disposeEmploymentTypeConfigsQuery,
  ] = useQueryLoader(GetEmploymentTypeConfigsQuery, initialQueryRef);

  useEffect(() => {
    if (businessContext.business?.id) {
      loadTimeOffTypesQuery(
        {
          businessId: businessContext.business?.id,
          ids: [timeOffTypeId],
          // skip if we're creating a new leave type
          skip: timeOffTypeId == null,
        },
        {
          fetchPolicy: "store-and-network",
        },
      );
      loadTimeOffBalancesQuery(
        {
          businessId: businessContext.business?.id,
          timeOffTypeIds: [timeOffTypeId],
          // skip if we're creating a new leave type
          skip: timeOffTypeId == null,
        },
        {
          fetchPolicy: "store-and-network",
        },
      );
      loadEmploymentTypeConfigsQuery(
        {
          businessId: businessContext.business?.id,
          deleted: false,
        },
        {
          fetchPolicy: "network-only",
        },
      );
    }
    return () => {
      disposeTimeOffBalancesQuery();
      disposeTimeOffTypesQuery();
      disposeEmploymentTypeConfigsQuery();
    };
  }, [
    timeOffTypeId,
    businessContext,
    loadTimeOffTypesQuery,
    loadTimeOffBalancesQuery,
    disposeTimeOffBalancesQuery,
    disposeTimeOffTypesQuery,
    loadEmploymentTypeConfigsQuery,
    disposeEmploymentTypeConfigsQuery,
  ]);

  return (
    <Suspense
      fallback={
        <>
          <HeaderPortal as="span">
            <HeaderPortalBreadcrumbs
              breadcrumbs={[
                <Link to={goBackUrl}>
                  <span>{t("nav.timeOffTypes")}</span>
                </Link>,
                <span> </span>,
              ]}
            />
          </HeaderPortal>
          <Loader />
        </>
      }
    >
      {timeOffTypeQueryRef != null &&
        timeOffTypeBalancesQueryRef != null &&
        employmentTypeConfigsQueryRef != null && (
          <Card body>
            <TimeOffTypesProfileForm
              businessContext={businessContext}
              queryReferences={{
                timeOffTypes: timeOffTypeQueryRef,
                timeOffBalances: timeOffTypeBalancesQueryRef,
                employmentTypeConfigs: employmentTypeConfigsQueryRef,
              }}
            />
          </Card>
        )}
    </Suspense>
  );
}
