/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountRow_viewer = {
    readonly id: string;
    readonly email: string;
    readonly confirmedAt: string | null;
    readonly confirmationSentAt: string | null;
    readonly confirmationToken: unknown | null;
    readonly lockedMinutesRemaining: number | null;
    readonly deleted: boolean;
    readonly locale: string;
    readonly lastSignInAt: string | null;
    readonly acceptedPolicyAt: string | null;
    readonly acceptedPolicyVersion: string | null;
    readonly updatedAt: string;
    readonly userStacks: ReadonlyArray<{
        readonly stackId: string;
        readonly synced: boolean;
    }>;
    readonly " $refType": "AccountRow_viewer";
};
export type AccountRow_viewer$data = AccountRow_viewer;
export type AccountRow_viewer$key = {
    readonly " $data"?: AccountRow_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AccountRow_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountRow_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmationSentAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmationToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedMinutesRemaining",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastSignInAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptedPolicyAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptedPolicyVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserStack",
      "kind": "LinkedField",
      "name": "userStacks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stackId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "synced",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '07ee50b00d9b9416a7a84939e1283341';
export default node;
