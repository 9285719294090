/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BreakTypeQueriesMutations_DeactivateBreakType_MutationVariables = {
    businessId: string;
    id: string;
};
export type BreakTypeQueriesMutations_DeactivateBreakType_MutationResponse = {
    readonly deactivateBreakType: boolean;
};
export type BreakTypeQueriesMutations_DeactivateBreakType_Mutation = {
    readonly response: BreakTypeQueriesMutations_DeactivateBreakType_MutationResponse;
    readonly variables: BreakTypeQueriesMutations_DeactivateBreakType_MutationVariables;
};



/*
mutation BreakTypeQueriesMutations_DeactivateBreakType_Mutation(
  $businessId: ID!
  $id: ID!
) {
  deactivateBreakType(businessId: $businessId, id: $id)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deactivateBreakType",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueriesMutations_DeactivateBreakType_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BreakTypeQueriesMutations_DeactivateBreakType_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b9f2067b56543480962a9e81f486b14f",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueriesMutations_DeactivateBreakType_Mutation",
    "operationKind": "mutation",
    "text": "mutation BreakTypeQueriesMutations_DeactivateBreakType_Mutation(\n  $businessId: ID!\n  $id: ID!\n) {\n  deactivateBreakType(businessId: $businessId, id: $id)\n}\n"
  }
};
})();
(node as any).hash = 'b5feae8104719e2196f56bd920e4ac6d';
export default node;
