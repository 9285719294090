import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { Translation, withTranslation, WithTranslation } from "react-i18next";
import { ValueType } from "react-select";
import { TFunction } from "i18next";
import isFunction from "lodash/isFunction";
import { ModalProps } from "../../Context/ModalContextLegacy";
import { Id } from "../../../models/common";
import { BusinessContext } from "../../Context/BusinessContext";
import { EmploymentLayoutInfo, User } from "./models";
import LinkUserToEmploymentMutation from "../mutations/LinkUserToEmploymentMutation";
import { LinkUserToEmploymentMutationResponse } from "../mutations/__generated__/LinkUserToEmploymentMutation.graphql";
import ServerError from "../../../utils/server-error";
import UserService from "../../IDM/internal/Account/AccountService";

type State = ModalProps & {
  user?: User;
  error?: string;
};

class LinkToUserModal extends React.Component<Props, State> {
  static contextType = BusinessContext;

  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    const temp = { value: undefined };
    this.state = { ...temp, ...props.modalProps };
  }

  private onOk(e: React.MouseEvent<HTMLButtonElement>) {
    const { onOk, employment, t, businessId } = this.props;
    const { environment } = this.context;
    const { user } = this.state;
    const {
      modalProps: { hideModal },
    } = this.props;

    if (!user || !user.id) {
      return;
    }

    if (employment) {
      LinkUserToEmploymentMutation(
        environment,
        businessId,
        user.id,
        employment.id,
        (response: LinkUserToEmploymentMutationResponse) => {
          const { linkUserToEmployment } = response;
          if (linkUserToEmployment) {
            toast(t("employment:linkUserModal.linkSuccess"));
            if (isFunction(onOk)) {
              onOk(e, user);
            }
            hideModal();
          }
        },
        (error: Error) => {
          const { source } = (error as any) || {};
          if (!source) {
            return;
          }
          const serverError = new ServerError(source);
          this.setState({
            error: serverError.getErrorDetails(),
          });
        },
      );
    }
  }

  private onChange(user: ValueType<User, false>) {
    if (!user) {
      this.setState({
        user: undefined,
        error: undefined,
      });

      return null;
    }

    const linkedUser = user as User;
    this.setState({ user: linkedUser, error: undefined });
    return linkedUser.id;
  }

  // eslint-disable-next-line class-methods-use-this
  private async getUsers(searchValue: string) {
    if (!searchValue) {
      return Promise.resolve([]);
    }

    const data = await UserService.searchUsersByEmail(searchValue);
    return Array.from(data ? data.values() : []);
  }

  render() {
    const { modalProps, linkedUser } = this.props;
    const { error } = this.state;
    const { show, hideModal } = modalProps;

    const getStatus = (t: TFunction, user: User) => {
      if (user.confirmedAt == null) {
        return t("employment:unconfirmed");
      }

      if (user.deleted) {
        return t("employment:deleted");
      }

      return "";
    };

    return (
      <Translation>
        {(t) => (
          <Modal show={show} onHide={hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Link User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {linkedUser ? (
                <i>
                  {t("employment:linkUserModal.linkedToUser", {
                    email: linkedUser.email,
                    status: getStatus(t, linkedUser),
                  })}
                </i>
              ) : null}
              <Form.Group>
                <Form.Label>
                  {t("employment:linkUserModal.userLabel")}
                </Form.Label>
                <AsyncSelect<User>
                  isClearable
                  cacheOptions
                  defaultOptions
                  isSearchable
                  onChange={this.onChange.bind(this)}
                  getOptionLabel={(user) =>
                    t("employment:linkUserModal.userOption", {
                      email: user.email,
                      status: getStatus(t, user),
                    })
                  }
                  getOptionValue={(d) => d.id}
                  loadOptions={this.getUsers.bind(this)}
                  backspaceRemoves
                  placeholder={t("employment:linkUserModal.userPlaceholder")}
                />
                {error ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {error}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hideModal}>
                {t("form.actions.cancel")}
              </Button>
              <Button variant="primary" onClick={this.onOk.bind(this)}>
                {t("form.actions.ok")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Translation>
    );
  }
}

type Props = typeof LinkToUserModal.defaultProps &
  WithTranslation & {
    onOk?: (e: React.MouseEvent<HTMLButtonElement>, user: User) => void;
    modalProps: State;
    employment?: EmploymentLayoutInfo;
    linkedUser: User | null;
    businessId: Id;
  };

export default withTranslation()(LinkToUserModal);
