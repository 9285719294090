import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import React from "react";

export default function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon color="black" icon={faCaretDown} size="sm" />
    </components.DropdownIndicator>
  );
}
