import { commitMutation, graphql, Environment } from "react-relay";

const mutation = graphql`
  mutation SignOutMutation {
    signOut
  }
`;

export default (
  environment: Environment,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {};

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError,
  });
};
