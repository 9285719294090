/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type StackProfile_QueryVariables = {
    stackId: string;
};
export type StackProfile_QueryResponse = {
    readonly stacks: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"Profile_stack">;
            } | null;
        } | null> | null;
    };
};
export type StackProfile_Query = {
    readonly response: StackProfile_QueryResponse;
    readonly variables: StackProfile_QueryVariables;
};



/*
query StackProfile_Query(
  $stackId: ID!
) {
  stacks(ids: [$stackId]) {
    edges {
      node {
        ...Profile_stack
        id
      }
    }
  }
}

fragment Profile_stack on Stack {
  id
  domainName
  stackCode
  deleted
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stackId"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "ids.0",
        "variableName": "stackId"
      }
    ],
    "kind": "ListValue",
    "name": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StackProfile_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StackConnection",
        "kind": "LinkedField",
        "name": "stacks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StackEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Stack",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Profile_stack"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StackProfile_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StackConnection",
        "kind": "LinkedField",
        "name": "stacks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StackEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Stack",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stackCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deleted",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a318085996af62ded814e16469e23d89",
    "id": null,
    "metadata": {},
    "name": "StackProfile_Query",
    "operationKind": "query",
    "text": "query StackProfile_Query(\n  $stackId: ID!\n) {\n  stacks(ids: [$stackId]) {\n    edges {\n      node {\n        ...Profile_stack\n        id\n      }\n    }\n  }\n}\n\nfragment Profile_stack on Stack {\n  id\n  domainName\n  stackCode\n  deleted\n}\n"
  }
};
})();
(node as any).hash = '01b69d01b2dcbe1de04b138ed7f4580b';
export default node;
