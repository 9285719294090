import React, { FunctionComponent, useEffect, useState } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import HeaderPortal from "../../Portal/HeaderPortal";
import { getRegionalStackEnvironment } from "../../../environment";
import { GlobalBusiness_InternalQuery } from "./__generated__/GlobalBusiness_InternalQuery.graphql";
import Loader from "../../common/Loader";
import { useAppContext } from "../../IDM/external/Context/AppContext";
import Searchable from "../../Searchable/Searchable";
import GlobalBusinessTable from "./GlobalBusinessTable";
import BusinessService, {
  GlobalBusinessStack,
} from "../Services/BusinessService";

const GlobalBusinessesQuery = graphql`
  query GlobalBusiness_InternalQuery(
    $globalBusinessName: String
    $pageSize: Int!
    $after: String
  ) {
    ...GlobalBusinessTable_viewer
  }
`;

interface MatchParams {
  stack_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const GlobalBusiness: FunctionComponent<Props> = (props: Props) => {
  const {
    match: {
      params: { stack_id: stackId },
    },
    ...rest
  } = props;

  const { t } = useTranslation("global-business");
  const [searchValue, setSearchValue] = useState<string>("");
  const { myStacksById } = useAppContext();
  const [globalBusinessStack, setGlobalBusinessStack] =
    useState<GlobalBusinessStack | null>(null);

  const stack = stackId && myStacksById ? myStacksById.get(stackId) : null;

  useEffect(() => {
    async function load() {
      const currentStackEnvironment = getRegionalStackEnvironment(
        stack?.domainName,
      );

      const currentGlobalBusinessStack = currentStackEnvironment
        ? await BusinessService.getGlobalBusinessStack(currentStackEnvironment)
        : null;

      setGlobalBusinessStack(currentGlobalBusinessStack ?? null);
    }

    load();
  }, [stack]);

  if (!stack) {
    // logged in user doesn't have access to current stack
    return null;
  }

  // can manage corporates if global business stack is current stack
  const isManagable = globalBusinessStack ? globalBusinessStack.isSelf : false;

  return (
    <>
      <Row className="mt-2 mb-2">
        <Col>
          <Searchable
            className="corporates"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            placeholder={t("table.search")}
          />
        </Col>
        {isManagable && (
          <Col md="auto">
            <LinkContainer to={`/stack/${stackId}/global_business/create`}>
              <Button>{t("table.new")}</Button>
            </LinkContainer>
          </Col>
        )}
      </Row>

      <Card body>
        <QueryRenderer<GlobalBusiness_InternalQuery>
          environment={getRegionalStackEnvironment(stack.domainName)}
          query={GlobalBusinessesQuery}
          variables={{
            globalBusinessName: searchValue,
            pageSize: 20,
          }}
          render={({ error, props: response }) => {
            if (error) {
              // TODO: handle specific error when global stack is unreachable
              return <div>Error!</div>;
            }
            if (!response) {
              return <Loader />;
            }

            return (
              <GlobalBusinessTable
                {...rest}
                searchValue={searchValue}
                viewer={response}
                match={props.match}
                isManagable={isManagable}
              />
            );
          }}
        />
      </Card>
      <HeaderPortal as="span">{t("layout.title")}</HeaderPortal>
    </>
  );
};

export default GlobalBusiness;
