/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment = {
    readonly id: string;
    readonly feedbackType: EmploymentFeedbackTypeEnum;
    readonly description: string;
    readonly defaultMessage: string | null;
    readonly deleted: boolean;
    readonly " $refType": "FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment";
};
export type FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment$data = FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment;
export type FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment$key = {
    readonly " $data"?: FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackReasonsQueriesMutations_EmploymentFeedbackReasonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedbackType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    }
  ],
  "type": "EmploymentFeedbackReason",
  "abstractKey": null
};
(node as any).hash = 'b933cd2499f4b9f508e1ac7003bcf3de';
export default node;
