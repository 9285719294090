import React from "react";
import { usePreloadedQuery, PreloadedQuery, useMutation } from "react-relay";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../Portal/HeaderPortal";
import {
  CreateTimeOffTypeTypeMutation,
  DeleteTimeOffTypeMutation,
  GetSingleTimeOffTypeQuery,
  GetTimeOffBalancesForTypeQuery,
  UpdateTimeOffTypeMutation,
} from "../TimeOffTypesQueryMutations";
import {
  BusinessContextValue,
  useBusinessContext,
} from "../../../Context/BusinessContext";
import { TimeOffTypesQueryMutations_GetSingleTimeOffType_Query } from "../__generated__/TimeOffTypesQueryMutations_GetSingleTimeOffType_Query.graphql";
import FormLayout from "../../../Form/FormLayout";
import FormLayoutFooter from "../../../Form/FormLayoutFooter";
import {
  getFieldsByNames,
  getSettingsByGroup,
} from "../../../Form/formUtilities";
import DynamicInputGroup from "../../../Form/DynamicInputGroup";
import { ComponentRule, IProperty } from "../../../Form/models";
import properties from "../../../../data/time-off-type-settings.json";
import { TimeOffType } from "../../../../generated/stack_internal_schema";
import { useModal } from "../../../Context/ModalContext";
import { DeleteTimeOffTypeModal } from "./DeleteTimeOffTypeModal";
import { TimeOffTypesQueryMutations_GetTimeOffBalances_Query } from "../__generated__/TimeOffTypesQueryMutations_GetTimeOffBalances_Query.graphql";
import { UnableToDeleteTimeOffTypeModal } from "./UnableToDeleteTimeOffTypeModal";
import MultiSelectList, {
  MultiSelectType,
} from "../../../Form/MultiSelectList";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { GetEmploymentTypeConfigsQuery } from "../../EmploymentTypes/EmploymentTypesQueriesMutations";
import { EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query } from "../../EmploymentTypes/__generated__/EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query.graphql";

type Props = {
  queryReferences: {
    timeOffTypes: PreloadedQuery<TimeOffTypesQueryMutations_GetSingleTimeOffType_Query>;
    timeOffBalances: PreloadedQuery<TimeOffTypesQueryMutations_GetTimeOffBalances_Query>;
    employmentTypeConfigs: PreloadedQuery<any>;
  };
  businessContext: BusinessContextValue;
};

const validationRules = yup.object({
  name: yup.string().required(),
  shortName: yup.string().nullable().max(3),
});

export default function TimeOffTypesProfileForm({
  queryReferences,
  businessContext,
}: Props) {
  const router = useAppRouter();
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation("time-off-types");
  const showEmploymentTypes = useShowEmploymentTypeSection();

  const {
    timeOffType,
    isCreate,
    timeOffBalanceCount,
    mutations: { createOrEditMutation, deleteMutation },
  } = useTimeOffType(queryReferences);

  const [employmentTypeConfigsSelectOptions] =
    useEmploymentTypeConfigsSelectOptions(
      queryReferences.employmentTypeConfigs,
    );

  const goBackUrl = router.getGoBackUrl("/time_off_types");

  const componentRules: Record<string, ComponentRule> = {
    employmentTypeCodes: {
      component: MultiSelectList,
      componentProps: {
        allOptions: employmentTypeConfigsSelectOptions,
        selectableOptions: employmentTypeConfigsSelectOptions,
        menuPlacement: "top",
        multiStyle: MultiSelectType.Pill,
        placeholder: t("form.employmentTypesPlaceholder"),
      },
    },
  };

  const onSaveClick = (
    data: Partial<TimeOffType>,
    errorHandler: (err: Error) => void,
  ) => {
    const existingData = {
      ...(timeOffType ?? {}),
      id: undefined,
    };

    createOrEditMutation({
      variables: {
        businessId: businessContext?.business?.id ?? "",
        input: {
          ...existingData,
          ...data,
        },
        ...(timeOffType?.id ? { id: timeOffType.id } : {}),
      },
      onCompleted() {
        toast(isCreate ? t("toast.created") : t("toast.updated"));
        router.replace(goBackUrl);
      },
      onError(error: Error) {
        errorHandler(error);
      },
    });
  };

  const showDeleteModal = () => {
    const onDelete = () => {
      deleteMutation({
        variables: {
          businessId: businessContext?.business?.id ?? "",
          id: timeOffType.id,
        },
        onCompleted() {
          toast(t("toast.deleted"));
          hideModal();
          router.replace(goBackUrl);
        },
        onError(error: Error) {
          hideModal();
          alert(error);
        },
      });
    };

    showModal(
      <DeleteTimeOffTypeModal
        onOk={onDelete}
        onClose={hideModal}
        timeOffTypeName={timeOffType.name}
      />,
    );
  };

  const onDeleteClick = () => {
    const canDelete = timeOffBalanceCount === 0;

    if (canDelete) {
      showDeleteModal();
    } else {
      // Cannot delete if > 1 employees have assigned stuff
      showModal(
        <UnableToDeleteTimeOffTypeModal
          employeeCount={timeOffBalanceCount}
          timeOffTypeName={timeOffType.name}
          onOk={hideModal}
          onClose={hideModal}
        />,
      );
    }
  };

  // Hide employment input group if showEmploymentTypes business setting is not true
  const inputGroupProperties = properties.filter((jsonProperty) =>
    !showEmploymentTypes ? jsonProperty.key !== "employmentTypes" : true,
  );

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <Link to={goBackUrl}>
              <span>{t("nav.timeOffTypes")}</span>
            </Link>,
            <span>{timeOffType?.name ?? t("create.title")}</span>,
          ]}
        />
      </HeaderPortal>
      <FormLayout<TimeOffType>
        isCreate={isCreate}
        base={timeOffType}
        onSave={onSaveClick}
        propertyList={[]}
        validationRules={validationRules}
        componentRules={componentRules}
      >
        <DynamicInputGroup
          fields={getSettingsByGroup(
            getFieldsByNames(inputGroupProperties as unknown as IProperty[], [
              "name",
              "shortName",
              "code",
            ]),
          )}
        />

        <DynamicInputGroup
          fields={getSettingsByGroup(
            getFieldsByNames(inputGroupProperties as unknown as IProperty[], [
              "paid",
              "ignoreAutoReject",
              "isManagerByProxyOnly",
              "employmentTypeCodes",
            ]),
          )}
        />

        <FormLayoutFooter isCreate={isCreate} onDelete={onDeleteClick} />
      </FormLayout>
    </>
  );
}

function useTimeOffType(queryReferences: {
  timeOffTypes: PreloadedQuery<TimeOffTypesQueryMutations_GetSingleTimeOffType_Query>;
  timeOffBalances: PreloadedQuery<TimeOffTypesQueryMutations_GetTimeOffBalances_Query>;
}) {
  const { timeOffTypes } =
    usePreloadedQuery<TimeOffTypesQueryMutations_GetSingleTimeOffType_Query>(
      GetSingleTimeOffTypeQuery,
      queryReferences.timeOffTypes,
    );

  const { timeOffBalances } =
    usePreloadedQuery<TimeOffTypesQueryMutations_GetTimeOffBalances_Query>(
      GetTimeOffBalancesForTypeQuery,
      queryReferences.timeOffBalances,
    );

  const defaultTimeOffValues = {
    ignoreAutoReject: false,
    isManagerByProxyOnly: false,
    paid: false,
  };

  const timeOffType = (timeOffTypes?.nodes ?? [])[0];
  const isCreate = timeOffType == null;

  const [createOrEditMutation] = useMutation(
    timeOffType != null
      ? UpdateTimeOffTypeMutation
      : CreateTimeOffTypeTypeMutation,
  );
  const [deleteMutation] = useMutation(DeleteTimeOffTypeMutation);

  return {
    timeOffType: (timeOffType ?? defaultTimeOffValues) as TimeOffType,
    isCreate,
    timeOffBalanceCount: (timeOffBalances?.nodes ?? []).length,
    mutations: {
      createOrEditMutation,
      deleteMutation,
    },
  };
}

function useShowEmploymentTypeSection() {
  // Using the original business object results in stale data if you update the setting without refreshing
  const { businessInCache } = useBusinessContext();
  return businessInCache?.contractsUseEmploymentType;
}

function useEmploymentTypeConfigsSelectOptions(
  queryReference: PreloadedQuery<any>,
) {
  const query =
    usePreloadedQuery<EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query>(
      GetEmploymentTypeConfigsQuery,
      queryReference,
    );
  const employmentTypeConfigsData = query.employmentTypeConfigs.nodes ?? [];
  const employmentTypesOptions = employmentTypeConfigsData.map((config) => {
    return { label: config?.name, value: config?.employmentTypeCode };
  });
  return [employmentTypesOptions] as const;
}
