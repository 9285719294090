import React from "react";
import { RelayPaginationProp } from "react-relay";
import LazyLoader from "./LazyLoader";

/**
 * Lazy loader using RelayPaginationProp framework
 * @param relay
 * @returns loader component that handle loading more data when intersect
 */
export default function RelayLazyLoader({
  relay,
}: {
  relay: RelayPaginationProp;
}) {
  return (
    <LazyLoader
      isLoading={relay.isLoading}
      hasMore={relay.hasMore}
      loadMore={relay.loadMore}
    />
  );
}
