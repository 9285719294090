/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GlobalBusiness_InternalQueryVariables = {
    globalBusinessName?: string | null | undefined;
    pageSize: number;
    after?: string | null | undefined;
};
export type GlobalBusiness_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"GlobalBusinessTable_viewer">;
};
export type GlobalBusiness_InternalQuery = {
    readonly response: GlobalBusiness_InternalQueryResponse;
    readonly variables: GlobalBusiness_InternalQueryVariables;
};



/*
query GlobalBusiness_InternalQuery(
  $globalBusinessName: String
  $pageSize: Int!
  $after: String
) {
  ...GlobalBusinessTable_viewer
}

fragment GlobalBusinessTable_viewer on InternalQuery {
  globalBusinesses(globalBusinessName: $globalBusinessName, first: $pageSize, after: $after) {
    edges {
      node {
        id
        name
        employmentUidNext
        employmentUidStart
        used
        createdByUser {
          id
          email
        }
        updatedByUser {
          id
          email
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "globalBusinessName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "globalBusinessName",
    "variableName": "globalBusinessName"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalBusiness_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "GlobalBusinessTable_viewer"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GlobalBusiness_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GlobalBusinessConnection",
        "kind": "LinkedField",
        "name": "globalBusinesses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalBusinessEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GlobalBusiness",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentUidNext",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentUidStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "used",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdByUser",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "updatedByUser",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "globalBusinessName"
        ],
        "handle": "connection",
        "key": "GlobalBusinessTable_globalBusinesses",
        "kind": "LinkedHandle",
        "name": "globalBusinesses"
      }
    ]
  },
  "params": {
    "cacheID": "bbcba57d9c94ac68c8af523cc751a27c",
    "id": null,
    "metadata": {},
    "name": "GlobalBusiness_InternalQuery",
    "operationKind": "query",
    "text": "query GlobalBusiness_InternalQuery(\n  $globalBusinessName: String\n  $pageSize: Int!\n  $after: String\n) {\n  ...GlobalBusinessTable_viewer\n}\n\nfragment GlobalBusinessTable_viewer on InternalQuery {\n  globalBusinesses(globalBusinessName: $globalBusinessName, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        name\n        employmentUidNext\n        employmentUidStart\n        used\n        createdByUser {\n          id\n          email\n        }\n        updatedByUser {\n          id\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '752fec7bb010b3bd0667f957fd7f611b';
export default node;
