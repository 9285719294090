/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SkillLevelAssignmentActionEnum = "none" | "warn" | "%future added value";
export type SkillLevelUpdateInput = {
    aosLevel?: number | null | undefined;
    assignmentAction?: SkillLevelAssignmentActionEnum | null | undefined;
    isDefault?: boolean | null | undefined;
    level?: string | null | undefined;
    name?: string | null | undefined;
};
export type SetAsDefaultModal_UpdateSkillLevelMutationVariables = {
    businessId: string;
    id: string;
    input: SkillLevelUpdateInput;
};
export type SetAsDefaultModal_UpdateSkillLevelMutationResponse = {
    readonly updateSkillLevel: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Profile_skillLevel">;
    };
};
export type SetAsDefaultModal_UpdateSkillLevelMutation = {
    readonly response: SetAsDefaultModal_UpdateSkillLevelMutationResponse;
    readonly variables: SetAsDefaultModal_UpdateSkillLevelMutationVariables;
};



/*
mutation SetAsDefaultModal_UpdateSkillLevelMutation(
  $businessId: ID!
  $id: ID!
  $input: SkillLevelUpdateInput!
) {
  updateSkillLevel(businessId: $businessId, id: $id, input: $input) {
    id
    ...Profile_skillLevel
  }
}

fragment Profile_skillLevel on SkillLevel {
  name
  isDefault
  level
  aosLevel
  assignmentAction
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetAsDefaultModal_UpdateSkillLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SkillLevel",
        "kind": "LinkedField",
        "name": "updateSkillLevel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Profile_skillLevel"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetAsDefaultModal_UpdateSkillLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SkillLevel",
        "kind": "LinkedField",
        "name": "updateSkillLevel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignmentAction",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cc2a6cfccda473aa9f81af7c8a7543f",
    "id": null,
    "metadata": {},
    "name": "SetAsDefaultModal_UpdateSkillLevelMutation",
    "operationKind": "mutation",
    "text": "mutation SetAsDefaultModal_UpdateSkillLevelMutation(\n  $businessId: ID!\n  $id: ID!\n  $input: SkillLevelUpdateInput!\n) {\n  updateSkillLevel(businessId: $businessId, id: $id, input: $input) {\n    id\n    ...Profile_skillLevel\n  }\n}\n\nfragment Profile_skillLevel on SkillLevel {\n  name\n  isDefault\n  level\n  aosLevel\n  assignmentAction\n}\n"
  }
};
})();
(node as any).hash = 'fe1c93886bee77daaf088fee6deca940';
export default node;
