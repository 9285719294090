import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
  onOk: () => any;
  employeeCount: number;
  timeOffTypeName: string;
};

export function UnableToDeleteTimeOffTypeModal({
  onClose,
  onOk,
  employeeCount,
  timeOffTypeName,
}: Props) {
  const { t } = useTranslation("time-off-types");

  return (
    <Modal onHide={onClose} show>
      <Modal.Header closeButton>
        <Modal.Title as="h3">{t("unableToDeleteModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="time-off-types:unableToDeleteModal.body"
          values={{ timeOffTypeName, employeeCount }}
          components={{ bold: <strong /> }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onOk}>
          {t("unableToDeleteModal.buttons.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
