import React from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import isString from "lodash/isString";
import DurationInput from "../../../Form/DurationInput";
import Field from "../../../Form/Field";

type Props = {
  disabled?: boolean;
};

const StyledDash = styled("span")`
  line-height: 35px;
`;

const StyledRow = styled(Row)`
  .input-group {
    width: 92px;
  }
`;

export default function PayDates({ disabled }: Props) {
  const { t } = useTranslation();
  const formikContext = useFormikContext();

  const shiftMinMeta = formikContext.getFieldMeta("aosConfig.shiftMin");
  const { error: shiftMinError } = shiftMinMeta;

  const shiftMaxMeta = formikContext.getFieldMeta("aosConfig.shiftMax");
  const { error: shiftMaxError } = shiftMaxMeta;
  return (
    <>
      <Form.Label htmlFor="aosConfig.shiftMin">
        Shift length must be in between
      </Form.Label>
      <StyledRow noGutters>
        <Field
          fieldKey="aosConfig.shiftMin"
          hideLabel
          disabled={disabled != null ? disabled : false}
          hideError
          component={DurationInput}
          componentProps={{
            from: "minutes",
            to: "hours",
            postfix: t("form.hour_plural"),
          }}
          lg="auto"
        />

        <Col md="auto">
          <StyledDash className="ml-1 mr-1">-</StyledDash>
        </Col>

        <Field
          fieldKey="aosConfig.shiftMax"
          hideLabel
          disabled={disabled != null ? disabled : false}
          hideError
          component={DurationInput}
          componentProps={{
            from: "minutes",
            to: "hours",
            postfix: t("form.hour_plural"),
          }}
          lg="auto"
        />
      </StyledRow>

      <Form.Control.Feedback type="invalid" className="d-block">
        {isString(shiftMinError) ? shiftMinError : ""}
        &nbsp;
        {isString(shiftMaxError) ? shiftMaxError : ""}
      </Form.Control.Feedback>
    </>
  );
}
