import React, { Suspense, useEffect } from "react";
import { PreloadedQuery, useQueryLoader } from "react-relay";
import { useTranslation } from "react-i18next";
import PeopleSettingsProfileForm from "./PeopleSettingsProfileForm";
import HeaderPortal from "../../../Portal/HeaderPortal";
import Loader from "../../../common/Loader";
import { PeopleSettingsQueriesMutations_GetBusinessPeopleSettings_Query } from "./__generated__/PeopleSettingsQueriesMutations_GetBusinessPeopleSettings_Query.graphql";
import { useBusinessContext } from "../../../Context/BusinessContext";

type Props = {
  initialQueryRef: PreloadedQuery<PeopleSettingsQueriesMutations_GetBusinessPeopleSettings_Query>;
};

export default function PeopleSettingsProfile({ initialQueryRef }: Props) {
  const { t } = useTranslation("people-settings");
  const { business } = useBusinessContext();
  const businessId = business?.id as string;

  return (
    <>
      <HeaderPortal as="span">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("nav.peopleSettings")}</span>
      </HeaderPortal>
      <Suspense fallback={<Loader />}>
        <PeopleSettingsProfileForm businessId={businessId} />
      </Suspense>
    </>
  );
}
