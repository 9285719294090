import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { useFormikContext } from "formik";
import DurationInput from "./DurationInput";

const StyledLabel = styled(Form.Label)`
  margin: 0;
  align-items: center;
  ${(props) => props.$disabled && `color: ${props.theme.grey600}`}
`;

const StyledRadio = styled(Form.Check).attrs({ custom: true })`
  .custom-control-input {
    z-index: 100;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    border-color: transparent;
  }
`;

type Option = {
  radioValue: string;
  label: string;
  value?: unknown;
  isCustom?: boolean;
  prefixLabel?: string;
  postfixLabel?: string;
  customInputPostfix?: string;
  minCustomValue?: number;
  maxCustomValue?: number;
};

type Props = {
  value: number;
  onChange: (newValue: unknown) => void;
  options: Option[];
  disabled: boolean;
};

export function RadioSelectWithCustomOption({
  value,
  onChange,
  options,
  disabled,
}: Props) {
  const { dirty } = useFormikContext();

  const [radioValue, setRadioValue] = useState(
    getDefaultRadioOption(value, options)?.radioValue,
  );
  const [customInput, setCustomInput] = useState<number | undefined>(
    getDefaultRadioOption(value, options)?.isCustom ? value : undefined,
  );

  const selectedOption = getSelectedOption(radioValue, options);
  const customOption = options.find((option) => option.isCustom);

  const onCustomInputChange = (newValue: string | number | null) => {
    // Make the field look like an uncontrolled component by allowing everything to be deleted
    if (newValue == null) {
      setCustomInput(undefined);
      onChange(newValue);
    }

    if (
      newValue != null &&
      (customOption?.minCustomValue == null ||
        newValue >= customOption?.minCustomValue) &&
      (customOption?.maxCustomValue == null ||
        newValue <= customOption?.maxCustomValue)
    ) {
      setCustomInput(newValue as number);
      onChange(newValue);
    }
  };

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: string,
  ) => {
    const isChecked = e.currentTarget.checked;
    if (isChecked) {
      setRadioValue(e.currentTarget.value as any);
      onChange(getValueForRadioOption(option, options, customInput));
    } else {
      onChange(null);
    }
  };

  useEffect(() => {
    // This field requires a bit of finesse to reset nicely, since we also manage state internally inside this component
    // If the form isn't dirty, assume that we want to reset the internal values
    if (!dirty) {
      const newOption = getDefaultRadioOption(value, options);
      setRadioValue(newOption?.radioValue);
      setCustomInput(getDefaultInputFieldValue(value, newOption));
    }
  }, [value, setRadioValue, setCustomInput, options, dirty]);

  return (
    <Col>
      {options.map((option: Option, index: number) => (
        <Row
          key={option.radioValue}
          className={
            index === 0 ? "mt-1 d-flex flex-nowrap" : "mt-2 d-flex flex-nowrap"
          }
        >
          <StyledRadio
            value={option.radioValue}
            checked={radioValue === option.radioValue}
            onChange={(e: any) => onCheckboxChange(e, option.radioValue)}
            type="radio"
            label={option.label}
            disabled={disabled}
          />
          {option.isCustom && (
            <StyledLabel
              $disabled={disabled}
              className="d-flex justify-content-md-center"
            >
              {option.prefixLabel && <span>{option.prefixLabel}</span>}
              <DurationInput
                fieldKey="customInput"
                size="sm"
                className="mr-2 ml-2"
                value={customInput}
                postfix={option.customInputPostfix}
                onChange={onCustomInputChange}
                disabled={!selectedOption?.isCustom}
              />
              {option.postfixLabel && <span>{option.postfixLabel}</span>}
            </StyledLabel>
          )}
        </Row>
      ))}
    </Col>
  );
}

function getDefaultRadioOption(value: number, options: Option[]) {
  const optionIsSelected = value !== undefined;
  if (optionIsSelected) {
    const selectedOption = options.find((x) => x.value === value);
    return selectedOption ?? options.find((option) => option.isCustom);
  }
  return undefined;
}

function getSelectedOption(radioValue: string | undefined, options: Option[]) {
  return options.find((option) => option.radioValue === radioValue);
}

function getValueForRadioOption(
  radio: string,
  options: Option[],
  inputValue?: number,
) {
  const selectedOption = options.find((option) => option.radioValue === radio);
  if (selectedOption?.isCustom) {
    return inputValue;
  }
  if (selectedOption?.value !== undefined) {
    return selectedOption?.value;
  }
  return undefined;
}

function getDefaultInputFieldValue(value: number | undefined, option?: Option) {
  return option?.isCustom ? value : undefined;
}
