import React, { useContext } from "react";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";
import styled from "styled-components";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "./ButtonIcon";

type Props = {
  eventKey: string;
  callback?: Function;
  className?: string;
};

const StyledButton = styled(ButtonIcon)`
  width: 24px;
  color: ${(props) => props.theme.grey700};
`;

const AccordionToggle: React.FunctionComponent<Props> = ({
  className,
  eventKey,
  callback,
}) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <StyledButton
      className={className}
      icon={isCurrentEventKey ? faChevronDown : faChevronRight}
      onClick={decoratedOnClick}
    />
  );
};

export default AccordionToggle;
