import { graphql } from "react-relay";
import { BusinessQueriesMutations_ListFragment } from "./__generated__/BusinessQueriesMutations_ListFragment.graphql";

export const BusinessTablePageQuery = graphql`
  query BusinessQueriesMutations_InternalQuery(
    $searchValue: String!
    $pageSize: Int!
    $after: String
  ) {
    ...BusinessQueriesMutations_ListFragment
  }
`;

export const BusinessTableFragment = graphql`
  fragment BusinessQueriesMutations_ListFragment on InternalQuery
  @refetchable(queryName: "BusinessTableQuery") {
    businesses(search: $searchValue, first: $pageSize, after: $after)
      @connection(key: "BusinessTable_businesses") {
      edges {
        node {
          id
          businessName
          shortDescription
          globalBusinessId
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

type EdgeType =
  {} & BusinessQueriesMutations_ListFragment["businesses"]["edges"];
type Edge = {} & EdgeType[number];
export type BusinessListItem = {} & Edge["node"];
