import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Translation } from "react-i18next";

import {
  idmExternalEnvironment,
  idmInternalEnvironment,
} from "../../../../environment";
import ModalContext, { ModalProps } from "../../../Context/ModalContextLegacy";
import OkCancelModal from "../../../OkCancelModal";
import SendResetPasswordEmailMutation from "../../external/mutations/SendResetPasswordEmailMutation";
import UnlockAccountMutation from "../mutations/UnlockAccountMutation";
import SendConfirmationEmailMutation from "../../external/mutations/SendConfirmationEmailMutation";
import { IUser, IStack } from "../../../../models/common";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { SendResetPasswordEmailMutationResponse } from "../../external/mutations/__generated__/SendResetPasswordEmailMutation.graphql";
import { SendConfirmationEmailMutationResponse } from "../../external/mutations/__generated__/SendConfirmationEmailMutation.graphql";
import { UnlockAccountMutationResponse } from "../mutations/__generated__/UnlockAccountMutation.graphql";

export type UserLayoutInfo = IUser & {
  lockedAt?: string;
};

type ModalDefinition = {
  title: string;
  body: string;
  onOk: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

enum ModalEnum {
  SetPasswordModal,
  GenericOkCancelModal,
}

type State = {
  modal?: ModalEnum;
  modalDefinition?: ModalDefinition;
};

type Props = {
  user?: UserLayoutInfo;
  stack?: IStack;
};

const StyledDropdown = styled(DropdownButton)`
  position: absolute;
  right: 0;
`;

export default class AccountActionsDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: undefined,
    };
  }

  private handleUnlockAccount = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const { user } = this.props;

    if (user && user.email) {
      UnlockAccountMutation(
        idmInternalEnvironment,
        user.id,
        (response: UnlockAccountMutationResponse) => {
          toast(`Account unlocked: ${response.unlockAccount.email}`);
        },
        (error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
    }

    event.stopPropagation();
    event.preventDefault();
  };

  private handleSendConfirmAccountEmail = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const { user } = this.props;

    if (user && user.email) {
      SendConfirmationEmailMutation(
        idmExternalEnvironment,
        user.email,
        (response: SendConfirmationEmailMutationResponse) => {
          if (response.sendConfirmationEmail) {
            toast(`Confirmation email sent to ${user.email}`);
          }
        },
        (error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
    }

    event.stopPropagation();
    event.preventDefault();
  };

  private handleSendResetPasswordEmail = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const { user } = this.props;

    if (user && user.email) {
      SendResetPasswordEmailMutation(
        idmExternalEnvironment,
        user.email,
        (response: SendResetPasswordEmailMutationResponse) => {
          if (response.sendResetPasswordEmail) {
            toast(`Sent reset password to ${user.email}`);
          }
        },
        (error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
    }

    event.stopPropagation();
    event.preventDefault();
  };

  private showSendResetPasswordConfirmation(modalProps: ModalProps) {
    const { user } = this.props;
    this.setState({
      modal: ModalEnum.GenericOkCancelModal,
      modalDefinition: {
        title: "Send reset password",
        body: `You are about to send reset password email to ${user?.email}`,
        onOk: this.handleSendResetPasswordEmail,
      },
    });
    modalProps.showModal();
  }

  private showUnlockAccountConfirmation(modalProps: ModalProps) {
    const { user } = this.props;
    this.setState({
      modal: ModalEnum.GenericOkCancelModal,
      modalDefinition: {
        title: "Unlock account",
        body: `You are about to unlock account for ${user?.email}`,
        onOk: this.handleUnlockAccount,
      },
    });
    modalProps.showModal();
  }

  private showSendConfirmAccountConfirmation(modalProps: ModalProps) {
    const { user } = this.props;
    this.setState({
      modal: ModalEnum.GenericOkCancelModal,
      modalDefinition: {
        title: "Send account confirmation",
        body: `You are about to send account confirmation email to ${user?.email}`,
        onOk: this.handleSendConfirmAccountEmail,
      },
    });
    modalProps.showModal();
  }

  render() {
    const { modalDefinition, modal } = this.state;
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const renderModal = (modalProps: ModalProps) => {
      switch (modal) {
        case ModalEnum.SetPasswordModal:
          return <ChangePasswordDialog modalProps={modalProps} user={user} />;
        case ModalEnum.GenericOkCancelModal:
          return (
            <OkCancelModal
              title={modalDefinition?.title}
              onOk={modalDefinition ? modalDefinition.onOk : () => undefined}
            >
              <p>{modalDefinition?.body}</p>
            </OkCancelModal>
          );
        default:
          return null;
      }
    };

    return (
      <Translation>
        {(t) => (
          <ModalContext.Consumer>
            {(modalProps) => (
              <StyledDropdown
                alignRight
                size="sm"
                title={t("user_actions_dropdown.title")}
              >
                <Dropdown.Item
                  onSelect={() => {
                    this.setState({
                      modal: ModalEnum.SetPasswordModal,
                    });
                    modalProps.showModal();
                  }}
                >
                  {t("user_actions_dropdown.actions.change_password")}
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => {
                    this.showSendResetPasswordConfirmation(modalProps);
                  }}
                >
                  {t("user_actions_dropdown.actions.send_reset_password")}
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => {
                    this.showSendConfirmAccountConfirmation(modalProps);
                  }}
                >
                  {t("user_actions_dropdown.actions.send_confirmation")}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  disabled={user?.lockedAt === null}
                  onSelect={() => {
                    this.showUnlockAccountConfirmation(modalProps);
                  }}
                >
                  {t("user_actions_dropdown.actions.unlock_account")}
                </Dropdown.Item>

                {renderModal(modalProps)}
              </StyledDropdown>
            )}
          </ModalContext.Consumer>
        )}
      </Translation>
    );
  }
}
