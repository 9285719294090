import React from "react";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { ConnectionHandler } from "react-relay";
import {
  DynamicFieldsLayoutView,
  EmploymentMetadata,
  MetadataType,
} from "../../../../../generated/stack_internal_schema";
import FormLayout from "../../../../Form/FormLayout";
import FormLayoutFooter from "../../../../Form/FormLayoutFooter";
import { useEmployeeMetadataQueriesMutations } from "../EmploymentMetadataQueriesMutations";
import { useModal } from "../../../../Context/ModalContext";
import { ConfirmationModal } from "../../../../common/ConfirmationModal";
import { MetadataFieldWithProps, MetadataUtility } from "../MetadataUtility";
import { concatenateStrings } from "../../../../../utils/utility";
import { EmploymentMetadataUpsertInput } from "../__generated__/EmploymentMetadataQueriesMutations_UpsertEmploymentMetadata_Mutation.graphql";
import { useDynamicFieldsLayout } from "../../../Business/MetadataLayout/MetadataLayoutQueriesMutations";
import DynamicEmploymentMetadataInputGroup from "./DynamicEmploymentMetadataInputGroup";
import { useAppRouter } from "../../../../hooks/useAppRouter";

type EmployeeMetaToTypeMapping = {
  readonly id: string | undefined;
  readonly value: unknown;
  readonly fieldName: string;
  readonly existingMetadata?: EmploymentMetadata;
  readonly metadataType: MetadataType;
};

// Metadata types are dynamic, so the keys could literally be anything
type EmployeeMetadataForm = Record<string, unknown>;

export default function EmploymentMetadataBasicProfile() {
  const { t } = useTranslation("employment");
  const [businessDynamicFieldsLayout] = useDynamicFieldsLayout();
  const { showModal, hideModal } = useModal();
  const {
    params: { employment_id: employmentId, business_id: businessId },
  } = useAppRouter<{
    employment_id: string;
    business_id: string;
  }>();

  const [{ employmentMetadataConnectionId }, { upsertEmploymentMetadata }] =
    useEmployeeMetadataQueriesMutations();

  const [metadataTypeFields, otherFields] = useMetadataTypeFields();
  const [employeeMetadataFormData, mapEmployeeMetadataValues] =
    useEmployeeMetadata();

  const validationRules = useValidationRules();

  const onSave = (
    data: EmployeeMetadataForm,
    errorHandler: (error: Error) => void,
    e?: any,
    values?: any,
    helpers?: FormikHelpers<EmployeeMetadataForm>,
  ) => {
    const mappedInputValues = mapEmployeeMetadataValues(data);

    const upsertEmploymentMetadataMutation = () => {
      upsertEmploymentMetadata({
        variables: {
          input: mappedInputValues.map((x) => x.value),
          employmentId,
          businessId,
        },
        onCompleted() {
          helpers?.setSubmitting(false);
          toast(t("metadata.basic.toast.updated"));
          hideModal();
        },
        onError(error: Error) {
          errorHandler(error);
        },
        updater(store) {
          const connectionReference = store.get(employmentMetadataConnectionId);
          if (connectionReference) {
            const newRecords =
              store.getPluralRootField("upsertEmploymentMetadata") ?? [];

            // Add new records to the cache
            newRecords.forEach((newRecord) => {
              if (newRecord) {
                const newEdge = ConnectionHandler.createEdge(
                  store,
                  connectionReference,
                  newRecord,
                  "EmploymentMetadataEdge",
                );
                ConnectionHandler.insertEdgeAfter(connectionReference, newEdge);
              }
            });

            // This will remove items from the query cache that have been set to null
            // The backend doesn't store null values, so the query won't return the deleted items
            mappedInputValues.forEach(({ value, id }) => {
              if (value && value.details == null && id != null) {
                ConnectionHandler.deleteNode(connectionReference, id);
              }
            });
          }
        },
      });
    };

    // If updated external fields, show a warning (which the user can bypass)
    const externalFieldsBeingUpdated = mappedInputValues
      .filter((field) => field.metadataType.external)
      .map((field) => MetadataUtility.getDisplayName(field.metadataType));

    if (externalFieldsBeingUpdated.length > 0) {
      showModal(
        <ConfirmationModal
          onClose={() => {
            helpers?.setSubmitting(false);
            hideModal();
          }}
          okClicked={upsertEmploymentMetadataMutation}
          title={t("metadata.basic.updateExternalFieldsModal.title")}
          okText={t("metadata.basic.updateExternalFieldsModal.actions.ok")}
        >
          {t("metadata.basic.updateExternalFieldsModal.body", {
            fieldNames: concatenateStrings(externalFieldsBeingUpdated),
          })}
        </ConfirmationModal>,
      );
    } else {
      upsertEmploymentMetadataMutation();
    }
  };

  return (
    <FormLayout<EmployeeMetadataForm>
      isCreate={false}
      onSave={onSave}
      validationRules={validationRules}
      propertyList={[]}
      base={employeeMetadataFormData}
    >
      {businessDynamicFieldsLayout.views.map(
        (view: DynamicFieldsLayoutView) => (
          <DynamicEmploymentMetadataInputGroup
            key={view.name}
            view={view}
            metadataTypeFields={metadataTypeFields}
            businessDynamicFieldsLayout={businessDynamicFieldsLayout}
          />
        ),
      )}
      {otherFields.length > 0 && (
        <DynamicEmploymentMetadataInputGroup
          view={null}
          metadataTypeFields={otherFields}
          businessDynamicFieldsLayout={businessDynamicFieldsLayout}
        />
      )}
      <FormLayoutFooter isCreate={false} onDelete={undefined} />
    </FormLayout>
  );
}

function useEmployeeMetadata() {
  const [{ businessMetadataTypes, employmentMetadata }] =
    useEmployeeMetadataQueriesMutations();

  // Map the data internally so that the metadata types (business level) and employment metadata JSON objects
  // are linked.
  const metadataTypesAndEmployeeDataMapping = businessMetadataTypes.map(
    (businessMetadataType: MetadataType) => {
      const metadataValue = employmentMetadata.find(
        (employeeMetadata: EmploymentMetadata) =>
          employeeMetadata?.metadataTypeId === businessMetadataType.id,
      );

      return {
        id: metadataValue?.id,
        value: metadataValue?.details,
        fieldName: businessMetadataType.name,
        existingMetadata: metadataValue,
        metadataType: businessMetadataType,
      } as EmployeeMetaToTypeMapping;
    },
  );

  const employeeMetadataFormData: EmployeeMetadataForm =
    metadataTypesAndEmployeeDataMapping.reduce((acc, employeeMetaMapping) => {
      return {
        ...acc,
        [employeeMetaMapping.fieldName]: employeeMetaMapping.value,
      };
    }, {});

  // Helper function which will handle creating the employeeMetadata object to send to the backend
  // The form handles the metadata entry as { [key]: value }, but it is sent to the server as an EmploymentMetadataUpsertInput object
  const mapEmployeeMetadataValues = (formData: EmployeeMetadataForm) => {
    return Object.keys(formData).reduce(
      (
        acc: {
          value: EmploymentMetadataUpsertInput;
          metadataType: MetadataType;
          id: string | undefined;
        }[],
        fieldName,
      ) => {
        const metaObject = metadataTypesAndEmployeeDataMapping.find(
          (employeeMetaMapping) => employeeMetaMapping.fieldName === fieldName,
        );

        // LK-9063: Backend will delete metadata from the database if it's null and will throw a validation error if details is a blank string
        const details =
          formData[fieldName] === "" || formData[fieldName] == null
            ? null
            : formData[fieldName];

        return metaObject
          ? [
              ...acc,
              {
                value: {
                  metadataTypeId: metaObject.metadataType.id,
                  details,
                },
                id: metaObject.existingMetadata?.id,
                metadataType: metaObject.metadataType,
              },
            ]
          : acc;
      },
      [],
    );
  };

  return [employeeMetadataFormData, mapEmployeeMetadataValues] as const;
}

function useMetadataTypeFields() {
  const [{ businessMetadataTypes }] = useEmployeeMetadataQueriesMutations();
  const [businessDynamicFieldsLayout] = useDynamicFieldsLayout();

  const metadataTypeFields = businessMetadataTypes
    .map((metadataType: MetadataType) => {
      return MetadataUtility.getFieldPropsForMetadataType(metadataType);
    })
    .reduce(
      (acc, field) => {
        const newArr = acc;
        if (
          !MetadataUtility.getMetadataTypesInAnyGroup(
            businessDynamicFieldsLayout,
          ).includes(field.metadataType.name)
        ) {
          newArr.others.push(field);
        } else {
          newArr.fieldsInGroup.push(field);
        }
        return newArr;
      },
      {
        // Fields that are in at least one view/group
        fieldsInGroup: [] as MetadataFieldWithProps[],

        // Fields that are not in any view or group
        others: [] as MetadataFieldWithProps[],
      },
    );

  const otherFields = metadataTypeFields.others.sort((a, b) => {
    return MetadataUtility.getDisplayName(a.metadataType).localeCompare(
      MetadataUtility.getDisplayName(b.metadataType),
    );
  });

  return [metadataTypeFields.fieldsInGroup, otherFields] as const;
}

function useValidationRules() {
  const [{ businessMetadataTypes }] = useEmployeeMetadataQueriesMutations();

  // Dynamically add validation to the field as needed
  return yup.object(
    businessMetadataTypes.reduce((acc, metadataType: MetadataType) => {
      return {
        ...acc,
        [metadataType.name]:
          MetadataUtility.applyYupRulesToMetadataType(metadataType),
      };
    }, {}),
  );
}
