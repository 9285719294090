import React, { Component } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import Searchable from "../../Searchable/Searchable";
import { BusinessContext } from "../../Context/BusinessContext";
import AOSTemplateTable from "./AOSTemplateTable";
import Loader from "../../common/Loader";
import HeaderPortal from "../../Portal/HeaderPortal";

const AOSTemplatesQuery = graphql`
  query AOSTemplates_InternalQuery(
    $businessId: ID!
    $search: String
    $pageSize: Int!
    $after: String
  ) {
    ...AOSTemplateTable_viewer
      @arguments(
        businessId: $businessId
        search: $search
        pageSize: $pageSize
        after: $after
      )
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & WithTranslation & {};

type State = {
  searchValue: string;
};
class AOSTemplates extends Component<Props, State> {
  static contextType = BusinessContext;

  constructor(props: any) {
    super(props);
    this.state = {
      searchValue: "",
    };
  }

  private onSearchChange(value: string) {
    this.setState({
      searchValue: value,
    });
  }

  render() {
    const { searchValue } = this.state;
    const { environment } = this.context;

    const {
      match: { params },
      t,
    } = this.props;
    const { business_id: businessId, stack_id: stackId } = params;

    return (
      <>
        <HeaderPortal as="span" elementId="sub-header-portal">
          <span className="ml-2 mr-2">&gt;</span>
          <span>{t("aosTemplate.aosTemplates")}</span>
        </HeaderPortal>
        <Row className="mt-2 mb-2">
          <Col>
            <Searchable
              className="aos-templates"
              searchValue={searchValue}
              onSearchChange={this.onSearchChange.bind(this)}
              placeholder={t("aosTemplate.search")}
            />
          </Col>
          <Col md="auto">
            <LinkContainer
              to={`/stack/${stackId}/business/${businessId}/aos_template/create`}
            >
              <Button>{t("aosTemplate.newTemplate")}</Button>
            </LinkContainer>
          </Col>
        </Row>

        <Card body>
          <QueryRenderer
            environment={environment}
            query={AOSTemplatesQuery}
            variables={{
              businessId,
              search: searchValue || "",
              pageSize: 50,
            }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error!</div>;
              }
              if (!props) {
                return <Loader />;
              }
              return (
                <AOSTemplateTable
                  {...this.props}
                  stackId={stackId}
                  businessId={businessId}
                  searchValue={searchValue}
                  viewer={props as any}
                />
              );
            }}
          />
        </Card>
      </>
    );
  }
}

export default withTranslation("aos")(AOSTemplates);
