import React, { Suspense, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, useQueryLoader } from "react-relay";
import Loader from "../../common/Loader";
import Checkbox from "../../Form/Checkbox";
import { useAppRouter } from "../../hooks/useAppRouter";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { useScheduleBusinessContextCheck } from "../../hooks/useScheduleBusinessContextCheck";
import HeaderPortal from "../../Portal/HeaderPortal";
import Searchable from "../../Searchable/Searchable";
import { GetEmploymentTypeConfigsQuery } from "./EmploymentTypesQueriesMutations";
import EmploymentTypesTable from "./EmploymentTypesTable";
import { EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query } from "./__generated__/EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query.graphql";

type EmploymentTypeConfigsQuery =
  PreloadedQuery<EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query>;

type Props = {
  initialQueryRef: EmploymentTypeConfigsQuery;
};

export default function EmploymentTypes({ initialQueryRef }: Props) {
  const router = useAppRouter();
  const { t } = useTranslation("employment-types");

  const onNewEmploymentTypeClicked = () => {
    router.push("employment_types/create");
  };

  const [queryReference, { search, showRemoved }] =
    useEmploymentTypesPreloadedQuery(initialQueryRef);

  return (
    <>
      <HeaderPortal>
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("nav.employmentTypes")}</span>
      </HeaderPortal>
      <Row className="align-baseline">
        <Col md={5}>
          <Searchable
            className="employmentTypes"
            searchValue={search.value}
            onSearchChange={search.setValue}
            placeholder={t("table.search")}
          />
        </Col>
        <Col md={4}>
          <Row>
            <Checkbox
              fieldKey="deactivated-checkbox"
              className="ml-3 align-self-center"
              value={showRemoved.value}
              label={t("table.remove")}
              onChange={showRemoved.setValue}
            />
          </Row>
        </Col>

        <Col md={3} className="d-flex justify-content-end">
          <Button variant="primary" onClick={onNewEmploymentTypeClicked}>
            {t("table.newEmploymentType")}
          </Button>
        </Col>
      </Row>

      <Card className="mt-4" body>
        {queryReference != null && (
          <Suspense fallback={<Loader />}>
            <EmploymentTypesTable
              queryReference={queryReference as EmploymentTypeConfigsQuery}
            />
          </Suspense>
        )}
      </Card>
    </>
  );
}

const defaultFilters = {
  showRemoved: true,
  searchValue: "",
};

function useEmploymentTypesPreloadedQuery(initialQueryRef: any) {
  const [searchValue, debouncedSearchValue, setSearchValue] = useDebouncedValue(
    defaultFilters.searchValue,
  );

  const [showRemoved, setShowRemoved] = useState(defaultFilters.showRemoved);

  const [queryReference, loadQuery] = useQueryLoader(
    GetEmploymentTypeConfigsQuery,
    initialQueryRef,
  );
  const { businessId } = useScheduleBusinessContextCheck();

  const clearFilters = () => {
    setShowRemoved(true);
    setSearchValue("", false);
  };

  useEffect(() => {
    if (businessId) {
      loadQuery(
        {
          businessId,
          deleted: showRemoved ? null : false, // null says "return deleted and non-deleted"
          search: debouncedSearchValue,
        },
        {
          fetchPolicy: "network-only",
        },
      );
    }
  }, [businessId, loadQuery, debouncedSearchValue, showRemoved]);

  const filters = {
    search: {
      value: searchValue,
      setValue: setSearchValue,
    },
    showRemoved: {
      value: showRemoved,
      setValue: setShowRemoved,
    },
    filtersChanged: searchValue !== defaultFilters.searchValue,
    clearFilters,
  };
  return [queryReference, filters] as const;
}
