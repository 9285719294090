/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Profile_globalBusiness = {
    readonly id: string;
    readonly name: string;
    readonly employmentUidStart: BigInt;
    readonly used: boolean;
    readonly " $refType": "Profile_globalBusiness";
};
export type Profile_globalBusiness$data = Profile_globalBusiness;
export type Profile_globalBusiness$key = {
    readonly " $data"?: Profile_globalBusiness$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Profile_globalBusiness">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Profile_globalBusiness",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentUidStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "used",
      "storageKey": null
    }
  ],
  "type": "GlobalBusiness",
  "abstractKey": null
};
(node as any).hash = '95650c82806b629986efb4626dc1176b';
export default node;
