/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type StackTable_InternalQueryVariables = {
    pageSize: number;
    after?: string | null | undefined;
};
export type StackTable_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"StackTable_viewer">;
};
export type StackTable_InternalQuery = {
    readonly response: StackTable_InternalQueryResponse;
    readonly variables: StackTable_InternalQueryVariables;
};



/*
query StackTable_InternalQuery(
  $pageSize: Int!
  $after: String
) {
  ...StackTable_viewer
}

fragment StackRow_viewer on Stack {
  id
  domainName
  stackCode
  deleted
}

fragment StackTable_viewer on InternalQuery {
  stacks(first: $pageSize, after: $after) {
    edges {
      node {
        ...StackRow_viewer
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StackTable_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "StackTable_viewer"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StackTable_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "StackConnection",
        "kind": "LinkedField",
        "name": "stacks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StackEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Stack",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stackCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "StackTable_stacks",
        "kind": "LinkedHandle",
        "name": "stacks"
      }
    ]
  },
  "params": {
    "cacheID": "af20925b5e1aa3c1247186e55e4751d9",
    "id": null,
    "metadata": {},
    "name": "StackTable_InternalQuery",
    "operationKind": "query",
    "text": "query StackTable_InternalQuery(\n  $pageSize: Int!\n  $after: String\n) {\n  ...StackTable_viewer\n}\n\nfragment StackRow_viewer on Stack {\n  id\n  domainName\n  stackCode\n  deleted\n}\n\nfragment StackTable_viewer on InternalQuery {\n  stacks(first: $pageSize, after: $after) {\n    edges {\n      node {\n        ...StackRow_viewer\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '01d607cfe424656431e746f0b84cacc5';
export default node;
