import React from "react";
import DynamicInputGroup from "../../../../Form/DynamicInputGroup";
import { AosConfigAdvanced } from "../models";

import {
  getSettingsByGroup,
  getFieldsByNames,
} from "../../../../Form/formUtilities";
import { IProperty } from "../../../../Form/models";

type Props = {
  values: {
    aosConfig: AosConfigAdvanced | null;
  };
  properties: IProperty[];
  disabled: boolean;
};

const AdvancedFields: React.FunctionComponent<Props> = ({
  values,
  properties,
  disabled,
}) => {
  const { aosConfig } = values;
  if (!aosConfig) {
    return null;
  }

  return (
    <>
      <fieldset>
        <DynamicInputGroup
          disabled={disabled}
          fields={getSettingsByGroup(
            getFieldsByNames(properties as unknown as IProperty[], [
              "aosConfig.aosTimeout",
              "aosConfig.aosCombinedRoles",
              "aosConfig.aosOrchestratorConfig",
            ]),
          )}
        />
      </fieldset>

      <fieldset>
        <DynamicInputGroup
          disabled={disabled}
          fields={getSettingsByGroup(
            getFieldsByNames(properties as unknown as IProperty[], [
              "populateScheduleReplanningEnabled",
              "aosConfig.replanningTimeout",
              "aosConfig.replanningOrchestratorConfig",
            ]),
          )}
        />
      </fieldset>
    </>
  );
};

export default AdvancedFields;
