import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import ScheduleProfileForm from "./ScheduleProfileForm";
import { useBusinessContext } from "../../../Context/BusinessContext";
import Loader from "../../../common/Loader";
import HeaderPortal from "../../../Portal/HeaderPortal";
import { ScheduleProfile_Query } from "./__generated__/ScheduleProfile_Query.graphql";

const ScheduleProfileQuery = graphql`
  query ScheduleProfile_Query($businessId: ID!, $scheduleId: ID!) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      edges {
        node {
          ...ScheduleProfileForm_schedule
        }
      }
    }
  }
`;

interface MatchParams {
  business_id: string;
  schedule_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

export default function ScheduleProfile(props: Props) {
  const { t } = useTranslation();
  const businessContext = useBusinessContext();

  const { match, ...rest } = props;
  const { params } = props.match;
  const { schedule_id: scheduleId, business_id: businessId } = params;
  const { environment } = businessContext;

  return (
    <QueryRenderer<ScheduleProfile_Query>
      environment={environment}
      query={ScheduleProfileQuery}
      variables={{
        businessId,
        scheduleId,
      }}
      render={({ error, props: queryRenderProps }) => {
        if (error) {
          return <div>{t("schedules:form.error")}</div>;
        }
        if (!queryRenderProps) {
          return <Loader />;
        }
        return (
          <>
            <HeaderPortal elementId="sub-header-portal" as="span">
              <span className="ml-2 mr-2">&gt;</span>
              <span>{t("schedules:layout.nav.general")}</span>
            </HeaderPortal>
            <Card body>
              <ScheduleProfileForm
                {...rest}
                match={match}
                schedule={(queryRenderProps as any).schedules.edges[0].node}
                environment={environment}
              />
            </Card>
          </>
        );
      }}
    />
  );
}
