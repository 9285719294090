import { commitMutation, graphql, Environment } from "react-relay";

const mutation = graphql`
  mutation RegisterStackMutation($domainName: String!, $stackCode: String!) {
    registerStack(domainName: $domainName, stackCode: $stackCode) {
      id
      domainName
      stackCode
    }
  }
`;

export default (
  environment: Environment,
  domainName: string,
  stackCode: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    domainName,
    stackCode,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
