import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Environment } from "react-relay";
import styled from "styled-components";
import { Translation, WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import ModalContext, { ModalProps } from "../../Context/ModalContextLegacy";
import { EmploymentLayoutInfo, User } from "./models";
import LinkToUserModal from "./LinkToUserModal";
import UnlinkUserModal from "./UnlinkUserModal";
import { Id } from "../../../models/common";
import UserService from "../../IDM/internal/Account/AccountService";

interface EmploymentInfo {
  id: Id;
  firstName: string;
  lastName: string;
  userId: Id;
}

enum ModalEnum {
  LinkUser,
  UnlinkUser,
}

type State = {
  modal?: ModalEnum;
  employment?: EmploymentLayoutInfo;
  linkedUser: User | null;
};

interface MatchParams {
  business_id: string;
  employment_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> &
  WithTranslation & {
    employment?: EmploymentInfo;
    environment: Environment;
  };

const StyledDropdown = styled(DropdownButton)`
  position: absolute;
  right: 0;
  top: 4px;
`;

class EmploymentActionsDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      linkedUser: null,
      modal: undefined,
      employment: props.employment,
    };
  }

  async componentDidMount() {
    const { employment } = this.props;

    if (!employment || !employment.userId) {
      return;
    }

    this.fetchLinkedUser(employment.userId);
  }

  private async fetchLinkedUser(userId: Id | null) {
    if (!userId) {
      return;
    }

    const user = await UserService.fetchUserById(userId);
    this.setState({
      linkedUser: user,
    });
  }

  private showDialog(modal: ModalEnum, modalProps: ModalProps) {
    this.setState({
      modal,
    });
    modalProps.showModal();
  }

  render() {
    const { modal, employment, linkedUser } = this.state;
    const {
      match: {
        params: { business_id: businessId },
      },
    } = this.props;

    const renderModal = (modalProps: ModalProps) => {
      switch (modal) {
        case ModalEnum.LinkUser:
          return (
            <LinkToUserModal
              modalProps={modalProps}
              linkedUser={linkedUser}
              employment={employment}
              businessId={businessId}
              onOk={(
                e: React.MouseEvent<HTMLButtonElement>,
                newLinkedUser: User,
              ) => {
                this.setState({ linkedUser: newLinkedUser });
              }}
            />
          );

        case ModalEnum.UnlinkUser:
          return (
            <UnlinkUserModal
              linkedUser={linkedUser}
              modalProps={modalProps}
              businessId={businessId}
              onOk={() => {
                this.setState({ linkedUser: null });
              }}
            />
          );
        default:
          return null;
      }
    };

    return (
      <Translation ns="employment">
        {(t) => (
          <ModalContext.Consumer>
            {(modalProps) => (
              <StyledDropdown
                alignRight
                size="sm"
                title={t("employmentActionsDropdown.title")}
              >
                <Dropdown.Item
                  onSelect={() => {
                    this.showDialog(ModalEnum.LinkUser, modalProps);
                  }}
                >
                  {t("employmentActionsDropdown.actions.linkToUser")}
                </Dropdown.Item>
                {linkedUser ? (
                  <Dropdown.Item
                    onSelect={() => {
                      this.showDialog(ModalEnum.UnlinkUser, modalProps);
                    }}
                  >
                    {t("employmentActionsDropdown.actions.unlinkFromUser", {
                      email: linkedUser.email,
                    })}
                  </Dropdown.Item>
                ) : null}
                {renderModal(modalProps)}
              </StyledDropdown>
            )}
          </ModalContext.Consumer>
        )}
      </Translation>
    );
  }
}

type LinkToEmploymentModalState = ModalProps & {
  value?: Id;
};

export default withTranslation(["translation"])(EmploymentActionsDropdown);
