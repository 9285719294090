import React, { createContext } from "react";
import isFunction from "lodash/isFunction";
import { isEmptyChildren } from "../../utils/utility";

export interface WithModalProps {
  hideModal: () => void;
  show: boolean;
  showModal: () => void;
}

export type ModalProps = WithModalProps;

type State = ModalProps & {};
type Props = {};
const ModalContext = createContext<State>({
  hideModal: () => null,
  show: false,
  showModal: () => null,
});

export default ModalContext;
export class ModalContextProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hideModal: this.hideModal,
      show: false,
      showModal: this.showModal,
    };
  }

  hideModal = () => {
    this.setState({
      show: false,
    });
  };

  showModal = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    const { children } = this.props;

    const getChildren = () => {
      if (children) {
        if (isFunction(children)) {
          return (children as (p: Props) => React.ReactNode)(
            this.state as Props,
          );
        }

        if (!isEmptyChildren(children)) {
          return children;
        }
      }

      return null;
    };

    return (
      <ModalContext.Provider value={this.state}>
        {getChildren()}
      </ModalContext.Provider>
    );
  }
}

export const useModalContext = () => React.useContext(ModalContext);

export function withModal<T extends WithModalProps = WithModalProps>(
  WrappedComponent: React.ComponentType<T>,
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithModal = (props: Omit<T, keyof WithModalProps>) => {
    return (
      <ModalContextProvider>
        {(modalProps: ModalProps) => {
          return <WrappedComponent {...modalProps} {...(props as T)} />;
        }}
      </ModalContextProvider>
    );
  };

  ComponentWithModal.displayName = `withModal(${displayName})`;

  return ComponentWithModal;
}
