import React from "react";
import Badge, { BadgeProps } from "react-bootstrap/Badge";
import { WithTranslation, withTranslation } from "react-i18next";
import { toRelative } from "../../../../utils/utility";

type Props = WithTranslation &
  BadgeProps & {
    user: {
      readonly confirmedAt: string | null;
      readonly confirmationSentAt: string | null;
      readonly confirmationToken: unknown | null;
      readonly lockedMinutesRemaining: number | null;
      readonly deleted: boolean;
    };
  };

const AccountBadge = (props: Props) => {
  const { user, t } = props;
  const {
    lockedMinutesRemaining,
    confirmationSentAt,
    deleted,
    confirmedAt,
    confirmationToken,
  } = user;

  const getStatusBadge = () => {
    if (deleted) {
      return <Badge variant="danger">{t("table.rows.status.deleted")}</Badge>;
    }

    if (!confirmedAt) {
      return (
        <Badge
          variant="warning"
          title={
            confirmationSentAt
              ? t("table.rows.confirmationSent", {
                  relativeTime: toRelative(confirmationSentAt as string, {
                    defaultValue: "",
                  }),
                  token: confirmationToken,
                })
              : t("table.rows.confirmationNotSent")
          }
        >
          {t("table.rows.status.pending")}
        </Badge>
      );
    }

    return <Badge variant="success">{t("table.rows.status.active")}</Badge>;
  };

  const getLockedBadge = () => {
    if (lockedMinutesRemaining) {
      return (
        <Badge
          variant="secondary"
          title={`${lockedMinutesRemaining} mins remaining`}
          className="ml-1"
        >
          {t("table.rows.status.locked")}
        </Badge>
      );
    }

    return null;
  };

  return (
    <>
      {getStatusBadge()}
      {getLockedBadge()}
    </>
  );
};

export default withTranslation("accounts")(AccountBadge);
