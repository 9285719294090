import React, { Suspense, useEffect } from "react";
import { PreloadedQuery, useQueryLoader } from "react-relay";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { useBusinessContext } from "../../../../Context/BusinessContext";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../../Portal/HeaderPortal";
import Loader from "../../../../common/Loader";
import MetadataTypesProfileForm from "./MetadataTypesProfileForm";
import { GetSingleBusinessMetadataTypeQuery } from "../MetadataTypesQueriesMutations";
import { MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery } from "../__generated__/MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery.graphql";
import { useAppRouter } from "../../../../hooks/useAppRouter";

type MatchParams = {
  metadata_type_id?: string;
};

type Props = {
  initialQueryRef: PreloadedQuery<MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery>;
};

export default function MetadataTypesProfile({ initialQueryRef }: Props) {
  const { t } = useTranslation("metadata-types");
  const { business } = useBusinessContext();
  const router = useAppRouter<MatchParams>();

  const [metadataTypeQueryRef, loadQuery] = useQueryLoader(
    GetSingleBusinessMetadataTypeQuery,
    initialQueryRef,
  );

  const { metadata_type_id: metadataTypeId } = router.params;
  const goBackUrl = router.getGoBackUrl("/metadata_types");

  useEffect(() => {
    if (business?.id) {
      loadQuery(
        {
          businessId: business.id,
          id: metadataTypeId ?? "",
          skip: metadataTypeId == null,
        },
        {
          fetchPolicy: "network-only",
        },
      );
    }
  }, [business, metadataTypeId, loadQuery]);

  return (
    <Suspense
      fallback={
        <>
          <HeaderPortal as="span">
            <HeaderPortalBreadcrumbs
              breadcrumbs={[
                <Link to={goBackUrl}>
                  <span>{t("nav.metadataTypes")}</span>
                </Link>,
              ]}
            />
          </HeaderPortal>
          <Loader />
        </>
      }
    >
      <Card body>
        {business && metadataTypeQueryRef != null && (
          <MetadataTypesProfileForm
            goBackUrl={goBackUrl}
            isCreate={metadataTypeId == null}
            queryReference={metadataTypeQueryRef}
            businessId={business.id}
            metadataTypeId={metadataTypeId}
          />
        )}
      </Card>
    </Suspense>
  );
}
