import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import * as yup from "yup";
import { FormikContext, FormikContextType } from "formik";
import { difference, without } from "lodash";
import {
  useDynamicFieldsLayout,
  useMetadataTypesForLayouts,
} from "../../MetadataLayoutQueriesMutations";
import FormLayout from "../../../../../Form/FormLayout";
import SortableLayoutGroups from "./SortableLayoutGroups";
import {
  DynamicFieldsLayout,
  DynamicFieldsLayoutView,
} from "../../../../../../generated/stack_internal_schema";
import { MetadataUtility } from "../../../../Employment/EmploymentMetadata/MetadataUtility";

type Props = {
  onClose: () => void;
  onOk: () => void;
  dynamicBusinessLayout: DynamicFieldsLayout;
  view: DynamicFieldsLayoutView;
};

const StyledModalTitle = styled(Modal.Title)`
  font-size: 20px;
`;

const StyledModal = styled(Modal)`
  min-width: 700px;
`;

type EditViewModalForm = {
  groups: string[];
};

export default function EditViewModal({ onClose, onOk, view }: Props) {
  const [
    { groups, views },
    {
      updateView,
      groupsHaveOverlappingFields,
      isMetadataTypeVisibleInView,
      getMetadataTypesFromGroupNames,
    },
  ] = useDynamicFieldsLayout();
  const { getMetadataTypesFromFieldLayouts } = useMetadataTypesForLayouts()[1];

  const { t } = useTranslation("metadata-types");

  const onSave = (form: Partial<EditViewModalForm>) => {
    updateView(
      view.name,
      form.groups ?? [],
      () => {
        onClose();
      },
      () => {
        onClose();
      },
    );
  };

  const formData = {
    groups: view.groups,
  };

  const groupOptions = groups.map((x) => ({ label: x.name, value: x.name }));

  const validationRules = yup.object({
    groups: yup
      .array()
      .of(
        yup
          .string()
          .required()
          .label(t("metadataLayout.views.editModal.fields.group.label")),
      )
      .test(
        "overlapping_group_fields",
        t("metadataLayout.views.editModal.errors.overlappingGroupFields"),
        (testGroups: string[]) => {
          return !groupsHaveOverlappingFields(testGroups);
        },
      ),
  });

  return (
    <StyledModal backdrop="static" centered onHide={onClose} show>
      <Modal.Header closeButton>
        <StyledModalTitle>
          {t("metadataLayout.views.editModal.title", {
            viewName: view.name,
          })}
        </StyledModalTitle>
      </Modal.Header>
      <Modal.Body>
        <p>{t("metadataLayout.views.editModal.description")}</p>
        <FormLayout<EditViewModalForm>
          formId="metadata-view-list"
          base={formData}
          onSave={onSave}
          propertyList={[]}
          validationRules={validationRules}
          hidePrompt
          isCreate
        >
          <FormikContext.Consumer>
            {({ values }: FormikContextType<DynamicFieldsLayoutView>) => {
              const { groups: updatedGroups } = values;
              const originalGroups = view.groups || [];
              const removedGroups = difference(originalGroups, updatedGroups);
              const otherViews = view ? without(views, view) : views;

              // get all unique fields as a result of removed groups
              const removedFields =
                getMetadataTypesFromGroupNames(removedGroups);

              // filter to only fields that need warnings
              const warningFields = getMetadataTypesFromFieldLayouts(
                Array.from(removedFields.values()),
              ).filter((i) => {
                return (
                  i &&
                  MetadataUtility.metadataTypeShouldBeVisible(i) &&
                  !isMetadataTypeVisibleInView(i, otherViews)
                );
              });

              return (
                <>
                  {warningFields.length > 0 && (
                    <Alert variant="warning">
                      {t("metadataLayout.warnings.requiredFields", {
                        fields: warningFields.map((x) => x.name).join(", "),
                      })}
                    </Alert>
                  )}
                  <SortableLayoutGroups options={groupOptions} />
                </>
              );
            }}
          </FormikContext.Consumer>
        </FormLayout>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="mr-4" onClick={onClose}>
          {t("translation:form.actions.cancel")}
        </Button>
        <Button type="submit" variant="primary" form="metadata-view-list">
          {t("translation:form.actions.save")}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
}
