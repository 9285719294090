/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RuleSetSubscriptionStatusEnum = "not_subscribed" | "production" | "staging" | "%future added value";
export type RemoveRulesOnScheduleMutationVariables = {
    businessId: string;
    scheduleId: string;
};
export type RemoveRulesOnScheduleMutationResponse = {
    readonly removeRulesOnSchedule: {
        readonly ruleSetName: string | null;
        readonly ruleSetVersion: string | null;
        readonly ruleSetSubscriptionStatus: RuleSetSubscriptionStatusEnum;
        readonly ruleSetMeta: unknown;
    };
};
export type RemoveRulesOnScheduleMutation = {
    readonly response: RemoveRulesOnScheduleMutationResponse;
    readonly variables: RemoveRulesOnScheduleMutationVariables;
};



/*
mutation RemoveRulesOnScheduleMutation(
  $businessId: ID!
  $scheduleId: ID!
) {
  removeRulesOnSchedule(businessId: $businessId, scheduleId: $scheduleId) {
    ruleSetName
    ruleSetVersion
    ruleSetSubscriptionStatus
    ruleSetMeta
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "scheduleId",
    "variableName": "scheduleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetVersion",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetSubscriptionStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetMeta",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveRulesOnScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "removeRulesOnSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveRulesOnScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "removeRulesOnSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7dca5993d85bee13d34fec9663c1c096",
    "id": null,
    "metadata": {},
    "name": "RemoveRulesOnScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveRulesOnScheduleMutation(\n  $businessId: ID!\n  $scheduleId: ID!\n) {\n  removeRulesOnSchedule(businessId: $businessId, scheduleId: $scheduleId) {\n    ruleSetName\n    ruleSetVersion\n    ruleSetSubscriptionStatus\n    ruleSetMeta\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6171170cb16f1c002fa46d04151fd7f5';
export default node;
