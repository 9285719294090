import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Dictionary } from "lodash";
import Field from "../../../Form/Field";
import { FloatFieldType } from "../../../Form/models";
import DurationInput from "../../../Form/DurationInput";
import { AosQueryMutations_BasicProfile_Fragment } from "./__generated__/AosQueryMutations_BasicProfile_Fragment.graphql";

type BasicAOSSettings = AosQueryMutations_BasicProfile_Fragment;

type Props = {
  customSettings: EmployeeGroup[];
  fieldKey: string;
  disabled?: boolean;
  aosGroupsByKey: Dictionary<{
    group: number | null;
    name: string | null;
  }>;
};

const StyledTd = styled("td")`
  line-height: 28px;
`;

export type EmployeeGroup = {
  empl_rating: number;
  name: string;
  weekly_workhours_min: number;
  weekly_workhours_max: number;
  shift_min: number;
  shift_max: number;
};

export default function EmployeeGroups(props: Props) {
  const { t } = useTranslation();
  const { fieldKey, disabled, customSettings, aosGroupsByKey } = props;

  return (
    <fieldset>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Table hover size="sm">
            <thead>
              <tr>
                <th style={{ minWidth: "80px" }}>
                  {t("aos:basic.sections.custom_hours.group")}
                </th>
                <th style={{ minWidth: "200px" }}>
                  {t("aos:basic.sections.custom_hours.name")}
                </th>
                <th>
                  <div>
                    {t("aos:basic.sections.custom_hours.min_hours_per_week")}
                  </div>
                  <Form.Text className="text-muted mb-0 text-lowercase">
                    {t(
                      "aos:basic.sections.custom_hours.min_hours_per_week_description",
                    )}
                  </Form.Text>
                </th>
                <th>
                  <div>
                    {t("aos:basic.sections.custom_hours.max_hours_per_week")}
                  </div>
                  <Form.Text className="text-muted mb-0 text-lowercase">
                    {t(
                      "aos:basic.sections.custom_hours.max_hours_per_week_description",
                    )}
                  </Form.Text>
                </th>
                <th>
                  <div>
                    {t("aos:basic.sections.custom_hours.min_hours_per_shift")}
                  </div>
                  <Form.Text className="text-muted mb-0 text-lowercase">
                    {t(
                      "aos:basic.sections.custom_hours.min_hours_per_shift_description",
                    )}
                  </Form.Text>
                </th>
                <th>
                  <div>
                    {t("aos:basic.sections.custom_hours.max_hours_per_shift")}
                  </div>
                  <Form.Text className="text-muted mb-0 text-lowercase">
                    {t(
                      "aos:basic.sections.custom_hours.max_hours_per_shift_description",
                    )}
                  </Form.Text>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {customSettings.map((i: EmployeeGroup, index: number) => {
                const { empl_rating: employeeRating } = i;
                const key = `${fieldKey}[${index}]`;
                const aosGroup = aosGroupsByKey[employeeRating];
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`employee-group${employeeRating}`}>
                    <StyledTd>
                      {t(
                        "aos:basic.sections.custom_hours.employee_rating_label",
                        {
                          rating: employeeRating,
                        },
                      )}
                    </StyledTd>
                    <StyledTd>{aosGroup ? aosGroup.name : "-"}</StyledTd>
                    <td>
                      <Row>
                        <Field
                          size="sm"
                          md={12}
                          lg={12}
                          hideLabel
                          disabled={disabled}
                          fieldKey={`${key}.weekly_workhours_min`}
                          schemaFieldType={FloatFieldType}
                          component={DurationInput}
                          componentProps={{
                            size: "sm",
                            from: "minutes",
                            to: "hours",
                            postfix: t("form.hour_plural"),
                          }}
                        />
                      </Row>
                    </td>
                    <td>
                      <Row>
                        <Field
                          size="sm"
                          md={12}
                          lg={12}
                          hideLabel
                          disabled={disabled}
                          fieldKey={`${key}.weekly_workhours_max`}
                          schemaFieldType={FloatFieldType}
                          component={DurationInput}
                          componentProps={{
                            size: "sm",
                            from: "minutes",
                            to: "hours",
                            postfix: t("form.hour_plural"),
                          }}
                        />
                      </Row>
                    </td>
                    <td>
                      <Row>
                        <Field
                          size="sm"
                          md={12}
                          lg={12}
                          hideLabel
                          disabled={disabled}
                          fieldKey={`${key}.shift_min`}
                          schemaFieldType={FloatFieldType}
                          component={DurationInput}
                          componentProps={{
                            size: "sm",
                            from: "minutes",
                            to: "hours",
                            postfix: t("form.hour_plural"),
                          }}
                        />
                      </Row>
                    </td>
                    <td>
                      <Row>
                        <Field
                          size="sm"
                          md={12}
                          lg={12}
                          hideLabel
                          disabled={disabled}
                          fieldKey={`${key}.shift_max`}
                          schemaFieldType={FloatFieldType}
                          component={DurationInput}
                          componentProps={{
                            size: "sm",
                            from: "minutes",
                            to: "hours",
                            postfix: t("form.hour_plural"),
                          }}
                        />
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </fieldset>
  );
}
