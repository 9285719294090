import React from "react";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import Footer from "./Footer";

type Props = {
  isCreate?: boolean;
  onDelete?: () => void;
  leftActionButtons?: {
    before: boolean;
    buttons: React.ReactNode;
  };
  rightActionButtons?: {
    before: boolean;
    buttons: React.ReactNode;
  };
};

const FormLayoutFooter: React.FunctionComponent<Props> = ({
  onDelete,
  children,
  isCreate,
  leftActionButtons,
  rightActionButtons,
}) => {
  const { handleReset, dirty, isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  return (
    <Footer>
      <Nav className="mr-auto">
        {leftActionButtons &&
          leftActionButtons.before &&
          leftActionButtons.buttons}
        {onDelete && !isCreate ? (
          <Button variant="danger" onClick={onDelete}>
            {t("form.actions.remove")}
          </Button>
        ) : null}
        {leftActionButtons &&
          !leftActionButtons.before &&
          leftActionButtons.buttons}
      </Nav>
      <Nav>
        {children}
        {rightActionButtons &&
          rightActionButtons.before &&
          rightActionButtons.buttons}
        <Button
          variant="outline-primary"
          onClick={handleReset}
          className="mr-2"
          disabled={!dirty}
        >
          {t("form.actions.reset")}
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={!isCreate && (isSubmitting || !dirty)}
        >
          {t("form.actions.save")}
        </Button>
        {rightActionButtons &&
          !rightActionButtons.before &&
          rightActionButtons.buttons}
      </Nav>
    </Footer>
  );
};

export default FormLayoutFooter;
