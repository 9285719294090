import { commitMutation, graphql, Environment } from "react-relay";

const mutation = graphql`
  mutation UpdateStackMutation(
    $domainName: String!
    $stackCode: String!
    $deleted: Boolean!
  ) {
    updateStack(
      domainName: $domainName
      stackCode: $stackCode
      deleted: $deleted
    ) {
      id
      domainName
      stackCode
      deleted
    }
  }
`;

export default (
  environment: Environment,
  stackId: string,
  domainName: string,
  stackCode: string,
  deleted: boolean,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    domainName,
    stackCode,
    deleted,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
    updater: (proxyStore) => {
      // const stack = proxyStore.getRootField("Stack")

      // const newVote = createVoteField.getLinkedRecord('vote')
      // const updatedLink = newVote.getLinkedRecord('link')
      // const newVotes = updatedLink.getLinkedRecord('votes')
      // const newVoteCount = newVotes.getValue('count')

      const stack = proxyStore.get(stackId);
      if (!stack) {
        return;
      }

      stack.setValue(domainName, "domainName");
      stack.setValue(stackCode, "stackCode");
    },
  });
};
