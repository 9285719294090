/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Employments_InternalQueryVariables = {
    businessId: string;
    searchValue?: string | null | undefined;
    deleted?: boolean | null | undefined;
    acceptedInvite?: boolean | null | undefined;
    userLinked?: boolean | null | undefined;
    currentlyEmployed?: boolean | null | undefined;
    pageSize: number;
    after?: string | null | undefined;
};
export type Employments_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EmploymentTable_viewer">;
};
export type Employments_InternalQuery = {
    readonly response: Employments_InternalQueryResponse;
    readonly variables: Employments_InternalQueryVariables;
};



/*
query Employments_InternalQuery(
  $businessId: ID!
  $searchValue: String
  $deleted: Boolean
  $acceptedInvite: Boolean
  $userLinked: Boolean
  $currentlyEmployed: Boolean
  $pageSize: Int!
  $after: String
) {
  ...EmploymentTable_viewer
}

fragment EmploymentTable_viewer on InternalQuery {
  employments(businessId: $businessId, search: $searchValue, deleted: $deleted, acceptedInvite: $acceptedInvite, userLinked: $userLinked, currentlyEmployed: $currentlyEmployed, first: $pageSize, after: $after) {
    edges {
      node {
        id
        firstName
        lastName
        email
        userId
        acceptedInvite
        deleted
        securityRole
        updatedAt
        userEmail
        during
        businessInvite {
          id
          expiryTime
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acceptedInvite"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentlyEmployed"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleted"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchValue"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userLinked"
},
v8 = [
  {
    "kind": "Variable",
    "name": "acceptedInvite",
    "variableName": "acceptedInvite"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "currentlyEmployed",
    "variableName": "currentlyEmployed"
  },
  {
    "kind": "Variable",
    "name": "deleted",
    "variableName": "deleted"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchValue"
  },
  {
    "kind": "Variable",
    "name": "userLinked",
    "variableName": "userLinked"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Employments_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EmploymentTable_viewer"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Employments_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "EmploymentConnection",
        "kind": "LinkedField",
        "name": "employments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Employment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acceptedInvite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "securityRole",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "during",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BusinessInvite",
                    "kind": "LinkedField",
                    "name": "businessInvite",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expiryTime",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [
          "businessId",
          "search",
          "deleted",
          "acceptedInvite",
          "userLinked",
          "currentlyEmployed"
        ],
        "handle": "connection",
        "key": "EmploymentTable_employments",
        "kind": "LinkedHandle",
        "name": "employments"
      }
    ]
  },
  "params": {
    "cacheID": "74a9cb693a8f4bebc65750793b978606",
    "id": null,
    "metadata": {},
    "name": "Employments_InternalQuery",
    "operationKind": "query",
    "text": "query Employments_InternalQuery(\n  $businessId: ID!\n  $searchValue: String\n  $deleted: Boolean\n  $acceptedInvite: Boolean\n  $userLinked: Boolean\n  $currentlyEmployed: Boolean\n  $pageSize: Int!\n  $after: String\n) {\n  ...EmploymentTable_viewer\n}\n\nfragment EmploymentTable_viewer on InternalQuery {\n  employments(businessId: $businessId, search: $searchValue, deleted: $deleted, acceptedInvite: $acceptedInvite, userLinked: $userLinked, currentlyEmployed: $currentlyEmployed, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        email\n        userId\n        acceptedInvite\n        deleted\n        securityRole\n        updatedAt\n        userEmail\n        during\n        businessInvite {\n          id\n          expiryTime\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4e749d32edf29e67e7aecdc1417d905d';
export default node;
