/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AccountLayout_QueryVariables = {
    userId: string;
};
export type AccountLayout_QueryResponse = {
    readonly users: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly email: string;
            readonly lockedMinutesRemaining: number | null;
            readonly confirmedAt: string | null;
            readonly confirmationSentAt: string | null;
            readonly confirmationToken: unknown | null;
            readonly deleted: boolean;
            readonly userStacks: ReadonlyArray<{
                readonly stackId: string;
                readonly synced: boolean;
            }>;
            readonly devices: ReadonlyArray<{
                readonly id: string;
            }>;
        } | null> | null;
    };
};
export type AccountLayout_Query = {
    readonly response: AccountLayout_QueryResponse;
    readonly variables: AccountLayout_QueryVariables;
};



/*
query AccountLayout_Query(
  $userId: ID!
) {
  users(ids: [$userId]) {
    nodes {
      id
      email
      lockedMinutesRemaining
      confirmedAt
      confirmationSentAt
      confirmationToken
      deleted
      userStacks {
        stackId
        synced
      }
      devices {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "userId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedMinutesRemaining",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmationSentAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmationToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserStack",
            "kind": "LinkedField",
            "name": "userStacks",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stackId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "synced",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "devices",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountLayout_Query",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountLayout_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2548cbd65ab703c6a2ce3c126afce0aa",
    "id": null,
    "metadata": {},
    "name": "AccountLayout_Query",
    "operationKind": "query",
    "text": "query AccountLayout_Query(\n  $userId: ID!\n) {\n  users(ids: [$userId]) {\n    nodes {\n      id\n      email\n      lockedMinutesRemaining\n      confirmedAt\n      confirmationSentAt\n      confirmationToken\n      deleted\n      userStacks {\n        stackId\n        synced\n      }\n      devices {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f143d4385fd3e2424d0cd044551ae2b1';
export default node;
