import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Disposable, UseMutationConfig } from "react-relay";
import styled from "styled-components";
import { MetadataType } from "../../../../../generated/stack_internal_schema";
import ServerError from "../../../../../utils/server-error";

type Props = {
  onClose: () => void;
  metadataType: MetadataType;
  deleteMutation: (config: UseMutationConfig<any>) => Disposable;
  businessId: string;
  onSuccess: () => void;
};

const StyledErrorMessage = styled.p`
  margin-top: 12px;
  margin-bottom: 0;
  color: ${(props) => props.theme.error};
  max-width: 498px;
  height: 16px;
`;

const StyledModalTitle = styled(Modal.Title)`
  font-size: 20px;
`;

export function RemoveMetadataTypeModal({
  onClose,
  metadataType,
  deleteMutation,
  businessId,
  onSuccess,
}: Props) {
  const { t } = useTranslation("metadata-types");
  const [error, setError] = useState<string | null>(null);

  const cancelModal = () => {
    setError(null);
    onClose();
  };

  const onOk = () => {
    deleteMutation({
      variables: {
        businessId,
        id: metadataType.id,
      },
      onCompleted: () => {
        onSuccess();
      },
      onError(e) {
        const { source } = (e as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        setError(serverError.getErrorDetails());
      },
    });
  };

  return (
    <Modal centered onHide={cancelModal} show>
      <Modal.Header closeButton>
        <StyledModalTitle>{t("deleteModal.title")}</StyledModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="metadata-types:deleteModal.body"
          values={{ name: metadataType.name }}
          components={{ bold: <strong /> }}
        />
        <StyledErrorMessage className="d-block">{error}</StyledErrorMessage>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="mr-4" onClick={cancelModal}>
          {t("deleteModal.actions.cancel")}
        </Button>
        <Button disabled={error != null} variant="danger" onClick={onOk}>
          {t("deleteModal.actions.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
