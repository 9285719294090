/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Order = "asc" | "desc" | "%future added value";
export type PayPeriodApprovalStatus = "approved" | "open" | "posted" | "processed" | "%future added value";
export type PayPeriodSortField = "endTime" | "id" | "payPeriodStatus" | "startTime" | "%future added value";
export type PayPeriodSort = {
    field: PayPeriodSortField;
    order: Order;
};
export type PayPeriods_InternalQueryVariables = {
    pageSize: number;
    after?: string | null | undefined;
    businessId: string;
    scheduleId: string;
    payPeriodStatus?: PayPeriodApprovalStatus | null | undefined;
    startDateTime: string;
    endDateTime: string;
    sort?: Array<PayPeriodSort> | null | undefined;
};
export type PayPeriods_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"PayPeriodTable_viewer">;
};
export type PayPeriods_InternalQuery = {
    readonly response: PayPeriods_InternalQueryResponse;
    readonly variables: PayPeriods_InternalQueryVariables;
};



/*
query PayPeriods_InternalQuery(
  $pageSize: Int!
  $after: String
  $businessId: ID!
  $scheduleId: ID!
  $payPeriodStatus: PayPeriodApprovalStatus
  $startDateTime: ISO8601DateTime!
  $endDateTime: ISO8601DateTime!
  $sort: [PayPeriodSort!]
) {
  ...PayPeriodTable_viewer
}

fragment PayPeriodTable_viewer on InternalQuery {
  payPeriods(first: $pageSize, after: $after, businessId: $businessId, scheduleId: $scheduleId, payPeriodStatus: $payPeriodStatus, startDateTime: $startDateTime, endDateTime: $endDateTime, sort: $sort) {
    edges {
      node {
        id
        startTime
        endTime
        payPeriodStatus
        postedAt
        postedById
        approvedAt
        approvedById
        processedAt
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateTime"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payPeriodStatus"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scheduleId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateTime"
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "endDateTime",
    "variableName": "endDateTime"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "payPeriodStatus",
    "variableName": "payPeriodStatus"
  },
  {
    "kind": "Variable",
    "name": "scheduleId",
    "variableName": "scheduleId"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  },
  {
    "kind": "Variable",
    "name": "startDateTime",
    "variableName": "startDateTime"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayPeriods_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PayPeriodTable_viewer"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "PayPeriods_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "PayPeriodConnection",
        "kind": "LinkedField",
        "name": "payPeriods",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayPeriodEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayPeriod",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payPeriodStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postedById",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "approvedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "approvedById",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "processedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [
          "businessId",
          "scheduleId",
          "payPeriodStatus",
          "startDateTime",
          "endDateTime",
          "sort"
        ],
        "handle": "connection",
        "key": "PayPeriodTable_payPeriods",
        "kind": "LinkedHandle",
        "name": "payPeriods"
      }
    ]
  },
  "params": {
    "cacheID": "9fb7884effd85e9903d2200c42117da5",
    "id": null,
    "metadata": {},
    "name": "PayPeriods_InternalQuery",
    "operationKind": "query",
    "text": "query PayPeriods_InternalQuery(\n  $pageSize: Int!\n  $after: String\n  $businessId: ID!\n  $scheduleId: ID!\n  $payPeriodStatus: PayPeriodApprovalStatus\n  $startDateTime: ISO8601DateTime!\n  $endDateTime: ISO8601DateTime!\n  $sort: [PayPeriodSort!]\n) {\n  ...PayPeriodTable_viewer\n}\n\nfragment PayPeriodTable_viewer on InternalQuery {\n  payPeriods(first: $pageSize, after: $after, businessId: $businessId, scheduleId: $scheduleId, payPeriodStatus: $payPeriodStatus, startDateTime: $startDateTime, endDateTime: $endDateTime, sort: $sort) {\n    edges {\n      node {\n        id\n        startTime\n        endTime\n        payPeriodStatus\n        postedAt\n        postedById\n        approvedAt\n        approvedById\n        processedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c0f024472970ee98b7bb19cc0470cb88';
export default node;
