import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Profile from "./Profile";
import { BusinessContext } from "../../Context/BusinessContext";
import Loader from "../../common/Loader";
import {
  AOSTemplateProfile_Query,
  AOSTemplateProfile_QueryResponse,
} from "./__generated__/AOSTemplateProfile_Query.graphql";
import { AosConfigBasic } from "../Schedule/AOS/models";

const AOSTemplateProfileQuery = graphql`
  query AOSTemplateProfile_Query($businessId: ID!, $id: ID!) {
    aosConfigTemplates(businessId: $businessId, ids: [$id]) {
      edges {
        node {
          ...Profile_template
        }
      }
    }
  }
`;

type EdgeType =
  {} & AOSTemplateProfile_QueryResponse["aosConfigTemplates"]["edges"];
type Edge = {} & EdgeType[number];
export type NodeType = Edge["node"];

interface MatchParams {
  stack_id: string;
  business_id: string;
  aos_template_id?: string;
  schedule_id?: string;
  clone_from_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {
  location: {
    query?: {
      aosConfig?: AosConfigBasic;
    };
  };
};

export default class AOSTemplateProfile extends React.Component<Props> {
  static contextType = BusinessContext;

  context!: React.ContextType<typeof BusinessContext>;

  render() {
    const { match, ...rest } = this.props;
    const { params } = match;
    const { aos_template_id: aosTemplateId, business_id: businessId } = params;
    const { environment } = this.context;

    if (!environment) {
      return null;
    }

    const getProfile = (item: NodeType | null) => {
      return (
        <Card body>
          <Profile
            {...rest}
            match={match}
            template={item}
            environment={environment}
          />
        </Card>
      );
    };

    if (aosTemplateId == null) {
      // create new
      return getProfile(null);
    }

    return (
      <QueryRenderer<AOSTemplateProfile_Query>
        environment={environment}
        query={AOSTemplateProfileQuery}
        variables={{
          businessId,
          id: aosTemplateId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props) {
            return <Loader />;
          }

          return getProfile(
            props?.aosConfigTemplates?.edges?.[0]?.node ?? null,
          );
        }}
      />
    );
  }
}
