/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PayPeriodStatus = "approved" | "open" | "posted" | "processed" | "%future added value";
export type UpdatePayPeriodMutationVariables = {
    businessId: string;
    id: string;
    payPeriodStatus: PayPeriodStatus;
};
export type UpdatePayPeriodMutationResponse = {
    readonly updatePayPeriod: {
        readonly id: string;
        readonly payPeriodStatus: string;
    };
};
export type UpdatePayPeriodMutation = {
    readonly response: UpdatePayPeriodMutationResponse;
    readonly variables: UpdatePayPeriodMutationVariables;
};



/*
mutation UpdatePayPeriodMutation(
  $businessId: ID!
  $id: ID!
  $payPeriodStatus: PayPeriodStatus!
) {
  updatePayPeriod(businessId: $businessId, id: $id, payPeriodStatus: $payPeriodStatus) {
    id
    payPeriodStatus
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "payPeriodStatus"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "payPeriodStatus",
        "variableName": "payPeriodStatus"
      }
    ],
    "concreteType": "PayPeriod",
    "kind": "LinkedField",
    "name": "updatePayPeriod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payPeriodStatus",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePayPeriodMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePayPeriodMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "173a440cdfd30cf0cdd755a66adad596",
    "id": null,
    "metadata": {},
    "name": "UpdatePayPeriodMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePayPeriodMutation(\n  $businessId: ID!\n  $id: ID!\n  $payPeriodStatus: PayPeriodStatus!\n) {\n  updatePayPeriod(businessId: $businessId, id: $id, payPeriodStatus: $payPeriodStatus) {\n    id\n    payPeriodStatus\n  }\n}\n"
  }
};
})();
(node as any).hash = '73c92b3153093d3eac9f0a6cff9a6254';
export default node;
