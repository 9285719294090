/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserCorporatesPaginationQueryVariables = {
    after?: string | null | undefined;
    corporateIds?: Array<string> | null | undefined;
    emailIncludes?: string | null | undefined;
    pageSize?: number | null | undefined;
};
export type UserCorporatesPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CorporatesQueryMutations_ListFragment">;
};
export type UserCorporatesPaginationQuery = {
    readonly response: UserCorporatesPaginationQueryResponse;
    readonly variables: UserCorporatesPaginationQueryVariables;
};



/*
query UserCorporatesPaginationQuery(
  $after: String
  $corporateIds: [ID!]
  $emailIncludes: String
  $pageSize: Int
) {
  ...CorporatesQueryMutations_ListFragment
}

fragment CorporatesQueryMutations_ListFragment on InternalQuery {
  userCorporates(corporateIds: $corporateIds, emailIncludes: $emailIncludes, first: $pageSize, after: $after) {
    edges {
      node {
        id
        user {
          id
          email
        }
        personKey
        lastAccessedAt
        business {
          businessName
          id
        }
        corporate {
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "corporateIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "emailIncludes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "corporateIds",
    "variableName": "corporateIds"
  },
  {
    "kind": "Variable",
    "name": "emailIncludes",
    "variableName": "emailIncludes"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCorporatesPaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CorporatesQueryMutations_ListFragment"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCorporatesPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserCorporateConnection",
        "kind": "LinkedField",
        "name": "userCorporates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCorporateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserCorporate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastAccessedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Business",
                    "kind": "LinkedField",
                    "name": "business",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "businessName",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Corporate",
                    "kind": "LinkedField",
                    "name": "corporate",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "corporateIds",
          "emailIncludes"
        ],
        "handle": "connection",
        "key": "CorporatesTable_userCorporates",
        "kind": "LinkedHandle",
        "name": "userCorporates"
      }
    ]
  },
  "params": {
    "cacheID": "457e9b395c33009a17a6dc48ab9ef172",
    "id": null,
    "metadata": {},
    "name": "UserCorporatesPaginationQuery",
    "operationKind": "query",
    "text": "query UserCorporatesPaginationQuery(\n  $after: String\n  $corporateIds: [ID!]\n  $emailIncludes: String\n  $pageSize: Int\n) {\n  ...CorporatesQueryMutations_ListFragment\n}\n\nfragment CorporatesQueryMutations_ListFragment on InternalQuery {\n  userCorporates(corporateIds: $corporateIds, emailIncludes: $emailIncludes, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n        personKey\n        lastAccessedAt\n        business {\n          businessName\n          id\n        }\n        corporate {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e11ee61449a8b1b70daacb641536dcee';
export default node;
