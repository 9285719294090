import React from "react";
import ReactDOM from "react-dom";

type Props = {};

export default class ModalPortal extends React.Component<Props> {
  private el: HTMLElement;

  private portalRoot: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement("div");
    this.getHeaderPortal();
  }

  getHeaderPortal() {
    if (!this.portalRoot) {
      this.portalRoot = document.getElementById("modal");
    }
  }

  componentDidMount = () => {
    this.getHeaderPortal();
    if (this.portalRoot) {
      this.portalRoot.appendChild(this.el);
    }
  };

  componentWillUnmount = () => {
    if (this.portalRoot) {
      this.portalRoot.removeChild(this.el);
    }
  };

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}
