import { graphql } from "react-relay";

export const GetBusinessBreakTypesQuery = graphql`
  query BreakTypeQueriesMutations_GetBusinessBreakTypes_Query(
    $businessId: ID!
    $search: String
    $includeDeleted: Boolean
  ) {
    breakTypes(
      businessId: $businessId
      search: $search
      includeDeleted: $includeDeleted
    ) {
      nodes {
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        breakScreenOrdering
        breakTypeCode
        canOverrideClockedPaidBreak
        code
        createdAt
        defaultDuration
        deleted
        description
        employmentMetadata
        employmentTypes
        employmentTypeCodes
        id
        legacyBreakType
        name
        paidThreshold
        schedulable
        updatedAt
      }
    }
  }
`;

export const GetScheduleBreakTypesQuery = graphql`
  query BreakTypeQueriesMutations_GetScheduleBreakTypes_Query(
    $businessId: ID!
    $scheduleId: ID!
    $search: String
  ) {
    scheduleBreakTypes(
      businessId: $businessId
      scheduleId: $scheduleId
      search: $search
    ) {
      nodes {
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        breakScreenOrdering
        breakTypeCode
        breakTypeId
        canOverrideClockedPaidBreak
        code
        createdAt
        defaultDuration
        deleted
        description
        employmentMetadata
        employmentTypes
        hidden
        id
        legacyBreakType
        name
        paidThreshold
        schedulable
        scheduleId
        scheduleOverride
        updatedAt
      }
    }
  }
`;

export const GetSingleBusinessBreakTypeQuery = graphql`
  query BreakTypeQueriesMutations_GetSingleBusinessBreakType_Query(
    $businessId: ID!
    $id: ID!
    $skip: Boolean!
  ) {
    breakTypes(businessId: $businessId, id: $id, includeDeleted: true)
      @skip(if: $skip) {
      nodes {
        id
        deleted
        ### Replaceable content start
        name
        breakTypeCode
        legacyBreakType
        breakScreenOrdering
        description
        code
        defaultDuration
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        schedulable
        paidThreshold
        canOverrideClockedPaidBreak
        employmentTypeCodes
        employmentMetadata
        ### Replaceable content finish
      }
    }
  }
`;

export const GetSingleScheduleBreakTypeQuery = graphql`
  query BreakTypeQueriesMutations_GetSingleScheduleBreakType_Query(
    $businessId: ID!
    $scheduleId: ID!
    $id: ID!
    $skip: Boolean!
  ) {
    scheduleBreakTypes(
      businessId: $businessId
      scheduleId: $scheduleId
      id: $id
    ) @skip(if: $skip) {
      nodes {
        hidden
        id
        deleted
        schedulePaidThreshold
        ### Replaceable content start
        name
        breakTypeCode
        legacyBreakType
        breakScreenOrdering
        description
        code
        defaultDuration
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        schedulable
        paidThreshold
        canOverrideClockedPaidBreak
        employmentTypeCodes
        employmentMetadata
        ### Replaceable content finish
      }
    }
  }
`;

export const UpdateBusinessBreakTypeMutation = graphql`
  mutation BreakTypeQueriesMutations_UpdateBreakType_Mutation(
    $businessId: ID!
    $input: BreakTypeUpdateInput!
    $id: ID!
  ) {
    updateBreakType(businessId: $businessId, id: $id, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const CreateBusinessBreakTypeMutation = graphql`
  mutation BreakTypeQueriesMutations_CreateBreakType_Mutation(
    $businessId: ID!
    $input: BreakTypeCreateInput!
  ) {
    createBreakType(businessId: $businessId, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const CreateScheduleBreakTypeMutation = graphql`
  mutation BreakTypeQueriesMutations_CreateScheduleBreakType_Mutation(
    $businessId: ID!
    $input: ScheduleBreakTypeCreateInput!
  ) {
    createScheduleBreakType(businessId: $businessId, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const UpdateScheduleBreakTypeMutation = graphql`
  mutation BreakTypeQueriesMutations_UpdateScheduleBreakType_Mutation(
    $businessId: ID!
    $input: ScheduleBreakTypeUpdateInput!
    $id: ID!
  ) {
    updateScheduleBreakType(businessId: $businessId, id: $id, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const DeactivateBreakTypeMutation = graphql`
  mutation BreakTypeQueriesMutations_DeactivateBreakType_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deactivateBreakType(businessId: $businessId, id: $id)
  }
`;

export const ReactivateBreakTypeMutation = graphql`
  mutation BreakTypeQueriesMutations_ReactivateBreakType_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    reactivateBreakType(businessId: $businessId, id: $id) {
      id
    }
  }
`;

export const RemoveScheduleBreakTypeOverrideMutation = graphql`
  mutation BreakTypeQueriesMutations_RemoveScheduleBreakTypeOverride_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteScheduleBreakType(businessId: $businessId, id: $id)
  }
`;
