/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent } from "react";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { GlobalBusinessTable_viewer } from "./__generated__/GlobalBusinessTable_viewer.graphql";
import RelayLazyLoader from "../../common/RelayLazyLoader";

const GlobalBusinessTablePageQuery = graphql`
  query GlobalBusinessTable_InternalQuery(
    $globalBusinessName: String!
    $pageSize: Int!
    $after: String
  ) {
    ...GlobalBusinessTable_viewer
  }
`;

interface MatchParams {
  stack_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {
  searchValue: string;
  viewer: GlobalBusinessTable_viewer;
  relay: RelayPaginationProp;
  match: any;
  isManagable: boolean;
};

const GlobalBusinessTableBase: FunctionComponent<Props> = (props: Props) => {
  const {
    viewer,
    relay,
    match: {
      params: { stack_id: stackId },
    },
    isManagable,
  } = props;
  const { t } = useTranslation("global-business");
  const globalBusinessNodes =
    viewer?.globalBusinesses?.edges?.filter((i) => i?.node != null) ?? [];
  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("table.headers.name")}</th>
            <th>{t("table.headers.employmentUidStart")}</th>
            <th>{t("table.headers.employmentUidNext")}</th>
            <th>{t("table.headers.used")}</th>
            <th>{t("table.headers.updatedBy")}</th>
          </tr>
        </thead>
        <tbody>
          {globalBusinessNodes.map((edge) => {
            const {
              name,
              id,
              employmentUidStart,
              employmentUidNext,
              createdByUser,
              updatedByUser,
              used,
            } = edge?.node ?? {};
            const updatedBy = updatedByUser || createdByUser;
            return (
              <tr key={id}>
                <td>
                  {isManagable ? (
                    <Link to={`/stack/${stackId}/global_business/edit/${id}`}>
                      {name}
                    </Link>
                  ) : (
                    name
                  )}
                </td>
                <td>{employmentUidStart}</td>
                <td>{employmentUidNext}</td>
                <td>
                  {used
                    ? t("table.usedOptions.used")
                    : t("table.usedOptions.unused")}
                </td>
                <td>{updatedBy?.email}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <RelayLazyLoader relay={relay} />
    </>
  );
};

export default createPaginationContainer<Props>(
  GlobalBusinessTableBase,
  {
    viewer: graphql`
      fragment GlobalBusinessTable_viewer on InternalQuery {
        globalBusinesses(
          globalBusinessName: $globalBusinessName
          first: $pageSize
          after: $after
        ) @connection(key: "GlobalBusinessTable_globalBusinesses") {
          edges {
            node {
              id
              name
              employmentUidNext
              employmentUidStart
              used
              createdByUser {
                id
                email
              }
              updatedByUser {
                id
                email
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: GlobalBusinessTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.globalBusinesses,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      searchValue: props.searchValue,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
