import React from "react";
import {
  Link,
  Route,
  RouteComponentProps,
  useLocation,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { RelayEnvironmentProvider } from "react-relay";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../../Portal/HeaderPortal";
import CorporatesUsers from "./CorporatesUsers/CorporatesUsers";
import CorporatesAuthMethods from "./CorporatesAuthMethods/CorporatesAuthMethods";
import { useCorporateById } from "../CorporatesQueryMutations";
import { idmInternalEnvironment } from "../../../../../environment";
import Loader from "../../../../common/Loader";
import CorporatesProfile from "./CorporatesProfile/CorporatesProfile";
import CorporatesAuthMethodsLayout from "./CorporatesAuthMethods/CorporatesAuthMethodsLayout";

type Props = RouteComponentProps<{
  corporate_id?: string;
}>;

export default function CorporatesLayout({ match, location }: Props) {
  const { t } = useTranslation("corporates");

  const { corporate_id: corporateId } = match.params;
  const isCreate = corporateId == null;
  const isInAuthMethodContext =
    location.pathname.includes("/auth_methods/create") ||
    location.pathname.includes("/auth_methods/edit");

  return (
    <RelayEnvironmentProvider environment={idmInternalEnvironment}>
      {!isInAuthMethodContext && (
        <header className="sub-header-container">
          <Nav variant="tabs">
            <LinkContainer exact to={`${match.url}`}>
              <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
            </LinkContainer>
            {!isCreate && (
              <>
                <LinkContainer exact to={`${match.url}/auth_methods`}>
                  <Nav.Link>{t("layout.nav.authMethods")}</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to={`${match.url}/users`}>
                  <Nav.Link>{t("layout.nav.users")}</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </header>
      )}

      <React.Suspense fallback={<Loader />}>
        <RoutesWithData
          match={match}
          isInAuthMethodContext={isInAuthMethodContext}
        />
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}

const RoutesWithData = ({
  match,
  isInAuthMethodContext,
}: Pick<Props, "match"> & { isInAuthMethodContext: boolean }) => {
  const { t } = useTranslation("corporates");

  const { corporate_id: corporateId } = useParams<{ corporate_id?: string }>();
  const goBackUrl = `${match.path.split(`/corporates`)[0]}/corporates`;

  // No corporate will be available if we're creating a new one (*GASP*)
  const corporate = useCorporateById(corporateId);

  return (
    <>
      <HeaderPortal as="span">
        <Link to={goBackUrl}>
          <span>{t("layout.title")}</span>
        </Link>
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <span>
              {corporate ? corporate.name : t("layout.newCorporate")}
            </span>,
          ]}
        />
      </HeaderPortal>

      <div className={!isInAuthMethodContext ? "mt-3" : ""}>
        <Route exact path={`${match.path}`} component={CorporatesProfile} />
        <Route exact path={`${match.path}/users`} component={CorporatesUsers} />
        {corporate && (
          <>
            <Route
              exact
              path={`${match.path}/auth_methods`}
              component={CorporatesAuthMethods}
            />
            <Route
              exact
              path={[
                `${match.path}/auth_methods/edit/:auth_method_id`,
                `${match.path}/auth_methods/create`,
              ]}
              component={CorporatesAuthMethodsLayout}
            />
          </>
        )}
      </div>
    </>
  );
};
