/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteModal_EmploymentRolesQueryVariables = {
    businessId: string;
    skillLevelId: string;
};
export type DeleteModal_EmploymentRolesQueryResponse = {
    readonly employmentRoles: {
        readonly totalCount: number;
    };
};
export type DeleteModal_EmploymentRolesQuery = {
    readonly response: DeleteModal_EmploymentRolesQueryResponse;
    readonly variables: DeleteModal_EmploymentRolesQueryVariables;
};



/*
query DeleteModal_EmploymentRolesQuery(
  $businessId: ID!
  $skillLevelId: ID!
) {
  employmentRoles(businessId: $businessId, skillLevelId: $skillLevelId) {
    totalCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skillLevelId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "skillLevelId",
        "variableName": "skillLevelId"
      }
    ],
    "concreteType": "EmploymentRoleConnection",
    "kind": "LinkedField",
    "name": "employmentRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteModal_EmploymentRolesQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteModal_EmploymentRolesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5dcebb37f627ac9ab8d0229cfa6695dc",
    "id": null,
    "metadata": {},
    "name": "DeleteModal_EmploymentRolesQuery",
    "operationKind": "query",
    "text": "query DeleteModal_EmploymentRolesQuery(\n  $businessId: ID!\n  $skillLevelId: ID!\n) {\n  employmentRoles(businessId: $businessId, skillLevelId: $skillLevelId) {\n    totalCount\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b00c75270b86b850ce68c3d027e957bd';
export default node;
