import React from "react";
import { Route, RouteComponentProps, Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql, QueryRenderer, Environment } from "react-relay";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import {
  BusinessContextProvider,
  BusinessInContext,
} from "../Context/BusinessContext";
import { Id, IStack } from "../../models/common";
import BusinessLayout from "./Business/BusinessLayout";
import ScheduleLayout from "./Schedule/ScheduleLayout";
import AOSTemplateLayout from "./AOSTemplates/AOSTemplateLayout";
// import ScheduleTree from "./Schedule/ScheduleTree";
import EmploymentLayout from "./Employment/EmploymentLayout";
import SkillLevelLayout from "./SkillLevels/SkillLevelLayout";
import AppContext, {
  AppContextValue,
} from "../IDM/external/Context/AppContext";

import { StackContextLayout_Query } from "./__generated__/StackContextLayout_Query.graphql";
import { getRegionalStackEnvironment } from "../../environment";
import Loader from "../common/Loader";

const query = graphql`
  query StackContextLayout_Query($businessId: ID!) {
    businesses(ids: [$businessId]) {
      nodes {
        id
        businessName
        aggregateToStartDay
        aosGroups {
          group
          name
        }
        aosConfigSchema
        dataLakeId
        dynamicFieldsLayout {
          views {
            name
            groups
          }
          groups {
            name
            label
            fields {
              metadataTypeName
              width
            }
          }
        }
        analyticsEnabled
        contractsUseEmploymentType
        defaultScheduleDayStartTime
        shiftCanOverlapLeaveRequest
        shiftCanOverlapUnavailability
        disciplinaryActionEnabled
        commendationEnabled
      }
    }
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
  employment_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {
  businessId: Id;
  stackId: Id;
  environment?: Environment;
  stack?: IStack;
};

const NonWrappingRow = styled(Row)`
  flex-wrap: nowrap;
`;
export default class StackContextLayout extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    const properties = props;
    const {
      match: { params },
    } = properties;

    this.state = {
      businessId: params.business_id,
      stackId: params.stack_id,
    };
  }

  componentDidMount() {
    const appContext: AppContextValue = this.context;
    const { stackId } = this.state;
    const stack = appContext.myStacksById?.get(stackId || "");
    appContext.setStackId(stackId);

    this.setState({
      stack,
      environment: getRegionalStackEnvironment(stack?.domainName || ""),
    });
  }

  render() {
    const properties = this.props;
    const { match } = properties;
    const { businessId, environment } = this.state;

    if (!environment) {
      return <div>Environment not found.</div>;
    }

    const getLayout = (b: BusinessInContext | undefined = undefined) => {
      return (
        <AppContext.Consumer>
          {(appContext: AppContextValue) => (
            <BusinessContextProvider
              business={b}
              appContext={appContext}
              environment={environment}
            >
              <NonWrappingRow noGutters>
                {/* <div className="pull-left">
            <div className="bg-light" style={sidebarStyle}>
              <ScheduleTree {...properties}></ScheduleTree>
            </div>
          </div> */}
                {/* <Col style={{ paddingLeft: "200px" }}> */}
                <Col>
                  <Container fluid>
                    <React.Suspense fallback={<Loader />}>
                      <Route
                        path={[
                          `${match.path}/profile`,
                          "/stack/:stack_id/business/create",
                        ]}
                        component={BusinessLayout}
                      />
                      <Route
                        path={`${match.path}/employee/:employment_id`}
                        component={EmploymentLayout}
                      />
                      <Route
                        path={`${match.path}/schedule/:schedule_id`}
                        component={ScheduleLayout}
                      />
                      <Route
                        path={[
                          `${match.path}/aos_template/edit/:aos_template_id`,
                          `${match.path}/aos_template/create/:schedule_id?`,
                          `${match.path}/aos_template/clone/:clone_from_id?`,
                        ]}
                        component={AOSTemplateLayout}
                      />
                      <Route
                        path={[
                          `${match.path}/skill_level/edit/:skill_level_id`,
                          `${match.path}/skill_level/create`,
                        ]}
                        component={SkillLevelLayout}
                      />
                    </React.Suspense>
                  </Container>
                </Col>
              </NonWrappingRow>
            </BusinessContextProvider>
          )}
        </AppContext.Consumer>
      );
    };

    if (!businessId) {
      return getLayout();
    }

    return (
      <QueryRenderer<StackContextLayout_Query>
        environment={environment}
        query={query}
        variables={{
          businessId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props) {
            return <Loader />;
          }

          if (!props.businesses?.nodes?.length || !props.businesses?.nodes[0]) {
            // return <div>Cannot find business with id {businessId}</div>;
            return <Redirect to="/stacks" />;
          }

          const business = props.businesses?.nodes[0];
          return getLayout(business);
        }}
      />
    );
  }
}
