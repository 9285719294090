import React, { FormEventHandler } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Translation, WithTranslation, withTranslation } from "react-i18next";
import { Formik, FormikHelpers, FormikState } from "formik";
import { ModalProps } from "../../../Context/ModalContextLegacy";
import UpdateUserMutation from "../mutations/UpdateUserMutation";
import { idmInternalEnvironment } from "../../../../environment";
import ServerError from "../../../../utils/server-error";
import { IUser } from "../../../../models/common";

type FormValues = {
  password?: string;
};

const validationRules = yup.object({
  password: yup.string().required(),
});

class ChangePasswordDialog extends React.Component<Props> {
  static defaultProps = {};

  private initialFormValues: FormValues = {
    password: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      ...props.modalProps,
      formValues: {
        password: undefined,
      },
    };
  }

  private handleSubmit(
    values: FormValues,
    { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>,
  ) {
    const {
      user: { id: userId },
      modalProps: { hideModal },
      t,
    } = this.props;

    setSubmitting(true);

    UpdateUserMutation(
      idmInternalEnvironment,
      userId,
      values,
      () => {
        setSubmitting(false);
        toast(t("form.notifications.saved_successfully"));
        resetForm(this.initialFormValues as Partial<FormikState<FormValues>>);
        hideModal();
      },
      (error: Error) => {
        setSubmitting(false);
        if (!error) {
          return;
        }
        const serverError = new ServerError(error);
        setFieldError("password", serverError.getErrorDetails());
      },
    );
  }

  render() {
    const { modalProps, user } = this.props;
    const { show, hideModal } = modalProps;
    return (
      <Translation>
        {(t) => (
          <Formik<FormValues>
            validationSchema={validationRules}
            onSubmit={this.handleSubmit.bind(this)}
            initialValues={this.initialFormValues}
          >
            {({ handleSubmit, handleChange, values, errors, resetForm }) => (
              <Modal show={show} onHide={hideModal}>
                <Form onSubmit={handleSubmit as unknown as FormEventHandler}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("change_user_password_dialog.title", {
                        email: user.email,
                      })}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group controlId="new-password">
                      <Form.Label>
                        {t("change_user_password_dialog.password_label")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t(
                          "change_user_password_dialog.new_password",
                        )}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={errors.password != null}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        resetForm(
                          this.initialFormValues as Partial<
                            FormikState<FormValues>
                          >,
                        );
                        hideModal();
                      }}
                    >
                      {t("form.actions.cancel")}
                    </Button>
                    <Button variant="primary" type="submit">
                      {t("form.actions.ok")}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            )}
          </Formik>
        )}
      </Translation>
    );
  }
}

type Props = typeof ChangePasswordDialog.defaultProps &
  WithTranslation & {
    user: IUser;
    modalProps: ModalProps;
  };

export default withTranslation(["translation"])(ChangePasswordDialog);
