import React, { useState } from "react";
import { TFunction } from "i18next";
import isFunction from "lodash/isFunction";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { Id } from "../../../models/common";
import { useModalContext } from "../../Context/ModalContextLegacy";
import DeleteGlobalBusinessMutation from "../mutations/DeleteGlobalBusinessMutation";
import ServerError from "../../../utils/server-error";
import { useAppContext } from "../../IDM/external/Context/AppContext";
import { getRegionalStackEnvironment } from "../../../environment";

export enum CorporateMoalEnum {
  ComfirmDelete,
}

type Corporate = {
  name: string;
  id: Id;
};

type Props = {
  t: TFunction;
  modal: CorporateMoalEnum | null;
  onDeletedCallback?: Function;
  item: Corporate | null;
};

const Modals: React.FunctionComponent<Props> = ({
  modal,
  t,
  item,
  onDeletedCallback,
}) => {
  const { stack } = useAppContext();
  const { show, hideModal } = useModalContext();

  const [error, setError] = useState<string>();
  if (!item) {
    return null;
  }

  const handleDelete = () => {
    if (!stack) {
      return;
    }
    DeleteGlobalBusinessMutation(
      getRegionalStackEnvironment(stack.domainName),
      item.id,
      () => {
        hideModal();
        if (isFunction(onDeletedCallback)) {
          onDeletedCallback();
        }
      },
      (e: Error) => {
        const { source } = (e as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        setError(serverError.getErrorDetails());
      },
    );
  };

  const renderError = () => {
    return error ? (
      <Form.Control.Feedback type="invalid" className="d-block">
        {error}
      </Form.Control.Feedback>
    ) : null;
  };

  const cancelModal = () => {
    setError(undefined);
    hideModal();
  };

  switch (modal) {
    case CorporateMoalEnum.ComfirmDelete:
      return (
        <Modal show={show} onHide={cancelModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t("deleteConfirmation.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t("deleteConfirmation.body", {
                name: item.name,
              })}
              {renderError()}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelModal}>
              {t("deleteConfirmation.cancel")}
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              {t("deleteConfirmation.ok")}
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

export default Modals;
