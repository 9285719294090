/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Accounts_InternalQueryVariables = {
    email: string;
    isLocked?: boolean | null | undefined;
    isConfirmed?: boolean | null | undefined;
    stackId?: string | null | undefined;
    pageSize: number;
    after?: string | null | undefined;
};
export type Accounts_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AccountTable_viewer">;
};
export type Accounts_InternalQuery = {
    readonly response: Accounts_InternalQueryResponse;
    readonly variables: Accounts_InternalQueryVariables;
};



/*
query Accounts_InternalQuery(
  $email: String!
  $isLocked: Boolean
  $isConfirmed: Boolean
  $stackId: ID
  $pageSize: Int!
  $after: String
) {
  ...AccountTable_viewer
}

fragment AccountRow_viewer on User {
  id
  email
  confirmedAt
  confirmationSentAt
  confirmationToken
  lockedMinutesRemaining
  deleted
  locale
  lastSignInAt
  acceptedPolicyAt
  acceptedPolicyVersion
  updatedAt
  userStacks {
    stackId
    synced
  }
}

fragment AccountTable_viewer on InternalQuery {
  users(email: $email, isLocked: $isLocked, isConfirmed: $isConfirmed, stackId: $stackId, first: $pageSize, after: $after) {
    edges {
      node {
        ...AccountRow_viewer
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isConfirmed"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLocked"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stackId"
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "isConfirmed",
    "variableName": "isConfirmed"
  },
  {
    "kind": "Variable",
    "name": "isLocked",
    "variableName": "isLocked"
  },
  {
    "kind": "Variable",
    "name": "stackId",
    "variableName": "stackId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Accounts_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccountTable_viewer"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Accounts_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "confirmedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "confirmationSentAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "confirmationToken",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedMinutesRemaining",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locale",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastSignInAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acceptedPolicyAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acceptedPolicyVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserStack",
                    "kind": "LinkedField",
                    "name": "userStacks",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stackId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "synced",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "filters": [
          "email",
          "isLocked",
          "isConfirmed",
          "stackId"
        ],
        "handle": "connection",
        "key": "AccountTable_users",
        "kind": "LinkedHandle",
        "name": "users"
      }
    ]
  },
  "params": {
    "cacheID": "d09fd8df031d2514fb9b110eefeb7ca5",
    "id": null,
    "metadata": {},
    "name": "Accounts_InternalQuery",
    "operationKind": "query",
    "text": "query Accounts_InternalQuery(\n  $email: String!\n  $isLocked: Boolean\n  $isConfirmed: Boolean\n  $stackId: ID\n  $pageSize: Int!\n  $after: String\n) {\n  ...AccountTable_viewer\n}\n\nfragment AccountRow_viewer on User {\n  id\n  email\n  confirmedAt\n  confirmationSentAt\n  confirmationToken\n  lockedMinutesRemaining\n  deleted\n  locale\n  lastSignInAt\n  acceptedPolicyAt\n  acceptedPolicyVersion\n  updatedAt\n  userStacks {\n    stackId\n    synced\n  }\n}\n\nfragment AccountTable_viewer on InternalQuery {\n  users(email: $email, isLocked: $isLocked, isConfirmed: $isConfirmed, stackId: $stackId, first: $pageSize, after: $after) {\n    edges {\n      node {\n        ...AccountRow_viewer\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3a3c0a616aa73d34bc8965ec695b2cec';
export default node;
