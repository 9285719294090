import React, { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { WithTranslation, withTranslation } from "react-i18next";
import { AOSTemplateTable_viewer } from "./__generated__/AOSTemplateTable_viewer.graphql";
import RelayLazyLoader from "../../common/RelayLazyLoader";
import { toRelative } from "../../../utils/utility";
import AOSTemplateModal, { AOSModalEnum } from "./AOSTemplateModal";
import { withModal, WithModalProps } from "../../Context/ModalContextLegacy";

type EdgeType = {} & AOSTemplateTable_viewer["aosConfigTemplates"]["edges"];
type NodeType = {} & EdgeType[number];
export type AOSTemplate = NodeType["node"];

const AOSTemplateTablePageQuery = graphql`
  query AOSTemplateTable_InternalQuery(
    $businessId: ID!
    $search: String
    $pageSize: Int!
    $after: String
  ) {
    ...AOSTemplateTable_viewer
      @arguments(
        businessId: $businessId
        search: $search
        pageSize: $pageSize
        after: $after
      )
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> &
  WithModalProps &
  WithTranslation & {
    stackId: string;
    businessId: string;
    searchValue: string;
    viewer: AOSTemplateTable_viewer;
    relay: RelayPaginationProp;
  };

type State = {
  modal?: AOSModalEnum;
  aosTemplate?: AOSTemplate;
};

class AOSTemplateTableBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { stackId, businessId, viewer, relay, t, showModal, ...rest } =
      this.props;
    const { modal, aosTemplate } = this.state;
    const nodes = (viewer.aosConfigTemplates.edges || []).map((edge) => {
      const node = edge?.node;
      if (!node) {
        return null;
      }

      const {
        id,
        templateName,
        updatedAt,
        isDefault,
        updatedByName,
        updatedById,
        createdByName,
        createdById,
      } = node;

      const changedById = updatedById || createdById;
      const changedByName = updatedByName || createdByName;
      return (
        <tr key={edge?.node?.id} className="hoverable">
          <td>
            <Link
              to={`/stack/${stackId}/business/${businessId}/aos_template/edit/${id}`}
            >
              {templateName}
              {isDefault ? (
                <Badge variant="secondary" className="ml-2">
                  {t("aosTemplate.default")}
                </Badge>
              ) : null}
            </Link>
          </td>
          <td>
            <Link
              to={`/stack/${stackId}/business/${businessId}/employee/${changedById}`}
            >
              {changedByName}
            </Link>
          </td>
          <td>
            {toRelative(updatedAt as string, {
              defaultValue: "-",
            })}
          </td>
          <td>
            <span className="border-right show-inline-on-hover">
              <Link
                to={`/stack/${stackId}/business/${businessId}/aos_template/clone/${id}`}
              >
                <Button variant="link" className="ml-1 mr-2">
                  Clone
                </Button>
              </Link>
            </span>
            <span className="border-right show-inline-on-hover">
              <Button
                variant="link"
                className="ml-1 mr-2"
                onClick={() => {
                  this.setState({
                    aosTemplate: node,
                    modal: AOSModalEnum.SetAsDefault,
                  });
                  showModal();
                }}
              >
                {isDefault
                  ? t("actions.removeAsDefaultTemplate")
                  : t("actions.setAsDefaultTemplate")}
              </Button>
            </span>
            <Button
              variant="link"
              className="ml-1 mr-2 show-inline-on-hover"
              onClick={() => {
                this.setState({
                  aosTemplate: node,
                  modal: AOSModalEnum.ComfirmDelete,
                });
                showModal();
              }}
            >
              {t("actions.delete")}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>{t("aosTemplatesTable.headers.templateName")}</th>
              <th>{t("aosTemplatesTable.headers.updatedBy")}</th>
              <th>{t("aosTemplatesTable.headers.lastUpdate")}</th>
              <th className="w-25">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </Table>
        <RelayLazyLoader relay={relay} />
        <AOSTemplateModal
          modal={modal}
          businessId={businessId}
          onDeletedCallback={() => relay?.refetchConnection(30)}
          onToggleDefaultCallback={() => relay?.refetchConnection(30)}
          template={aosTemplate || null}
          t={t}
          {...rest}
        />
      </>
    );
  }
}

export default createPaginationContainer(
  withTranslation("aos")(withModal(AOSTemplateTableBase)),
  {
    viewer: graphql`
      fragment AOSTemplateTable_viewer on InternalQuery
      @argumentDefinitions(
        businessId: { type: "ID!" }
        search: { type: "String" }
        pageSize: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        sort: {
          type: "[AosConfigTemplateSort!]"
          defaultValue: [{ field: templateName, order: asc }]
        }
      ) {
        aosConfigTemplates(
          businessId: $businessId
          search: $search
          first: $pageSize
          after: $after
          sort: $sort
        ) @connection(key: "AOSTemplateTable_aosConfigTemplates") {
          edges {
            node {
              id
              templateName
              isDefault
              updatedAt
              updatedByName
              updatedById
              createdByName
              createdById
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: AOSTemplateTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.aosConfigTemplates,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      businessId: props.businessId,
      search: props.searchValue,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
