/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateGlobalBusinessInput = {
    employmentUidStart: BigInt;
    name: string;
};
export type CreateGlobalBusinessMutationVariables = {
    input: CreateGlobalBusinessInput;
};
export type CreateGlobalBusinessMutationResponse = {
    readonly createGlobalBusiness: {
        readonly id: string;
        readonly name: string;
        readonly employmentUidStart: BigInt;
    };
};
export type CreateGlobalBusinessMutation = {
    readonly response: CreateGlobalBusinessMutationResponse;
    readonly variables: CreateGlobalBusinessMutationVariables;
};



/*
mutation CreateGlobalBusinessMutation(
  $input: CreateGlobalBusinessInput!
) {
  createGlobalBusiness(input: $input) {
    id
    name
    employmentUidStart
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GlobalBusiness",
    "kind": "LinkedField",
    "name": "createGlobalBusiness",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentUidStart",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateGlobalBusinessMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateGlobalBusinessMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41b27e59bc6fcce1374d4cfc2054d768",
    "id": null,
    "metadata": {},
    "name": "CreateGlobalBusinessMutation",
    "operationKind": "mutation",
    "text": "mutation CreateGlobalBusinessMutation(\n  $input: CreateGlobalBusinessInput!\n) {\n  createGlobalBusiness(input: $input) {\n    id\n    name\n    employmentUidStart\n  }\n}\n"
  }
};
})();
(node as any).hash = '85c656c17bdde78ac3968e85d5b18d5a';
export default node;
