import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import styled from "styled-components";
import DurationInput from "../../../Form/DurationInput";
import { BreakTypePaidThreshold } from "../../../Form/models";

type Props = {
  disabled: boolean;
  value: number;
  onChange: any;
  businessValue: number;
  showDefaultOption: boolean;
};

enum RadioInputValue {
  DefaultToBusiness,
  Always,
  Never,
  Custom,
}

const StyledRadio = styled(Form.Check).attrs({ custom: true })`
  .custom-control-input {
    z-index: 100;
  }
  .custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    border-color: transparent;
  }
`;

function getDefaultRadioButtonValue(value: number, showDefaultOption: boolean) {
  if (showDefaultOption && value == null) {
    return RadioInputValue.DefaultToBusiness;
  }
  if (value === BreakTypePaidThreshold.NeverPaid) {
    return RadioInputValue.Never;
  }
  if (value >= BreakTypePaidThreshold.AlwaysPaidMinimum) {
    return RadioInputValue.Always;
  }
  if (value != null) {
    return RadioInputValue.Custom;
  }
  return null;
}

function getDefaultInputFieldValue(
  value: number,
  radioValue: RadioInputValue | null,
) {
  return radioValue === RadioInputValue.Custom
    ? value
    : BreakTypePaidThreshold.DefaultUnpaidCustomAmount;
}

// Maps to a single value (paidThreshold). If the final option is selected, then the value inside of the
// input field will be used.
export function PaidThresholdRadioSelect({
  disabled = false,
  value,
  onChange,
  businessValue,
  showDefaultOption,
}: Props) {
  const { t } = useTranslation("break-types");
  const { dirty } = useFormikContext();

  // Manage the value of the selected radio button separately to the actual value to avoid weirdness
  // between the default and custom input value being the same and stopping either from being selected
  const [radioValue, setRadioValue] = useState(
    getDefaultRadioButtonValue(value, showDefaultOption),
  );

  const [paidThresholdInput, setPaidThresholdInput] = useState<
    number | undefined
  >(
    getDefaultInputFieldValue(
      value,
      getDefaultRadioButtonValue(value, showDefaultOption),
    ),
  );

  const getPaidThresholdDescription = (paidThreshold: number) => {
    // If the value is 0 assume it's unpaid
    if (paidThreshold === BreakTypePaidThreshold.NeverPaid) {
      return t("form.paidThresholdRadioGroup.options.default.never");
    }

    if (paidThreshold >= BreakTypePaidThreshold.AlwaysPaidMinimum) {
      return t("form.paidThresholdRadioGroup.options.default.always");
    }

    return t("form.paidThresholdRadioGroup.options.default.unpaid_when_x", {
      paidThreshold,
    });
  };

  function getValueForRadioOption(radio: RadioInputValue) {
    switch (radio) {
      case RadioInputValue.Always:
        return BreakTypePaidThreshold.AlwaysPaidDefault;
      case RadioInputValue.Never:
        return BreakTypePaidThreshold.NeverPaid;
      case RadioInputValue.DefaultToBusiness:
        return null;
      case RadioInputValue.Custom:
        return paidThresholdInput;
      default:
        return undefined;
    }
  }

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: any,
  ) => {
    const isChecked = e.currentTarget.checked;
    if (isChecked) {
      setRadioValue(Number(e.currentTarget.value));
      onChange(getValueForRadioOption(Number(option.value)));
    } else {
      onChange(null);
    }
  };

  const onPaidThresholdInputChange = (newValue: string | number | null) => {
    // Make the field look like an uncontrolled component by allowing everything to be deleted
    if (newValue == null) {
      setPaidThresholdInput(undefined);
      onChange(newValue);
    }

    if (
      newValue != null &&
      newValue >= 0 &&
      newValue < BreakTypePaidThreshold.AlwaysPaidMinimum
    ) {
      setPaidThresholdInput(newValue as number);
      onChange(newValue);
    }
  };

  const options = [
    // Only show Default value if a schedule context
    ...(showDefaultOption
      ? [
          {
            label: getPaidThresholdDescription(businessValue as number),
            value: RadioInputValue.DefaultToBusiness,
            disabled,
          },
        ]
      : []),
    {
      label: t("form.paidThresholdRadioGroup.options.always"),
      value: RadioInputValue.Always,
      disabled,
    },
    {
      label: t("form.paidThresholdRadioGroup.options.never"),
      value: RadioInputValue.Never,
      disabled,
    },
    {
      label: t("form.paidThresholdRadioGroup.options.unpaid"),
      value: RadioInputValue.Custom,
      disabled,
      showDurationInput: true,
    },
  ];

  useEffect(() => {
    // This field requires a bit of finesse to reset nicely, since we also manage state internally inside this component
    // If the form isn't dirty, assume that we want to reset the internal values
    if (!dirty) {
      const newRadioValue = getDefaultRadioButtonValue(
        value,
        showDefaultOption,
      );
      setRadioValue(newRadioValue);
      setPaidThresholdInput(getDefaultInputFieldValue(value, newRadioValue));
    }
  }, [value, setRadioValue, setPaidThresholdInput, dirty, showDefaultOption]);

  return (
    <Col>
      {options.map((option) => (
        <Row key={option.value}>
          <StyledRadio
            className="mr-3 mb-1"
            key={option.label}
            label={option.label}
            type="radio"
            name="paidThreshold"
            disabled={option.disabled}
            value={option.value}
            checked={radioValue === option.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onCheckboxChange(e, option)
            }
          />
          {option.showDurationInput && (
            <DurationInput
              fieldKey="paidThreshold-input"
              postfix={t("form.paidThresholdRadioGroup.mins")}
              size="sm"
              value={paidThresholdInput}
              onChange={onPaidThresholdInputChange}
              disabled={radioValue !== option.value}
            />
          )}
        </Row>
      ))}
    </Col>
  );
}
