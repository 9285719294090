import { graphql } from "react-relay";

export const BasicProfileFragment = graphql`
  fragment AosQueryMutations_BasicProfile_Fragment on Schedule {
    id
    analyticsEnabled
    aosConfigSchema
    ### Replaceable content start
    populateScheduleEnabled
    autoPopulateScheduleEnabled
    autoPopulateScheduleDaysInAdvance
    autoPopulateScheduleAtTime
    labourCostPercentageEnabled
    labourCostPercentage
    aosConfig {
      schedulingDayStart
      addUnderstaffingShifts
      weeklyWorkhoursMin
      weeklyWorkhoursMax
      shiftMin
      shiftMax
      shiftMinAbsolute
      shiftGapMin
      shiftMaxStart
      maxRolesPerShift
      minShiftPartLength
      breakTrigger
      breakLength
      breakMinStart
      breakMaxStart
      breakEndPad
      includeRoles
      demandOnly
      bonusRoles
      ignoreBreakRoles
      flexibleRole
      coreObeysAosRules
      skipSchedulingManagers
      skipSchedulingCore
      addCoreBreaks
      optimizeCoreBreaks
      weeklyDayparts
      daypartRanks
      planningOrder
      overstaffingPenalty
      skillPreference
      shiftLengthPreference
      penaltyShortParts
      penaltyShortPartsCutoff
      costEqualLabor
      weightEqualLaborByRating
      applyRule24HoursRest
      maxWorkdaysCalweek
      maxWorkdaysWorkweek
      overstaffingByRole
      understaffingByRole
      shiftConfig
    }
    ### Replaceable content finish
  }
`;

export const BasicProfileQuery = graphql`
  query AosQueryMutations_BasicProfile_Query(
    $businessId: ID!
    $scheduleId: ID!
  ) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      edges {
        node {
          id
          analyticsEnabled
          aosConfigSchema
          ### Replaceable content start
          populateScheduleEnabled
          autoPopulateScheduleEnabled
          autoPopulateScheduleDaysInAdvance
          autoPopulateScheduleAtTime
          labourCostPercentageEnabled
          labourCostPercentage
          aosConfig {
            schedulingDayStart
            addUnderstaffingShifts
            weeklyWorkhoursMin
            weeklyWorkhoursMax
            shiftMin
            shiftMax
            shiftMinAbsolute
            shiftGapMin
            shiftMaxStart
            maxRolesPerShift
            minShiftPartLength
            breakTrigger
            breakLength
            breakMinStart
            breakMaxStart
            breakEndPad
            includeRoles
            demandOnly
            bonusRoles
            ignoreBreakRoles
            flexibleRole
            coreObeysAosRules
            skipSchedulingManagers
            skipSchedulingCore
            addCoreBreaks
            optimizeCoreBreaks
            weeklyDayparts
            daypartRanks
            planningOrder
            overstaffingPenalty
            skillPreference
            shiftLengthPreference
            penaltyShortParts
            penaltyShortPartsCutoff
            costEqualLabor
            weightEqualLaborByRating
            applyRule24HoursRest
            maxWorkdaysCalweek
            maxWorkdaysWorkweek
            overstaffingByRole
            understaffingByRole
            shiftConfig
          }
          ### Replaceable content finish
        }
      }
    }
  }
`;

export const AdvancedProfileFragment = graphql`
  fragment AosQueryMutations_AdvancedProfile_Fragment on Schedule {
    id
    analyticsEnabled
    populateScheduleEnabled
    aosConfigSchema
    populateScheduleReplanningEnabled
    aosConfig {
      aosTimeout
      aosCombinedRoles
      aosOrchestratorConfig
      replanningTimeout
      replanningOrchestratorConfig
    }
  }
`;

export const AdvancedProfileQuery = graphql`
  query AosQueryMutations_AdvancedProfile_Query(
    $businessId: ID!
    $scheduleId: ID!
  ) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      edges {
        node {
          id
          analyticsEnabled
          populateScheduleEnabled
          aosConfigSchema
          populateScheduleReplanningEnabled
          aosConfig {
            aosTimeout
            aosCombinedRoles
            aosOrchestratorConfig
            replanningTimeout
            replanningOrchestratorConfig
          }
        }
      }
    }
  }
`;
