import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
  Environment,
} from "react-relay";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import without from "lodash/without";
import styled from "styled-components";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { TFunction } from "i18next";
import { Trans, Translation } from "react-i18next";
import { valueToDateTime } from "../../../utils/utility";
import {
  ScheduleContext,
  ScheduleContextValue,
} from "../../Context/ScheduleContext";
import { Id } from "../../../models/common";
import ModalContext, { ModalProps } from "../../Context/ModalContextLegacy";
import OkCancelModal from "../../OkCancelModal";
import UpdatePayPeriodMutation from "../mutations/UpdatePayPeriodMutation";
import { PayPeriodTable_viewer } from "./__generated__/PayPeriodTable_viewer.graphql";
import RelayLazyLoader from "../../common/RelayLazyLoader";

const PayPeriodTablePageQuery = graphql`
  query PayPeriodTable_InternalQuery(
    $pageSize: Int!
    $after: String
    $businessId: ID!
    $scheduleId: ID!
    $payPeriodStatus: PayPeriodApprovalStatus
    $startDateTime: ISO8601DateTime!
    $endDateTime: ISO8601DateTime!
    $sort: [PayPeriodSort!]
  ) {
    ...PayPeriodTable_viewer
  }
`;

const StyledButton = styled(Button)`
  margin-top: -2px;
`;

const StyledStatusTd = styled("td")`
  text-transform: capitalize;
`;

const StyledTh240px = styled("th")`
  width: 240px;
  max-width: 240px;
`;

const StyledTh120px = styled("th")`
  width: 120px;
  max-width: 120px;
`;

const StyledTh160px = styled("th")`
  width: 160px;
  max-width: 160px;
`;

interface MatchParams {
  schedule_id: string;
  business_id: string;
  stack_id: string;
}

export enum PayPeriodApprovalStatus {
  ALL = "all",
  OPEN = "open",
  POSTED = "posted",
  APPROVED = "approved",
  PROCESSED = "processed",
}

export const payPeriodDefaultSort = [
  {
    field: "startTime",
    order: "asc",
  },
];

export interface Employee {
  readonly id: Id;
  readonly firstName: string;
  readonly lastName: string;
}

export interface PayPeriodToReOpen {
  readonly id: Id;
  readonly startTime: unknown;
  readonly endTime: unknown;
}

type Props = RouteComponentProps<MatchParams> & {
  viewer: PayPeriodTable_viewer;
  relay: RelayPaginationProp;
  payPeriodStatus: PayPeriodApprovalStatus | null;
  startDateTime: string;
  endDateTime: string;
  employments: Map<Id, Employee | null | undefined>;
  onNewEmploymentsFound: (employmentIds: Id[]) => void;
};

type State = {
  payPeriodToReopen?: PayPeriodToReOpen;
};

const dateTimeFormat = "dd MMM yyyy HH:mm:ss ZZZZ";
const dateFormat = "dd MMM yyyy";

class PayPeriodTableBase extends Component<Props, State> {
  static contextType = ScheduleContext;

  constructor(props: any) {
    super(props);
    this.state = {
      payPeriodToReopen: undefined,
    };
  }

  private onSaved(t: TFunction) {
    this.setState({
      payPeriodToReopen: undefined,
    });
    toast(t("pay-periods:reopenConfirmation.success"));
  }

  showPayPeriodReOpenConfirmationDialog = (
    modalProps: ModalProps,
    payPeriod: PayPeriodToReOpen,
  ) => {
    this.setState({
      payPeriodToReopen: payPeriod,
    });
    modalProps.showModal();
  };

  private reopenPayPeriod(
    t: TFunction,
    environment: Environment,
    id: Id,
    businessId: Id,
  ) {
    UpdatePayPeriodMutation(
      environment,
      id,
      businessId,
      PayPeriodApprovalStatus.OPEN,
      this.onSaved.bind(this, t),
      (e) => {
        alert(e.message);
      },
    );
  }

  render() {
    const { viewer, relay, employments, onNewEmploymentsFound, match } =
      this.props;
    const { schedule, businessContext } = this.context as ScheduleContextValue;
    const { environment } = businessContext;
    const { payPeriodToReopen } = this.state;

    const { params } = match;
    const { business_id: businessId } = params;

    if (
      !environment ||
      !viewer ||
      !viewer.payPeriods ||
      !viewer.payPeriods.edges
    ) {
      return null;
    }

    const employmentsFound = viewer.payPeriods.edges.reduce(
      (result: Set<Id>, e) => {
        if (e && e.node) {
          const { postedById, approvedById } = e.node;
          if (postedById) {
            result.add(postedById);
          }

          if (approvedById) {
            result.add(approvedById);
          }
        }

        return result;
      },
      new Set<Id>(),
    );

    const loaded = Array.from(employments.keys());
    const employeesToFetch = without(
      Array.from(employmentsFound.values()),
      ...loaded,
    );
    if (employeesToFetch.length) {
      onNewEmploymentsFound(employeesToFetch as string[]);
      return null;
    }

    const getEmploymentName = (id: Id | null) => {
      if (!id) {
        return null;
      }
      const employment = employments.get(id);
      if (!employment) {
        return null;
      }

      return (
        <span>{` by ${employment.firstName} ${employment.lastName}`}</span>
      );
    };

    const getDateTimeFromString = (raw: string | null | undefined) => {
      if (!raw) {
        return null;
      }

      return valueToDateTime(
        raw as string,
        undefined,
        schedule?.timeZone || undefined,
      );
    };

    const formatDate = (format: string, dateTime?: DateTime | null) => {
      if (!dateTime) {
        return "";
      }

      return dateTime.toFormat(format);
    };

    const getEndDateToDisplay = (
      endTime: string | null | undefined,
    ): string => {
      if (!endTime) {
        return "";
      }

      const endDateTime = getDateTimeFromString(endTime as string);
      if (!endDateTime) {
        return "";
      }

      return formatDate(
        dateFormat,
        // Backend DateTimes are [inclusive, exclusive]
        endDateTime.minus({ day: 1 }),
      );
    };

    const range = payPeriodToReopen
      ? ` ${formatDate(
          dateFormat,
          getDateTimeFromString(payPeriodToReopen.startTime as string),
        )} - ${getEndDateToDisplay(payPeriodToReopen.endTime as string)} `
      : "";

    return (
      <Translation ns={["pay-periods", "translation"]}>
        {(t) => (
          <ModalContext.Consumer>
            {(modalProps) => (
              <>
                <Table hover size="sm">
                  <thead>
                    <tr>
                      <StyledTh120px>
                        {t("pay-periods:property.startDate")}
                      </StyledTh120px>
                      <StyledTh120px>
                        {t("pay-periods:property.endDate")}
                      </StyledTh120px>
                      <StyledTh160px>
                        {t("pay-periods:property.status")}
                      </StyledTh160px>
                      <StyledTh240px>
                        {t("pay-periods:property.posted")}
                      </StyledTh240px>
                      <StyledTh240px>
                        {t("pay-periods:property.approved")}
                      </StyledTh240px>
                      <StyledTh240px>
                        {t("pay-periods:property.processed")}
                      </StyledTh240px>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(viewer.payPeriods.edges || []).map((edge) => {
                      if (!edge) {
                        return null;
                      }

                      const { node } = edge;
                      if (!node) {
                        return null;
                      }

                      return (
                        <tr key={node.id} className="hoverable">
                          <td>
                            {formatDate(
                              dateFormat,
                              getDateTimeFromString(node.startTime as string),
                            )}
                          </td>
                          <td>{getEndDateToDisplay(node.endTime as string)}</td>
                          <StyledStatusTd>
                            {node.payPeriodStatus}
                            {node.payPeriodStatus !==
                            PayPeriodApprovalStatus.OPEN ? (
                              <StyledButton
                                variant="link"
                                className="ml-1 mr-2 show-inline-on-hover"
                                onClick={() =>
                                  this.showPayPeriodReOpenConfirmationDialog(
                                    modalProps,
                                    node,
                                  )
                                }
                              >
                                {t("pay-periods:actions.reopen")}
                              </StyledButton>
                            ) : null}
                          </StyledStatusTd>
                          <td>
                            {formatDate(
                              dateTimeFormat,
                              getDateTimeFromString(node.postedAt as string),
                            )}
                            {getEmploymentName(node.postedById)}
                          </td>
                          <td>
                            {formatDate(
                              dateTimeFormat,
                              getDateTimeFromString(node.approvedAt as string),
                            )}
                            {getEmploymentName(node.approvedById)}
                          </td>
                          <td>
                            {formatDate(
                              dateTimeFormat,
                              getDateTimeFromString(node.processedAt as string),
                            )}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <RelayLazyLoader relay={relay} />
                {payPeriodToReopen ? (
                  <OkCancelModal
                    title={t("pay-periods:reopenConfirmation.title")}
                    onOk={() =>
                      this.reopenPayPeriod(
                        t,
                        environment,
                        payPeriodToReopen.id,
                        businessId,
                      )
                    }
                  >
                    <p>
                      <Trans i18nKey="pay-periods:reopenConfirmation.body">
                        Are you sure you want to reopen pay period
                        <strong>{{ range }}</strong>?
                      </Trans>
                    </p>
                  </OkCancelModal>
                ) : null}
              </>
            )}
          </ModalContext.Consumer>
        )}
      </Translation>
    );
  }
}

export default createPaginationContainer(
  PayPeriodTableBase,
  {
    viewer: graphql`
      fragment PayPeriodTable_viewer on InternalQuery {
        payPeriods(
          first: $pageSize
          after: $after
          businessId: $businessId
          scheduleId: $scheduleId
          payPeriodStatus: $payPeriodStatus
          startDateTime: $startDateTime
          endDateTime: $endDateTime
          sort: $sort
        ) @connection(key: "PayPeriodTable_payPeriods") {
          edges {
            node {
              id
              startTime
              endTime
              payPeriodStatus
              postedAt
              postedById
              approvedAt
              approvedById
              processedAt
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: PayPeriodTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.payPeriods,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
      sort: payPeriodDefaultSort,
    }),
    getVariables: (props, paginationInfo) => ({
      businessId: props.match.params.business_id,
      scheduleId: props.match.params.schedule_id,
      payPeriodStatus: props.payPeriodStatus,
      startDateTime: props.startDateTime,
      endDateTime: props.endDateTime,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
      sort: payPeriodDefaultSort,
    }),
  },
);
