/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type EmploymentFeedbackReasonCreateInput = {
    defaultMessage?: string | null | undefined;
    description?: string | null | undefined;
    feedbackType: EmploymentFeedbackTypeEnum;
};
export type FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutationVariables = {
    businessId: string;
    input: EmploymentFeedbackReasonCreateInput;
};
export type FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutationResponse = {
    readonly createEmploymentFeedbackReason: {
        readonly id: string;
        readonly feedbackType: EmploymentFeedbackTypeEnum;
        readonly description: string;
        readonly defaultMessage: string | null;
        readonly deleted: boolean;
    };
};
export type FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutation = {
    readonly response: FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutationResponse;
    readonly variables: FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutationVariables;
};



/*
mutation FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutation(
  $businessId: ID!
  $input: EmploymentFeedbackReasonCreateInput!
) {
  createEmploymentFeedbackReason(businessId: $businessId, input: $input) {
    id
    feedbackType
    description
    defaultMessage
    deleted
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentFeedbackReason",
    "kind": "LinkedField",
    "name": "createEmploymentFeedbackReason",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultMessage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "26fa4c79a4515a749344f8fa1fc0318e",
    "id": null,
    "metadata": {},
    "name": "FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutation",
    "operationKind": "mutation",
    "text": "mutation FeedbackReasonsQueriesMutations_CreateEmploymentFeedbackReasonMutation(\n  $businessId: ID!\n  $input: EmploymentFeedbackReasonCreateInput!\n) {\n  createEmploymentFeedbackReason(businessId: $businessId, input: $input) {\n    id\n    feedbackType\n    description\n    defaultMessage\n    deleted\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bcc8c2463cd5ed767c9ae14aee773188';
export default node;
