/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CorporatesQueryMutations_GetCorporatesByName_InternalQueryVariables = {
    nameIncludes?: string | null | undefined;
    excludeDeleted?: boolean | null | undefined;
};
export type CorporatesQueryMutations_GetCorporatesByName_InternalQueryResponse = {
    readonly corporates: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        } | null> | null;
    };
};
export type CorporatesQueryMutations_GetCorporatesByName_InternalQuery = {
    readonly response: CorporatesQueryMutations_GetCorporatesByName_InternalQueryResponse;
    readonly variables: CorporatesQueryMutations_GetCorporatesByName_InternalQueryVariables;
};



/*
query CorporatesQueryMutations_GetCorporatesByName_InternalQuery(
  $nameIncludes: String
  $excludeDeleted: Boolean
) {
  corporates(nameIncludes: $nameIncludes, excludeDeleted: $excludeDeleted) {
    nodes {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeDeleted"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameIncludes"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "excludeDeleted",
        "variableName": "excludeDeleted"
      },
      {
        "kind": "Variable",
        "name": "nameIncludes",
        "variableName": "nameIncludes"
      }
    ],
    "concreteType": "CorporateConnection",
    "kind": "LinkedField",
    "name": "corporates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Corporate",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueryMutations_GetCorporatesByName_InternalQuery",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CorporatesQueryMutations_GetCorporatesByName_InternalQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2f0db00815a716b9a274797602cc8b01",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueryMutations_GetCorporatesByName_InternalQuery",
    "operationKind": "query",
    "text": "query CorporatesQueryMutations_GetCorporatesByName_InternalQuery(\n  $nameIncludes: String\n  $excludeDeleted: Boolean\n) {\n  corporates(nameIncludes: $nameIncludes, excludeDeleted: $excludeDeleted) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0d4ca26617b202b9eab0723a7943f4e0';
export default node;
