import { DayOfWeek } from "../../../../models/common";
import { AosQueryMutations_AdvancedProfile_Fragment } from "./__generated__/AosQueryMutations_AdvancedProfile_Fragment.graphql";
import { AosQueryMutations_BasicProfile_Fragment } from "./__generated__/AosQueryMutations_BasicProfile_Fragment.graphql";

export type DaypartTime = string[];

export type Daypart = {
  name: string;
  time: DaypartTime;
};

export type DaypartRank = {
  preference: number;
  dayOfWeek: DayOfWeek;
};

export type WeeklyDayparts = {
  daypart: Daypart;
  ranks: DaypartRank[];
};

export type TimeRangeRanksConfigs = {
  weeklyDaypartsData: WeeklyDayparts[];
  useGlobalPreference: boolean;
  globalPreference?: number | null;
};

export enum PlanningOrderType {
  Backward = "backward",
  Forward = "forward",
  Custom = "custom",
}

export type AosConfigBasic =
  {} & AosQueryMutations_BasicProfile_Fragment["aosConfig"];
export type AosConfigAdvanced =
  {} & AosQueryMutations_AdvancedProfile_Fragment["aosConfig"];
export type TransformedAosConfigBasic = AosConfigBasic & TimeRangeRanksConfigs;
