import React, { Component } from "react";
import { FormikContext } from "formik";
import Row from "react-bootstrap/Row";
import { DateTime } from "luxon";
import range from "lodash/range";
import { Translation } from "react-i18next";
import Field from "../../../Form/Field";
import { daysOfWeek, BaseOption } from "../../../../models/common";
import { AosConfigBasic, PlanningOrderType } from "./models";
import SelectionGroup from "../../../Form/SelectionGroup";
import OrderedList from "../../../Form/OrderedList";
import { ScheduleContext } from "../../../Context/ScheduleContext";
import FormGroup from "../../../Form/FormGroup";

type Props = {
  values: AosConfigBasic;
  disabled?: boolean;
};

type State = {
  planningOrderType: PlanningOrderType;
};

export type EmployeeGroup = {
  empl_rating: number;
  name: string;
  weekly_workhours_min: number;
  weekly_workhours_max: number;
  shift_min: number;
  shift_max: number;
};

export default class PlanningOrder extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      planningOrderType: PlanningOrderType.Backward,
    };
  }

  static getDerivedStateFromProps(props: Readonly<Props>) {
    const { values } = props;

    if (!values) {
      return null;
    }

    const { planningOrder } = values;
    if (
      planningOrder &&
      planningOrder.find(
        (i) =>
          i.toLocaleLowerCase() ===
          PlanningOrderType.Backward.toLocaleLowerCase(),
      )
    ) {
      return { planningOrderType: PlanningOrderType.Backward };
    }

    if (
      planningOrder &&
      planningOrder.find(
        (i) =>
          i.toLocaleLowerCase() ===
          PlanningOrderType.Forward.toLocaleLowerCase(),
      )
    ) {
      return { planningOrderType: PlanningOrderType.Forward };
    }

    return { planningOrderType: PlanningOrderType.Custom };
  }

  render() {
    const { disabled, values: aosConfig } = this.props;
    const { planningOrderType } = this.state;

    return (
      <Translation>
        {(t) => (
          <ScheduleContext.Consumer>
            {(scheduleContext) => {
              // custom
              const startOfWeek = scheduleContext.getWeekStart(
                DateTime.local(),
              );
              const endOfWeek = startOfWeek.plus({ day: 6 });

              const startOfWeekDay = startOfWeek.toFormat("ccc");
              const endOfWeekDay = endOfWeek.toFormat("ccc");

              const planningOrderOptions: BaseOption<PlanningOrderType, any>[] =
                [
                  {
                    value: PlanningOrderType.Backward,
                    label: `${t(
                      "aos:basic.sections.planning_order.backward",
                    )} (${endOfWeekDay}, ${endOfWeek
                      .minus({ day: 1 })
                      .toFormat("ccc")}, ... ${startOfWeekDay})`,
                  },
                  {
                    value: PlanningOrderType.Forward,
                    label: `${t(
                      "aos:basic.sections.planning_order.forward",
                    )} (${startOfWeekDay}, ${startOfWeek
                      .plus({ day: 1 })
                      .toFormat("ccc")}, ... ${endOfWeekDay})`,
                  },
                  {
                    value: PlanningOrderType.Custom,
                    label: t("aos:basic.sections.planning_order.custom"),
                  },
                ];

              const defaultCustomPlanningOrder = range(7).map((day) => {
                return startOfWeek.plus({ day }).toFormat("cccc");
              });

              return (
                <FormikContext.Consumer>
                  {(formikContext) => {
                    return (
                      <fieldset>
                        <Row>
                          <FormGroup
                            md={4}
                            lg={3}
                            fieldKey="planningOrderType"
                            label={t("aos:basic.sections.planning_order.order")}
                          >
                            <SelectionGroup<PlanningOrderType>
                              fieldKey="planningOrderType"
                              options={planningOrderOptions}
                              formCheckType="radio"
                              value={planningOrderType}
                              disabled={disabled}
                              onChange={(newValue) => {
                                this.setState({
                                  planningOrderType:
                                    newValue as PlanningOrderType,
                                });

                                const helper = formikContext.getFieldHelpers(
                                  "aosConfig.planningOrder",
                                );
                                switch (newValue) {
                                  case PlanningOrderType.Backward:
                                    helper.setValue([
                                      PlanningOrderType.Backward,
                                    ]);
                                    return;
                                  case PlanningOrderType.Forward:
                                    helper.setValue([
                                      PlanningOrderType.Forward,
                                    ]);
                                    return;
                                  default: {
                                    helper.setValue(defaultCustomPlanningOrder);
                                  }
                                }
                              }}
                            />
                          </FormGroup>

                          {/* <Field
                            label={t("aos:basic.sections.planning_order.order")}
                            md={4}
                            lg={3}
                            disabled={disabled}
                            // fieldKey="planningOrderType"
                            value={values.planningOrderType}
                            component={SelectionGroup}
                            componentProps={{
                              options: planningOrderOptions,
                              formCheckType: "radio",
                            }}
                            onValueChanged={(newValue) => {}}
                          /> */}
                          {planningOrderType === PlanningOrderType.Custom ? (
                            <Field
                              label={t(
                                "aos:basic.sections.planning_order.custom_order",
                              )}
                              md={4}
                              lg={3}
                              disabled={disabled}
                              fieldKey="aosConfig.planningOrder"
                              value={
                                (aosConfig.planningOrder as string[]) || []
                              }
                              component={OrderedList}
                              componentProps={{
                                options: daysOfWeek,
                              }}
                            />
                          ) : null}
                        </Row>
                      </fieldset>
                    );
                  }}
                </FormikContext.Consumer>
              );
            }}
          </ScheduleContext.Consumer>
        )}
      </Translation>
    );
  }
}
