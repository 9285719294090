/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import UserCorporatesPaginationQuery from "./UserCorporatesPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type CorporatesQueryMutations_ListFragment = {
    readonly userCorporates: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly user: {
                    readonly id: string;
                    readonly email: string;
                };
                readonly personKey: string;
                readonly lastAccessedAt: string | null;
                readonly business: {
                    readonly businessName: string;
                };
                readonly corporate: {
                    readonly id: string;
                };
            } | null;
        } | null> | null;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
    };
    readonly " $refType": "CorporatesQueryMutations_ListFragment";
};
export type CorporatesQueryMutations_ListFragment$data = CorporatesQueryMutations_ListFragment;
export type CorporatesQueryMutations_ListFragment$key = {
    readonly " $data"?: CorporatesQueryMutations_ListFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CorporatesQueryMutations_ListFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "userCorporates"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "corporateIds"
    },
    {
      "kind": "RootArgument",
      "name": "emailIncludes"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": UserCorporatesPaginationQuery
    }
  },
  "name": "CorporatesQueryMutations_ListFragment",
  "selections": [
    {
      "alias": "userCorporates",
      "args": [
        {
          "kind": "Variable",
          "name": "corporateIds",
          "variableName": "corporateIds"
        },
        {
          "kind": "Variable",
          "name": "emailIncludes",
          "variableName": "emailIncludes"
        }
      ],
      "concreteType": "UserCorporateConnection",
      "kind": "LinkedField",
      "name": "__CorporatesTable_userCorporates_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserCorporateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserCorporate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "personKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastAccessedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Business",
                  "kind": "LinkedField",
                  "name": "business",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "businessName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Corporate",
                  "kind": "LinkedField",
                  "name": "corporate",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};
})();
(node as any).hash = 'e11ee61449a8b1b70daacb641536dcee';
export default node;
