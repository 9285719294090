import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  FetchPolicy,
} from "react-relay";
import {
  BusinessesQueriesMutations_GetCorporatesBusinessesQuery,
  BusinessesQueriesMutations_GetCorporatesBusinessesQueryResponse,
} from "./__generated__/BusinessesQueriesMutations_GetCorporatesBusinessesQuery.graphql";

import {
  BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery,
  BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQueryResponse,
} from "./__generated__/BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery.graphql";

import {
  BusinessesQueriesMutations_LinkBusinessToCorporate_MutationResponse,
  BusinessesQueriesMutations_LinkBusinessToCorporate_MutationVariables,
} from "./__generated__/BusinessesQueriesMutations_LinkBusinessToCorporate_Mutation.graphql";

export const GetCorporatesBusinessesByIds = graphql`
  query BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery(
    $ids: [ID!]
    $skip: Boolean!
  ) {
    businesses(ids: $ids) @skip(if: $skip) {
      nodes {
        businessName
        id
        corporate {
          id
          name
        }
      }
    }
  }
`;

export const GetCorporatesBusinesses = graphql`
  query BusinessesQueriesMutations_GetCorporatesBusinessesQuery {
    businesses {
      nodes {
        businessName
        id
        corporate {
          id
          name
        }
      }
    }
  }
`;

export const LinkBusinessToCorporate = graphql`
  mutation BusinessesQueriesMutations_LinkBusinessToCorporate_Mutation(
    $id: ID!
    $corporateId: ID
  ) {
    linkBusinessToCorporate(id: $id, corporateId: $corporateId) {
      id
    }
  }
`;

type GetCorporatesBusinessesQuery_ResponseType =
  {} & BusinessesQueriesMutations_GetCorporatesBusinessesQueryResponse["businesses"];
type GetCorporatesBusinessesQuery_NodeType =
  {} & GetCorporatesBusinessesQuery_ResponseType["nodes"];
export type GetCorporatesBusinessesQuery_Business =
  {} & GetCorporatesBusinessesQuery_NodeType[number];

export function useIdmBusinessCorporateLazyLoad(networkPolicy = false) {
  const queryData =
    useLazyLoadQuery<BusinessesQueriesMutations_GetCorporatesBusinessesQuery>(
      GetCorporatesBusinesses,
      {},
      {
        ...(networkPolicy && { fetchPolicy: "network-only" }),
      },
    );

  const items = queryData?.businesses?.nodes;
  return [items] as const;
}

type GetCorporatesBusinessesByIdsQuery_ResponseType =
  {} & BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQueryResponse["businesses"];
type GetCorporatesBusinessesByIdsQueryResponse_NodeType =
  {} & GetCorporatesBusinessesByIdsQuery_ResponseType["nodes"];
export type GetCorporatesBusinessesByIdsQuery_Business =
  {} & GetCorporatesBusinessesByIdsQueryResponse_NodeType[number];

export type QueryOptions = {
  fetchKey?: string | number;
  fetchPolicy?: FetchPolicy;
};

export function useIdmBusinessById(id?: string, queryOptions?: QueryOptions) {
  const queryData =
    useLazyLoadQuery<BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery>(
      GetCorporatesBusinessesByIds,
      { ids: id ? [id] : [], skip: id == null },
      queryOptions ?? { fetchPolicy: "store-and-network" },
    );

  return queryData.businesses?.nodes?.[0] ?? null;
}

export function useIdmBusinessData(
  businessId?: string,
  queryOptions?: QueryOptions,
) {
  const [linkBusinessToCorporateMutation] = useMutation<{
    response: BusinessesQueriesMutations_LinkBusinessToCorporate_MutationResponse;
    variables: BusinessesQueriesMutations_LinkBusinessToCorporate_MutationVariables;
  }>(LinkBusinessToCorporate);
  const business = useIdmBusinessById(businessId, queryOptions);

  return [
    business,
    {
      linkBusinessToCorporateMutation,
    },
  ] as const;
}
