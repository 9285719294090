import { graphql } from "react-relay";

export const GetAllBusinessMetadataTypesQuery = graphql`
  query MetadataTypesQueriesMutations_MetaDataTypesQuery(
    $businessId: ID!
    $search: String
  ) {
    metadataTypes(businessId: $businessId, search: $search) {
      nodes {
        id
        displayName
        name
        dataType
        encrypted
        external
        internalAccess
        isTimeboxed
        maximum
        minimum
        validValues
        obfuscatedNumber
        objectClass
        required
        description
        updatedAt
        createdAt
      }
    }
  }
`;

export const GetSingleBusinessMetadataTypeQuery = graphql`
  query MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery(
    $businessId: ID!
    $id: ID!
    $skip: Boolean!
  ) {
    metadataTypes(businessId: $businessId, ids: [$id]) @skip(if: $skip) {
      nodes {
        id
        ### Replaceable content start
        name
        displayName
        objectClass
        required
        description
        dataType
        minimum
        maximum
        validValues
        isTimeboxed
        external
        internalAccess
        encrypted
        obfuscatedNumber
        ### Replaceable content finish
      }
    }
  }
`;

export const CreateBusinessMetadataTypeMutation = graphql`
  mutation MetadataTypesQueriesMutations_CreateBusinessMetadataType_Mutation(
    $businessId: ID!
    $input: MetadataTypeCreateInput!
  ) {
    createMetadataType(businessId: $businessId, input: $input) {
      id
      ### Replaceable content start
      name
      displayName
      objectClass
      required
      description
      dataType
      minimum
      maximum
      validValues
      isTimeboxed
      external
      internalAccess
      encrypted
      obfuscatedNumber
      ### Replaceable content finish
    }
  }
`;

export const UpdateBusinessMetadataTypeMutation = graphql`
  mutation MetadataTypesQueriesMutations_UpdateBusinessMetadataType_Mutation(
    $businessId: ID!
    $id: ID!
    $input: MetadataTypeUpdateInput!
  ) {
    updateMetadataType(businessId: $businessId, id: $id, input: $input) {
      id
      ### Replaceable content start
      name
      displayName
      objectClass
      required
      description
      dataType
      minimum
      maximum
      validValues
      isTimeboxed
      external
      internalAccess
      encrypted
      obfuscatedNumber
      ### Replaceable content finish
    }
  }
`;

export const DeleteBusinessMetadataTypeMutation = graphql`
  mutation MetadataTypesQueriesMutations_DeleteBusinessMetadataType_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteMetadataType(businessId: $businessId, id: $id)
  }
`;
