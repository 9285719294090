/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQueryVariables = {
    ids?: Array<string> | null | undefined;
    skip: boolean;
};
export type BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQueryResponse = {
    readonly businesses?: {
        readonly nodes: ReadonlyArray<{
            readonly businessName: string;
            readonly id: string;
            readonly corporate: {
                readonly id: string;
                readonly name: string;
            } | null;
        } | null> | null;
    } | undefined;
};
export type BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery = {
    readonly response: BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQueryResponse;
    readonly variables: BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQueryVariables;
};



/*
query BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery(
  $ids: [ID!]
  $skip: Boolean!
) {
  businesses(ids: $ids) @skip(if: $skip) {
    nodes {
      businessName
      id
      corporate {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "BusinessConnection",
        "kind": "LinkedField",
        "name": "businesses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Business",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessName",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Corporate",
                "kind": "LinkedField",
                "name": "corporate",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "eef9f99be334bc2e8c5fa72dde00d80c",
    "id": null,
    "metadata": {},
    "name": "BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery",
    "operationKind": "query",
    "text": "query BusinessesQueriesMutations_GetCorporatesBusinessesByIdsQuery(\n  $ids: [ID!]\n  $skip: Boolean!\n) {\n  businesses(ids: $ids) @skip(if: $skip) {\n    nodes {\n      businessName\n      id\n      corporate {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9d2bf9346e79f38fb90696613818c2a3';
export default node;
