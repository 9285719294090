import React, { Component } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Translation } from "react-i18next";
import Table from "./AccountTable";
import { idmInternalEnvironment } from "../../../../environment";
import HeaderPortal from "../../../Portal/HeaderPortal";
import Searchable from "../../../Searchable/Searchable";
import Loader from "../../../common/Loader";
import { Accounts_InternalQuery } from "./__generated__/Accounts_InternalQuery.graphql";
import { BaseOption, Id, IStack } from "../../../../models/common";
import StackService from "../Stack/StackService";
import DynamicSelect from "../../../Form/DynamicSelect";

const AccountsQuery = graphql`
  query Accounts_InternalQuery(
    $email: String!
    $isLocked: Boolean
    $isConfirmed: Boolean
    $stackId: ID
    $pageSize: Int!
    $after: String
  ) {
    ...AccountTable_viewer
  }
`;

interface MatchParams {}

type Props = RouteComponentProps<MatchParams> & {};

type State = {
  searchValue: string;
  isLocked: boolean;
  isUnconfirmed: boolean;
  stackId: Id;
  stacksOptions: BaseOption<Id, any>[];
  stacksById?: Map<string, IStack>;
};

const defaultFilter = {
  searchValue: "",
  isLocked: false,
  isUnconfirmed: false,
  stackId: "",
};

const defaultStackOption = { label: "All", value: "" };

export default class Accounts extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      stacksOptions: [],
      ...defaultFilter,
    };
  }

  async componentDidMount() {
    const stacksById = await StackService.getAllStacks();
    const stacks = Array.from(stacksById?.values() || []).map((s) => {
      return { label: s.stackCode, value: s.id };
    });

    const stacksOptions: BaseOption<Id, any>[] = [
      defaultStackOption,
      ...stacks,
    ];

    this.setState({
      stacksOptions,
      stacksById,
    });
  }

  private onSearchChange(value: string) {
    this.setState({
      searchValue: value,
    });
  }

  render() {
    const {
      searchValue,
      isLocked,
      isUnconfirmed,
      stackId,
      stacksById,
      stacksOptions,
    } = this.state;

    if (!stacksById) {
      return null;
    }

    return (
      <Translation ns="accounts">
        {(t) => (
          <>
            <Row className="my-2">
              <Col>
                <Searchable
                  className="Accounts"
                  searchValue={searchValue}
                  onSearchChange={this.onSearchChange.bind(this)}
                  placeholder={t("table.search")}
                />
              </Col>
              <Col md="auto">
                <Form inline>
                  <Form.Group>
                    <Form.Check
                      className="mr-4"
                      type="checkbox"
                      id="isLocked"
                      label={t("table.locked")}
                      name="isLocked"
                      checked={isLocked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { currentTarget } = e;
                        const { checked } = currentTarget;
                        this.setState({
                          isLocked: checked,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="border-right">
                    <Form.Check
                      className="mr-4 ml-2"
                      type="checkbox"
                      id="isUnconfirmed"
                      label={t("table.unconfirmed")}
                      name="isUnconfirmed"
                      checked={isUnconfirmed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { currentTarget } = e;
                        const { checked } = currentTarget;
                        this.setState({
                          isUnconfirmed: checked,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="ml-4 mr-2">
                      {t("table.stack")}
                    </Form.Label>
                    <DynamicSelect<Id | null>
                      options={stacksOptions}
                      defaultValue={defaultStackOption}
                      value={stackId}
                      name="employed-status"
                      onChange={(newValue: Id | null | undefined) => {
                        this.setState({
                          stackId: newValue || "",
                        });
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    this.setState(defaultFilter);
                  }}
                >
                  {t("table.clearFilters")}
                </Button>
              </Col>
              <Col md="auto">
                <LinkContainer to="/account/create">
                  <Button disabled>{t("table.new")}</Button>
                </LinkContainer>
              </Col>
            </Row>

            <Card body>
              <QueryRenderer<Accounts_InternalQuery>
                environment={idmInternalEnvironment}
                query={AccountsQuery}
                variables={{
                  email: searchValue,
                  isLocked: isLocked || undefined,
                  isConfirmed: isUnconfirmed ? false : undefined,
                  stackId: stackId || undefined,
                  pageSize: 50,
                }}
                render={({ error, props }) => {
                  if (error) {
                    return <div>Error!</div>;
                  }
                  if (!props) {
                    return <Loader />;
                  }
                  return (
                    <Table
                      {...this.props}
                      searchValue={searchValue}
                      isLocked={isLocked}
                      isUnconfirmed={isUnconfirmed}
                      stackId={stackId}
                      viewer={props}
                      stacksById={stacksById}
                    />
                  );
                }}
              />
            </Card>
            <HeaderPortal>{t("layout.title")}</HeaderPortal>
          </>
        )}
      </Translation>
    );
  }
}
