import React, { Component } from "react";
import { Environment, graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Translation } from "react-i18next";
import { TFunction } from "i18next";
import Table, { SearchByEnum } from "./StackPeopleTable";
import { getRegionalStackEnvironment } from "../../../environment";
import HeaderPortal from "../../Portal/HeaderPortal";
import Searchable from "../../Searchable/Searchable";
import Loader from "../../common/Loader";
import DynamicSelect from "../../Form/DynamicSelect";
import {
  EmploymentStatus,
  getCurrentlyEmployedParam,
  getDeletedParam,
} from "../Employment/EmploymentTable";
import StackService from "../../IDM/internal/Stack/StackService";
import { Id, IStack } from "../../../models/common";
import { StackPeople_InternalQuery } from "./__generated__/StackPeople_InternalQuery.graphql";

const StackPeopleQuery = graphql`
  query StackPeople_InternalQuery(
    $email: String
    $userId: ID
    $deleted: Boolean
    $acceptedInvite: Boolean
    $currentlyEmployed: Boolean
    $unlinkedUser: Boolean
    $pageSize: Int!
    $after: String
  ) {
    ...StackPeopleTable_viewer
  }
`;

interface MatchParams {
  stack_id: Id;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {
  email: string;
  userId: string;
  employmentStatus: EmploymentStatus;
  environment?: Environment;
  stack?: IStack;
  searchBy: SearchByEnum;
  unlinked: boolean;
};

const defaultFilter = {
  email: "",
  userId: "",
  employmentStatus: EmploymentStatus.Any,
  searchBy: SearchByEnum.Email,
  unlinked: false,
};

export default class StackPeople extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...defaultFilter,
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { stack_id: stackId },
      },
    } = this.props;

    const stack = await StackService.getStackById(stackId);
    if (!stack) {
      return;
    }
    const environment = getRegionalStackEnvironment(stack.domainName);

    this.setState({
      stack,
      environment,
    });
  }

  render() {
    const {
      email,
      userId,
      employmentStatus,
      environment,
      stack,
      searchBy,
      unlinked,
    } = this.state;
    const {
      match: {
        params: { stack_id: stackId },
      },
    } = this.props;

    if (!environment) {
      return null;
    }

    const getSearchInput = (t: TFunction) => {
      switch (searchBy) {
        case SearchByEnum.Email:
          return (
            <Searchable
              searchValue={email}
              className="email"
              onSearchChange={(value: string) => {
                this.setState({
                  email: value,
                });
              }}
              placeholder={t("table.searchByEmail")}
            />
          );
        case SearchByEnum.UserId:
        default:
          return (
            <Searchable
              searchValue={userId}
              className="user-id"
              onSearchChange={(value: string) => {
                this.setState({
                  userId: value,
                });
              }}
              placeholder={t("table.searchByUserId")}
            />
          );
      }
    };

    return (
      <Translation ns="stacks">
        {(t) => (
          <>
            <Row className="my-2">
              <Col>
                <Form inline>
                  <Form.Group>
                    <Form.Label className="mr-2 ml-0">
                      {t("table.searchBy.label")}
                    </Form.Label>
                    <DynamicSelect<SearchByEnum>
                      className="mr-2"
                      options={[
                        {
                          label: t("table.searchBy.email"),
                          value: SearchByEnum.Email,
                        },
                        {
                          label: t("table.searchBy.userId"),
                          value: SearchByEnum.UserId,
                        },
                      ]}
                      value={searchBy}
                      name="employment-status"
                      onChange={(newValue: SearchByEnum | null | undefined) => {
                        this.setState({
                          searchBy: newValue ?? SearchByEnum.Email,
                        });
                      }}
                    />

                    {getSearchInput(t)}
                  </Form.Group>
                  <Form.Group className="border-right">
                    <Form.Check
                      className="mr-4 ml-4"
                      type="checkbox"
                      id="unlinked"
                      label={t("table.unlinked")}
                      name="unlinked"
                      checked={unlinked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { currentTarget } = e;
                        const { checked } = currentTarget;
                        this.setState({
                          unlinked: checked,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="ml-4 mr-2">
                      {t("table.status")}
                    </Form.Label>
                    <DynamicSelect<EmploymentStatus>
                      options={[
                        {
                          label: t("table.statuses.any"),
                          value: EmploymentStatus.Any,
                        },
                        {
                          label: t("table.statuses.employed"),
                          value: EmploymentStatus.Employed,
                        },
                        {
                          label: t("table.statuses.invited"),
                          value: EmploymentStatus.Invited,
                        },
                        {
                          label: t("table.statuses.terminated"),
                          value: EmploymentStatus.Terminated,
                        },
                      ]}
                      value={employmentStatus}
                      name="employment-status"
                      onChange={(
                        newValue: EmploymentStatus | null | undefined,
                      ) => {
                        this.setState({
                          employmentStatus:
                            newValue ?? EmploymentStatus.Employed,
                        });
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    this.setState(defaultFilter);
                  }}
                >
                  {t("table.clearFilters")}
                </Button>
              </Col>
            </Row>

            <Card body>
              <QueryRenderer<StackPeople_InternalQuery>
                environment={environment}
                query={StackPeopleQuery}
                variables={{
                  email: searchBy === SearchByEnum.Email ? email : undefined,
                  userId: searchBy === SearchByEnum.UserId ? userId : undefined,
                  acceptedInvite:
                    employmentStatus === EmploymentStatus.Invited
                      ? false
                      : undefined,
                  deleted: getDeletedParam(employmentStatus),
                  currentlyEmployed:
                    getCurrentlyEmployedParam(employmentStatus),
                  unlinkedUser: unlinked ? true : undefined,
                  pageSize: 50,
                }}
                render={({ error, props }) => {
                  if (error) {
                    return <div>Error!</div>;
                  }
                  if (!props) {
                    return <Loader />;
                  }
                  return (
                    <Table
                      {...this.props}
                      email={email}
                      userId={userId}
                      employmentStatus={employmentStatus}
                      stackId={stackId}
                      searchBy={searchBy}
                      unlinked={unlinked}
                      viewer={props}
                    />
                  );
                }}
              />
            </Card>

            <HeaderPortal as="span">{stack?.domainName}</HeaderPortal>
          </>
        )}
      </Translation>
    );
  }
}
