/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BusinessService_GetGlobalBusinessStack_QueryVariables = {};
export type BusinessService_GetGlobalBusinessStack_QueryResponse = {
    readonly globalBusinessStack: {
        readonly id: string;
        readonly isSelf: boolean;
        readonly stackDomainName: string;
    } | null;
};
export type BusinessService_GetGlobalBusinessStack_Query = {
    readonly response: BusinessService_GetGlobalBusinessStack_QueryResponse;
    readonly variables: BusinessService_GetGlobalBusinessStack_QueryVariables;
};



/*
query BusinessService_GetGlobalBusinessStack_Query {
  globalBusinessStack {
    id
    isSelf
    stackDomainName
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalBusinessStack",
    "kind": "LinkedField",
    "name": "globalBusinessStack",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSelf",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stackDomainName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessService_GetGlobalBusinessStack_Query",
    "selections": (v0/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BusinessService_GetGlobalBusinessStack_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bd4e7546e90771dac06d1ddcf0304cd0",
    "id": null,
    "metadata": {},
    "name": "BusinessService_GetGlobalBusinessStack_Query",
    "operationKind": "query",
    "text": "query BusinessService_GetGlobalBusinessStack_Query {\n  globalBusinessStack {\n    id\n    isSelf\n    stackDomainName\n  }\n}\n"
  }
};
})();
(node as any).hash = '27d62e6626fc9198fa7a2d608e7e0c25';
export default node;
