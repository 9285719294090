/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type StackPeopleTable_InternalQueryVariables = {
    email?: string | null | undefined;
    userId?: string | null | undefined;
    deleted?: boolean | null | undefined;
    acceptedInvite?: boolean | null | undefined;
    currentlyEmployed?: boolean | null | undefined;
    unlinkedUser?: boolean | null | undefined;
    pageSize: number;
    after?: string | null | undefined;
};
export type StackPeopleTable_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"StackPeopleTable_viewer">;
};
export type StackPeopleTable_InternalQuery = {
    readonly response: StackPeopleTable_InternalQueryResponse;
    readonly variables: StackPeopleTable_InternalQueryVariables;
};



/*
query StackPeopleTable_InternalQuery(
  $email: String
  $userId: ID
  $deleted: Boolean
  $acceptedInvite: Boolean
  $currentlyEmployed: Boolean
  $unlinkedUser: Boolean
  $pageSize: Int!
  $after: String
) {
  ...StackPeopleTable_viewer
}

fragment StackPeopleTable_viewer on InternalQuery {
  searchStackForEmployments(email: $email, userId: $userId, deleted: $deleted, acceptedInvite: $acceptedInvite, currentlyEmployed: $currentlyEmployed, unlinkedUser: $unlinkedUser, first: $pageSize, after: $after) {
    edges {
      node {
        computedName
        email
        during
        acceptedInvite
        deleted
        userId
        userEmail
        business {
          id
          businessName
        }
        updatedAt
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acceptedInvite"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentlyEmployed"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleted"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unlinkedUser"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v8 = [
  {
    "kind": "Variable",
    "name": "acceptedInvite",
    "variableName": "acceptedInvite"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "currentlyEmployed",
    "variableName": "currentlyEmployed"
  },
  {
    "kind": "Variable",
    "name": "deleted",
    "variableName": "deleted"
  },
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "unlinkedUser",
    "variableName": "unlinkedUser"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StackPeopleTable_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "StackPeopleTable_viewer"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "StackPeopleTable_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "EmploymentConnection",
        "kind": "LinkedField",
        "name": "searchStackForEmployments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Employment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "computedName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "during",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acceptedInvite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Business",
                    "kind": "LinkedField",
                    "name": "business",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "businessName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [
          "email",
          "userId",
          "deleted",
          "acceptedInvite",
          "currentlyEmployed",
          "unlinkedUser"
        ],
        "handle": "connection",
        "key": "StackPeopleTable_searchStackForEmployments",
        "kind": "LinkedHandle",
        "name": "searchStackForEmployments"
      }
    ]
  },
  "params": {
    "cacheID": "fc2376201fbd58b0ffc945a4429ebc52",
    "id": null,
    "metadata": {},
    "name": "StackPeopleTable_InternalQuery",
    "operationKind": "query",
    "text": "query StackPeopleTable_InternalQuery(\n  $email: String\n  $userId: ID\n  $deleted: Boolean\n  $acceptedInvite: Boolean\n  $currentlyEmployed: Boolean\n  $unlinkedUser: Boolean\n  $pageSize: Int!\n  $after: String\n) {\n  ...StackPeopleTable_viewer\n}\n\nfragment StackPeopleTable_viewer on InternalQuery {\n  searchStackForEmployments(email: $email, userId: $userId, deleted: $deleted, acceptedInvite: $acceptedInvite, currentlyEmployed: $currentlyEmployed, unlinkedUser: $unlinkedUser, first: $pageSize, after: $after) {\n    edges {\n      node {\n        computedName\n        email\n        during\n        acceptedInvite\n        deleted\n        userId\n        userEmail\n        business {\n          id\n          businessName\n        }\n        updatedAt\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1d7b4f16d578ff9b7d4090147552d246';
export default node;
