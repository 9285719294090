import React from "react";
import { FormikContextType, useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Dictionary } from "lodash";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Field from "../../../Form/Field";
import { FloatFieldType, IntegerFieldType } from "../../../Form/models";
import { AosConfigBasic, TransformedAosConfigBasic } from "./models";
import Range from "../../../Form/Range";
import DurationInput from "../../../Form/DurationInput";

type FormData = {
  aosConfig: TransformedAosConfigBasic;
};

type Props = {
  values: AosConfigBasic;
  disabled?: boolean;
  aosGroupsByKey: Dictionary<{
    group: number | null;
    name: string | null;
  }>;
};

type RangeInputGroupProps = {
  value?: number | null;
  fieldKey: string;
  disabled?: boolean;
  context: FormikContextType<FormData>;
  min?: number;
  max?: number;
  minLabel?: string;
  maxLabel?: string;
};

const StyledGoalColumn = styled("th")`
  width: 240px;
  max-width: 240px;
`;

const StyledPreferenceColumn = styled("th")`
  width: 320px;
  max-width: 320px;
`;

const StyledRow = styled(Row)`
  .form-label.col {
    max-width: 160px;
  }
`;

function RangeInputGroup({
  value,
  fieldKey,
  disabled,
  context,
  min,
  max,
  ...rest
}: RangeInputGroupProps) {
  return (
    <Row>
      <Field
        size="sm"
        md={4}
        lg={4}
        hideLabel
        disabled={disabled}
        fieldKey={fieldKey}
        schemaFieldType={FloatFieldType}
        min={min}
        max={max}
      />
      <Col md={6} lg={6}>
        <Range
          fieldKey={fieldKey}
          disabled={disabled}
          value={value}
          onChange={(newValue) => {
            context.setFieldValue(fieldKey, newValue);
          }}
          min={min}
          max={max}
          {...rest}
        />
      </Col>
    </Row>
  );
}

export default function OptimizationGoals(props: Props) {
  const { t } = useTranslation();
  const formikContext = useFormikContext<FormData>();
  const { values: aosConfig, disabled, aosGroupsByKey } = props;

  if (!aosConfig) {
    return null;
  }

  let employeeRatings: number[] = [];
  const { shiftConfig } = aosConfig;
  if (shiftConfig) {
    const { custom_settings: customSettings } = shiftConfig as any;
    if (customSettings) {
      employeeRatings = customSettings.map(
        (item: Record<string, any>) => item.empl_rating,
      );
    }
  }

  return (
    <fieldset>
      <Row className="mb-2">
        <Col md={6}>
          <Form.Text className="text-muted">
            {t("aos:basic.sections.optimization_goals.disclaimer")}
          </Form.Text>
        </Col>
      </Row>

      <Table hover size="sm" className="border-bottom">
        <thead>
          <tr>
            <StyledGoalColumn>
              {t("aos:basic.sections.optimization_goals.goals")}
            </StyledGoalColumn>
            <StyledPreferenceColumn>
              {t("aos:basic.sections.optimization_goals.preference")}
            </StyledPreferenceColumn>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t("aos:basic.sections.optimization_goals.overstaffing_penalty")}
            </td>
            <td>
              <RangeInputGroup
                value={aosConfig.overstaffingPenalty}
                fieldKey="aosConfig.overstaffingPenalty"
                disabled={disabled}
                context={formikContext}
                min={0}
                max={10}
                minLabel={t("aos:basic.sections.optimization_goals.never")}
                maxLabel={t(
                  "aos:basic.sections.optimization_goals.highly_prefer",
                )}
              />
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {t("aos:basic.sections.optimization_goals.skill_preference")}
            </td>
            <td>
              <RangeInputGroup
                value={aosConfig.skillPreference}
                fieldKey="aosConfig.skillPreference"
                disabled={disabled}
                context={formikContext}
                min={0}
                max={10}
                minLabel={t(
                  "aos:basic.sections.optimization_goals.no_preference",
                )}
                maxLabel={t(
                  "aos:basic.sections.optimization_goals.highly_prefer",
                )}
              />
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {t(
                "aos:basic.sections.optimization_goals.shift_length_preference",
              )}
            </td>
            <td>
              <RangeInputGroup
                value={aosConfig.shiftLengthPreference}
                fieldKey="aosConfig.shiftLengthPreference"
                disabled={disabled}
                context={formikContext}
                min={0}
                max={10}
                minLabel={t(
                  "aos:basic.sections.optimization_goals.prefer_shorter",
                )}
                maxLabel={t(
                  "aos:basic.sections.optimization_goals.prefer_longer",
                )}
              />
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {t("aos:basic.sections.optimization_goals.penalty_short_parts")}
            </td>
            <td>
              <RangeInputGroup
                value={aosConfig.penaltyShortParts}
                fieldKey="aosConfig.penaltyShortParts"
                disabled={disabled}
                context={formikContext}
                min={0}
                max={10}
                minLabel={t(
                  "aos:basic.sections.optimization_goals.no_preference",
                )}
                maxLabel={t(
                  "aos:basic.sections.optimization_goals.avoid_at_high_cost",
                )}
              />
            </td>
            <td>
              <StyledRow>
                <Field
                  label={t(
                    "aos:basic.sections.optimization_goals.penalty_short_parts_cutoff",
                  )}
                  size="sm"
                  xs={12}
                  md={12}
                  lg={8}
                  disabled={disabled}
                  fieldKey="aosConfig.penaltyShortPartsCutoff"
                  horizontal
                  component={DurationInput}
                  componentProps={{
                    size: "sm",
                    from: "minutes",
                    to: "minutes",
                    postfix: t("translation:form.minute_plural"),
                  }}
                />
              </StyledRow>
            </td>
          </tr>
          <tr>
            <td>
              {t("aos:basic.sections.optimization_goals.cost_equal_labour")}
            </td>
            <td>
              <RangeInputGroup
                value={aosConfig.costEqualLabor}
                fieldKey="aosConfig.costEqualLabor"
                disabled={disabled}
                context={formikContext}
                min={0}
                max={10}
                minLabel={t(
                  "aos:basic.sections.optimization_goals.no_preference",
                )}
                maxLabel={t(
                  "aos:basic.sections.optimization_goals.highly_prefer",
                )}
              />
            </td>
            <td>
              <Form.Label>
                {t(
                  "aos:basic.sections.optimization_goals.relative_weight_disclaimer",
                )}
              </Form.Label>
              {employeeRatings.map((i: number, index: number) => {
                const group = aosGroupsByKey[i];
                const groupName = group && group.name ? group.name : "";

                const label = `${t(
                  "aos:basic.sections.optimization_goals.employee_rating_label",
                  {
                    rating: i,
                  },
                )} ${
                  groupName
                    ? t("aos:basic.sections.optimization_goals.group_name", {
                        group: groupName,
                      })
                    : ""
                }`;

                return (
                  <StyledRow key={`row-${i}`}>
                    <Field
                      label={label}
                      description={
                        !groupName
                          ? t("aos:basic.sections.optimization_goals.not_set")
                          : undefined
                      }
                      size="sm"
                      xs={12}
                      md={12}
                      lg={8}
                      horizontal
                      disabled={disabled || aosConfig?.costEqualLabor === 0}
                      fieldKey={`aosConfig.weightEqualLaborByRating.${index}`}
                      schemaFieldType={IntegerFieldType}
                    />
                  </StyledRow>
                );
              })}
            </td>
          </tr>
        </tbody>
      </Table>
    </fieldset>
  );
}
