import React, { Component } from "react";
import { FormikContext } from "formik";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import startCase from "lodash/startCase";
import AddButton from "./AddButton";
import RemoveIcon from "./RemoveIcon";
import { IProperty, SchemaFieldType } from "./models";

import {
  DynamicFormContext,
  DynamicFormContextProps,
} from "../Context/DynamicFormContext";
import { getFieldsByInputObjectName } from "./formUtilities";

type State = {
  topLevelMeasurementInputProperties: IProperty[];
};

type TopLevelForecastMeasurement = {
  name: string;
  label: string;
  isPrimary: boolean;
  isCurrency: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof TopLevelForecastMeasurements.defaultProps & {
  value: TopLevelForecastMeasurement[];
  fieldKey: string;
  disabled?: boolean;
  onChange: (newValue: TopLevelForecastMeasurement[] | null) => void;
  type: SchemaFieldType;
};

export default class TopLevelForecastMeasurements extends Component<
  Props,
  State
> {
  static contextType = DynamicFormContext;

  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      topLevelMeasurementInputProperties: [],
    };
  }

  componentDidMount() {
    const dynamicFormContext: DynamicFormContextProps = this.context;
    this.setState({
      topLevelMeasurementInputProperties: getFieldsByInputObjectName(
        dynamicFormContext.propertyList || [],
        "AnalyticsTopLevelMeasurementInput",
      ),
    });
  }

  render() {
    const { fieldKey, value, disabled } = this.props;
    const { topLevelMeasurementInputProperties } = this.state;

    return (
      <FormikContext.Consumer>
        {(formikContext) => {
          const rows = (value || []).map(
            (item: TopLevelForecastMeasurement, i: number) => {
              const indexKey = `${fieldKey}[${i}]`;
              const measurements = topLevelMeasurementInputProperties.map(
                (property: IProperty) => {
                  const { name } = property;
                  const key = `${indexKey}.${name}`;

                  switch (name) {
                    case "isPrimary":
                    case "isCurrency": {
                      const measurementFieldKey = `${fieldKey}[${i}].${name}`;

                      return (
                        <td key={key}>
                          <Form.Group>
                            <Form.Check
                              type="radio"
                              disabled={disabled}
                              checked={(item[name] as boolean) || false}
                              onClick={(
                                e: React.MouseEvent<HTMLInputElement>,
                              ) => {
                                // make radio button deselectable
                                if (item[name]) {
                                  e.currentTarget.checked = false;
                                  formikContext.setFieldValue(
                                    measurementFieldKey,
                                    false,
                                  );
                                }
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const isChecked = e.currentTarget.checked;
                                if (isChecked) {
                                  value.forEach(
                                    (
                                      valueItem: TopLevelForecastMeasurement,
                                      valueIndex: number,
                                    ) => {
                                      const valueFieldKey = `${fieldKey}[${valueIndex}].${name}`;
                                      // if current measurement isPrimary or isCurrency is true, make sure others are set to false
                                      // so that there can only be one isPrimary and isCurrency measurement
                                      const newValue =
                                        valueIndex === i ? isChecked : false;

                                      const fieldHelper =
                                        formikContext.getFieldHelpers(
                                          valueFieldKey,
                                        );
                                      fieldHelper.setValue(newValue);
                                    },
                                  );
                                }
                              }}
                            />
                          </Form.Group>
                        </td>
                      );
                    }
                    default: {
                      return (
                        <td key={key}>
                          <Form.Control
                            name={key}
                            disabled={disabled}
                            type="text"
                            value={(item as any)[name] || ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              formikContext
                                .getFieldHelpers(key)
                                .setValue(e.currentTarget.value);
                            }}
                          />
                        </td>
                      );
                    }
                  }
                },
              );

              return (
                <tr key={indexKey}>
                  {measurements}
                  <td>
                    <RemoveIcon
                      formikContext={formikContext}
                      fieldKey={fieldKey}
                      index={i}
                      disabled={disabled}
                    />
                  </td>
                </tr>
              );
            },
          );

          return (
            <>
              <Table responsive size="sm">
                <thead>
                  <tr>
                    {topLevelMeasurementInputProperties.map(
                      (property: IProperty) => (
                        <th key={property.name}>{startCase(property.name)}</th>
                      ),
                    )}
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
              <AddButton
                disabled={disabled}
                formikContext={formikContext}
                name={fieldKey}
                defaultValue={{
                  name: "",
                  label: "",
                  isPrimary: false,
                  isCurrency: false,
                }}
              />
            </>
          );
        }}
      </FormikContext.Consumer>
    );
  }
}
