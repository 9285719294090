import React, { FunctionComponent, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { QueryRenderer, graphql } from "react-relay";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { useTranslation } from "react-i18next";
import StackTable from "./StackTable";
import { Id } from "../../../../models/common";
import HeaderPortal from "../../../Portal/HeaderPortal";
import Searchable from "../../../Searchable/Searchable";
import { idmInternalEnvironment } from "../../../../environment";
import { Stacks_InternalQuery } from "./__generated__/Stacks_InternalQuery.graphql";
import Loader from "../../../common/Loader";

const StacksQuery = graphql`
  query Stacks_InternalQuery(
    # $searchValue: String!
    $pageSize: Int!
    $after: String
  ) {
    ...StackTable_viewer
  }
`;

interface MatchParams {
  stack_id: Id;
}

type Props = RouteComponentProps<MatchParams> & {};

const Stacks: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation("stacks");
  const [search, setSearch] = useState<string>("");
  const onSearchChange = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      <Row className="my-2">
        <Col>
          <Searchable
            className="stacks"
            searchValue={search}
            onSearchChange={onSearchChange}
            placeholder={t("stacksTable.searchPlaceholder")}
          />
        </Col>
        <Col md="auto">
          <LinkContainer to="/stack/create">
            <Button>{t("stacksTable.newStack")}</Button>
          </LinkContainer>
        </Col>
      </Row>

      <Card body>
        <QueryRenderer<Stacks_InternalQuery>
          environment={idmInternalEnvironment}
          query={StacksQuery}
          variables={{
            // searchValue: searchValue,
            pageSize: 20,
          }}
          render={({ error, props: p }) => {
            if (error) {
              return <div>Error!</div>;
            }
            if (!p) {
              return <Loader />;
            }
            return <StackTable {...props} searchValue={search} viewer={p} />;
          }}
        />
      </Card>
      <HeaderPortal>Stacks</HeaderPortal>
    </>
  );
};

export default Stacks;
