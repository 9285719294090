import React, { PropsWithChildren } from "react";
import { useLazyLoadQuery } from "react-relay";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import {
  AuditLogsQueries_DetailsQuery,
  AuditLogsQueries_DetailsQueryResponse,
} from "../__generated__/AuditLogsQueries_DetailsQuery.graphql";
import JsonEditor from "../../../Form/JsonEditor";
import { detailsQuery } from "../AuditLogsQueries";
import { formatDate } from "../../../../utils/utility";

type ResponseType = {} & AuditLogsQueries_DetailsQueryResponse["auditLogs"];
type NodeType = {} & ResponseType["nodes"];
export type AuditLogDetail = {} & NodeType[number];

const ValueField = ({
  title,
  description,
  children,
}: PropsWithChildren<{ title: string; description?: string }>) => {
  return (
    <Row>
      <Col sm={3}>
        {description ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">{description}</Tooltip>}
          >
            <span>{title}</span>
          </OverlayTrigger>
        ) : (
          title
        )}
      </Col>
      <Col sm={9} className="text-muted">
        {children}
      </Col>
    </Row>
  );
};

type Props = {
  businessId: string;
  id: string;
  onClose: Function;
};

function AuditLogDetailsModal({ id, businessId, onClose }: Props) {
  const data = useLazyLoadQuery<AuditLogsQueries_DetailsQuery>(
    detailsQuery,
    { ids: [id], businessId },
    { fetchPolicy: "store-or-network" },
  );

  const { t } = useTranslation("audit-logs");
  const cancelModal = () => {
    onClose();
  };

  const auditLog = data.auditLogs?.nodes?.[0];
  if (!auditLog) {
    return null;
  }

  const {
    actionType,
    specificAction,
    related,
    meta,
    objType,
    performedBy,
    occurredAt,
    relatedToId,
    objId,
    requestId,
    code,
    schedule,
    scheduleId,
  } = auditLog;

  return (
    <Modal size="lg" show onHide={cancelModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("auditLogDetails.title", {
            objectType: objType,
            action: specificAction ?? actionType,
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <ValueField title={t("auditLogDetails.occurrance")}>
                {formatDate(occurredAt, {
                  toFormat: "dd MMM yyyy hh:mm a z",
                  showRelative: true,
                })}
                {performedBy && (
                  <>
                    <span className="mx-1">
                      {t("auditLogDetails.by", {
                        name: performedBy.computedName,
                      })}
                    </span>
                  </>
                )}
              </ValueField>
            </Card.Header>
          </Card>

          {scheduleId && (
            <Card>
              <Card.Header>
                <ValueField title={t("auditLogDetails.schedule")}>
                  {schedule?.scheduleName}
                </ValueField>
              </Card.Header>
            </Card>
          )}

          {relatedToId && (
            <Card>
              <Card.Header>
                <ValueField
                  title={t("auditLogDetails.relatedToId.label")}
                  description={t("auditLogDetails.relatedToId.description")}
                >
                  {relatedToId}
                </ValueField>
              </Card.Header>
            </Card>
          )}

          {objId && (
            <Card>
              <Card.Header>
                <ValueField
                  title={t("auditLogDetails.objectId.label")}
                  description={t("auditLogDetails.objectId.description")}
                >
                  {objId}
                </ValueField>
              </Card.Header>
            </Card>
          )}

          {requestId && (
            <Card>
              <Card.Header>
                <ValueField
                  title={t("auditLogDetails.requestId.label")}
                  description={t("auditLogDetails.requestId.description")}
                >
                  {requestId}
                </ValueField>
              </Card.Header>
            </Card>
          )}

          {code && (
            <Card>
              <Card.Header>
                <ValueField
                  title={t("auditLogDetails.code.label")}
                  description={t("auditLogDetails.code.description")}
                >
                  {code}
                </ValueField>
              </Card.Header>
            </Card>
          )}

          {meta && (
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  {t("auditLogDetails.meta.label")}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <JsonEditor
                    key="meta"
                    value={meta as {}}
                    name="meta"
                    disabled
                  />
                  <Form.Text className="text-muted">
                    {t("auditLogDetails.meta.description")}
                  </Form.Text>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}

          {related && (
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  {t("auditLogDetails.related.label")}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <JsonEditor
                    key="related"
                    value={related as {}}
                    name="related"
                    disabled
                  />
                  <Form.Text className="text-muted">
                    {t("auditLogDetails.related.description")}
                  </Form.Text>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelModal}>
          {t("auditLogDetails.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AuditLogDetailsModal;
