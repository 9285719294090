import React, { Component, FormEvent } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { TFunction } from "i18next";
import { FormikProps } from "formik";
import * as yup from "yup";

import JsonEditor from "../../../Form/JsonEditor";
import {
  RuleSet,
  RuleSetContents,
  RuleSetFetch,
  ScheduleRulesInfo,
  SubscriptionStatus,
} from "./models";

import Footer from "../../../Form/Footer";
import { BaseOption, Option } from "../../../../models/common";

type RulesConfigurationFormProps = {
  t: TFunction;
  formik: FormikProps<ScheduleRulesInfo>;
  ruleSets: RuleSetFetch;
  fetchAllRules(): void;
  removeRules(): void;
};

type State = {};

const findHighestProductionVersion = (
  ruleSet: RuleSet,
): RuleSetContents | undefined => {
  // Note: contents is always in descending version order
  return ruleSet.contents.find((c) => c.level.toLowerCase() === "production");
};

const createOption = (labelAndValue: any) => {
  return { label: labelAndValue, value: labelAndValue };
};

const subscriptionOptions = [
  { value: SubscriptionStatus.NOT_SUBSCRIBED, label: "Not Subscribed" },
  { value: SubscriptionStatus.STAGING, label: "Staging" },
  { value: SubscriptionStatus.PRODUCTION, label: "Production" },
];

export const RULES_CONFIG_VALIDATION = Object.freeze(
  yup.object({
    ruleSetName: yup.string(),
    ruleSetSubscriptionStatus: yup.string().required(),
    ruleSetMeta: yup.mixed().isValidJson("Meta"),
    ruleSetVersion: yup
      .string()
      .notRequired()
      .when("ruleSetName", {
        is: (value) => !!value,
        then: yup.string().required().typeError("Version must be selected"),
        else: yup.string().notRequired(),
      }),
  }),
);

export default class RulesConfigurationForm extends Component<
  RulesConfigurationFormProps,
  State
> {
  private readonly jsonRef: React.RefObject<JsonEditor>;

  constructor(props: any) {
    super(props);

    this.jsonRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.jsonRef && this.jsonRef.current) {
      this.jsonRef.current.set(this.props.formik.values.ruleSetMeta);
    }
  }

  private loadingRuleSets = (): boolean => {
    return this.props.ruleSets.loading;
  };

  private processRuleSetsIntoOptions = (): Array<Option<RuleSet>> => {
    return this.props.ruleSets.data.map((rs) => {
      return { label: rs.name, value: rs.name, data: rs };
    });
  };

  private getVersionsFromRuleSet = (
    ruleSetName: string | null,
  ): Array<Option<RuleSetContents>> => {
    if (ruleSetName === null) {
      return [];
    }
    return (
      this.props.ruleSets.data
        .find((rs) => rs.name === ruleSetName)
        ?.contents?.map((c) => {
          return {
            value: c.version,
            label: `${c.version} (${c.level})`,
            data: c,
          };
        }) || []
    );
  };

  private setSubscriptionStatus = (subscriptionStatus: SubscriptionStatus) => {
    this.props.formik.setFieldValue(
      "ruleSetSubscriptionStatus",
      subscriptionStatus,
    );
  };

  private setName = (name: string) => {
    this.props.formik.setFieldValue("ruleSetName", name);
  };

  private handleRulesetChange = (selected: Option<RuleSet>) => {
    this.setName(selected.value);
    const version =
      selected.data != null
        ? findHighestProductionVersion(selected.data)?.version
        : null;
    if (version == null) {
      this.clearVersion();
    } else {
      this.setVersion(version);
    }
  };

  /**
   * Set the version of the selected rule set to use.
   *
   * If a ruleSetName is non-null, ruleSetVersion also has to be non-null. If the selected ruleSet does not have any
   * associated versions, then it should not be able to be selected in the first place.
   *
   * @param version The version of the ruleset to use. If null, it means the ruleSet was unselected an a version
   * should not be set.
   */
  private setVersion = (version: string | null) => {
    this.props.formik.setFieldValue("ruleSetVersion", version);
  };

  private clearVersion = () => {
    this.setVersion(null);
  };

  private handleSubscriptionStatusChange = (
    values: ScheduleRulesInfo,
    subOptionSelected: BaseOption<SubscriptionStatus, null>,
  ) => {
    // We should always get subOptionSelected of type Option, as subscription status is scalar.
    const subValue = subOptionSelected.value;

    this.setSubscriptionStatus(subValue);

    if (subValue === SubscriptionStatus.NOT_SUBSCRIBED) {
      return;
    }

    const ruleSet = this.props.ruleSets.data.find(
      (r) => r.name === values.ruleSetName,
    );
    if (ruleSet == null || ruleSet.contents.length === 0) {
      return;
    }

    let contents: RuleSetContents | undefined;
    if (subValue === SubscriptionStatus.PRODUCTION) {
      contents = findHighestProductionVersion(ruleSet);
    } else if (subValue === SubscriptionStatus.STAGING) {
      const [c] = ruleSet.contents;
      contents = c;
    }

    if (contents !== undefined) {
      this.setVersion(contents.version);
    }
  };

  render() {
    const { t, formik, fetchAllRules, removeRules } = this.props;
    const { handleSubmit, handleReset, errors, values, initialValues } = formik;
    return (
      <div>
        <Form onSubmit={(e) => handleSubmit(e as FormEvent<HTMLFormElement>)}>
          <Row>
            <Col md={12} lg={5}>
              <Form.Group controlId="ruleSetName">
                <Form.Label>Name</Form.Label>
                <Select
                  cacheOptions
                  isLoading={this.loadingRuleSets()}
                  onChange={(selectedRaw) =>
                    this.handleRulesetChange(selectedRaw as Option<RuleSet>)
                  }
                  value={createOption(values.ruleSetName)}
                  onMenuOpen={() => fetchAllRules()}
                  options={this.processRuleSetsIntoOptions()}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={5}>
              <Form.Group controlId="ruleSetVersion">
                <Form.Label>
                  Version{" "}
                  {errors.ruleSetVersion && (
                    <span className="text-danger">{errors.ruleSetVersion}</span>
                  )}
                </Form.Label>
                <Select
                  onChange={(selected) => {
                    this.setVersion(
                      (selected as Option<RuleSetContents>)?.value,
                    );
                  }}
                  onMenuOpen={() => fetchAllRules()}
                  isLoading={this.loadingRuleSets()}
                  isDisabled={values.ruleSetName == null}
                  value={createOption(values.ruleSetVersion)}
                  options={this.getVersionsFromRuleSet(values.ruleSetName)}
                />
              </Form.Group>
            </Col>
            <Col md={12} lg={5}>
              <Form.Group controlId="ruleSetSubscriptionStatus">
                <Form.Label>Subscription status</Form.Label>
                <Select
                  onChange={(selected) => {
                    if (selected == null) {
                      // We don't want to do anything if null.
                      return;
                    }

                    this.handleSubscriptionStatusChange(
                      values,
                      selected as BaseOption<SubscriptionStatus, null>,
                    );
                  }}
                  isDisabled={values.ruleSetName == null}
                  value={createOption(values.ruleSetSubscriptionStatus)}
                  options={subscriptionOptions}
                />
                <div className="mt-3">
                  <ul>
                    <li>
                      <strong>Not subscribed</strong>: Schedule will never
                      automatically update the rule set version it uses.
                    </li>
                    <li>
                      <strong>Production</strong>: Schedule will automatically
                      update to newer production versions.
                    </li>
                    <li>
                      <strong>Staging</strong>: Schedule will always update to
                      the latest version.
                    </li>
                  </ul>
                </div>
              </Form.Group>
            </Col>
            <Col md={12} lg={5}>
              <Form.Group controlId="ruleSetMeta">
                <Form.Label>Meta</Form.Label>
                <JsonEditor
                  ref={this.jsonRef}
                  value={values.ruleSetMeta}
                  name="ruleSetMeta"
                  mode="code"
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChange={() => {}}
                  onEditable={() => false}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-5">
            <Footer>
              <Button variant="primary" type="submit">
                {t("form.actions.save")}
              </Button>
              <Button
                variant="outline-primary"
                onClick={handleReset}
                className="ml-2"
              >
                {t("form.actions.reset")}
              </Button>
              <Button
                variant="danger"
                className="ml-4"
                type="button"
                disabled={initialValues.ruleSetName == null}
                onClick={removeRules}
              >
                Remove rules
              </Button>
            </Footer>
          </div>
        </Form>
      </div>
    );
  }
}
