import React, { FunctionComponent } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import CorporateProfile from "./GlobalBusinessProfile";
import { useAppContext } from "../../IDM/external/Context/AppContext";
import { getRegionalStackEnvironment } from "../../../environment";

interface MatchParams {
  stack_id: string;
  global_business_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const GlobalBusinessLayout: FunctionComponent<Props> = (props: Props) => {
  const { match } = props;
  const { stack } = useAppContext();
  const { t } = useTranslation("global-business");

  if (!stack) {
    return null;
  }

  const environment = getRegionalStackEnvironment(stack.domainName);

  return (
    <>
      <header className="sub-header-container">
        <Nav variant="tabs">
          <LinkContainer to={`${match.url}`} exact>
            <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
          </LinkContainer>
        </Nav>
      </header>
      <div className="mt-4">
        <Route
          exact
          path={`${match.path}`}
          render={() => (
            <CorporateProfile environment={environment} {...props} />
          )}
        />
      </div>
      <footer />
    </>
  );
};

export default GlobalBusinessLayout;
