import React from "react";
import {
  graphql,
  createFragmentContainer,
  Environment,
  fetchQuery,
} from "react-relay";
import { Link, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormikContext, FormikContextType } from "formik";
import omit from "lodash/omit";
import { Profile_template } from "./__generated__/Profile_template.graphql";
import properties from "../../../data/aos-config-template-create-settings.json";
import FormLayout from "../../Form/FormLayout";
import FormLayoutFooter from "../../Form/FormLayoutFooter";
import { IProperty } from "../../Form/models";

import { BusinessId, Id } from "../../../models/common";
import BasicFields from "../Schedule/AOS/Basic/BasicFields";
import UpdateAosConfigTemplateMutation from "../mutations/UpdateAosConfigTemplateMutation";
import CreateAosConfigTemplateMutation from "../mutations/CreateAosConfigTemplateMutation";
import { UpdateAosConfigTemplateMutationResponse } from "../mutations/__generated__/UpdateAosConfigTemplateMutation.graphql";
import {
  Profile_ScheduleAOSConfigQuery,
  Profile_ScheduleAOSConfigQueryResponse,
} from "./__generated__/Profile_ScheduleAOSConfigQuery.graphql";
import {
  TransformedAosConfigBasic,
  AosConfigBasic,
} from "../Schedule/AOS/models";
import TimeRangeRanks, {
  DaypartRanksObject,
  WeeklyDaypartObject,
} from "../Schedule/AOS/TimeRangeRanks";
import DynamicInputGroup from "../../Form/DynamicInputGroup";
import {
  getFieldsByNames,
  getSettingsByGroup,
  ServerValidationConfig,
} from "../../Form/formUtilities";
import { getBasicAOSConfigSchema } from "../Schedule/AOS/aosUtils";
import { BusinessContext } from "../../Context/BusinessContext";
import AdvancedFields from "../Schedule/AOS/Advanced/AdvancedFields";
import HeaderPortal from "../../Portal/HeaderPortal";
import ModalContext from "../../Context/ModalContextLegacy";
import AOSTemplateModal, { AOSModalEnum } from "./AOSTemplateModal";
import AOSTemplateService, {
  AOSConfigTemplate,
} from "../Services/AOSTemplateService";

const query = graphql`
  query Profile_ScheduleAOSConfigQuery($businessId: ID!, $scheduleId: ID!) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      nodes {
        id
        scheduleName
        aosConfig {
          schedulingDayStart
          addUnderstaffingShifts
          weeklyWorkhoursMin
          weeklyWorkhoursMax
          shiftMin
          shiftMax
          shiftMinAbsolute
          shiftGapMin
          shiftMaxStart
          maxRolesPerShift
          minShiftPartLength
          breakTrigger
          breakLength
          breakMinStart
          breakMaxStart
          breakEndPad
          includeRoles
          demandOnly
          bonusRoles
          ignoreBreakRoles
          flexibleRole
          coreObeysAosRules
          skipSchedulingManagers
          skipSchedulingCore
          addCoreBreaks
          optimizeCoreBreaks
          weeklyDayparts
          daypartRanks
          planningOrder
          overstaffingPenalty
          skillPreference
          shiftLengthPreference
          penaltyShortParts
          penaltyShortPartsCutoff
          costEqualLabor
          weightEqualLaborByRating
          applyRule24HoursRest
          maxWorkdaysCalweek
          maxWorkdaysWorkweek
          overstaffingByRole
          understaffingByRole
          shiftConfig
          aosTimeout
          aosCombinedRoles
          aosOrchestratorConfig
          replanningTimeout
          replanningOrchestratorConfig
        }
      }
    }
  }
`;

type NodesType =
  {} & Profile_ScheduleAOSConfigQueryResponse["schedules"]["nodes"];
type ScheduleAosConfig = {} & NodesType[number];

interface MatchParams {
  stack_id: string;
  business_id: string;
  aos_template_id?: string;
  schedule_id?: string; // defined if create new template from schedule settings
  clone_from_id?: string; // defined if clone from another template
}

type Props = RouteComponentProps<MatchParams> &
  WithTranslation & {
    template: Profile_template | null;
    environment: Environment;
    location: {
      query?: {
        aosConfig?: AosConfigBasic;
      };
    };
  };

type State = {
  template: Profile_template | null;
  stackId: Id;
  businessId: BusinessId;
  aosTemplateId?: Id;
  scheduleId?: Id;
  cloneTemplateId?: Id;
  validationRules: yup.ObjectSchema<Partial<Profile_template>>;
  defaults: Record<string, any>;
  modal?: AOSModalEnum;
  scheduleAosConfig?: ScheduleAosConfig;
  cloneTemplateAosConfig?: AOSConfigTemplate;
};

type FormData = Profile_template & {
  aosConfig: TransformedAosConfigBasic;
};

class Profile extends React.Component<Props, State> {
  static contextType = BusinessContext;

  context!: React.ContextType<typeof BusinessContext>;

  constructor(props: Props) {
    super(props);

    const { match } = this.props;
    const {
      params: {
        aos_template_id: aosTemplateId,
        business_id: businessId,
        stack_id: stackId,
        schedule_id: scheduleId,
        clone_from_id: cloneTemplateId,
      },
    } = match;

    this.state = {
      template: props.template,
      stackId,
      businessId,
      aosTemplateId,
      scheduleId,
      cloneTemplateId,
      validationRules: yup.object({}),
      defaults: {},
    };
  }

  componentDidMount() {
    const { business, environment } = this.context;
    const { businessId, scheduleId, cloneTemplateId } = this.state;

    this.setupAOSConfigSchema(
      business?.aosConfigSchema as ServerValidationConfig,
    );

    if (scheduleId && environment) {
      this.fetchScheduleAosSetting(environment, businessId, scheduleId);
    } else if (cloneTemplateId && environment) {
      this.fetchTemplateToCloneFrom(environment, businessId, cloneTemplateId);
    }
  }

  private setupAOSConfigSchema(aosConfigSchema: ServerValidationConfig) {
    const { t } = this.props;

    const result = getBasicAOSConfigSchema(
      t,
      aosConfigSchema,
      properties as unknown as IProperty[],
      true,
    );

    const validationRules = yup.object({
      templateName: yup
        .string()
        .required()
        .label(t("aos:aosTemplate.templateName")),
      aosConfig: result.aosConfigValidationRules,
    });

    this.setState({
      validationRules: validationRules as unknown as yup.ObjectSchema<
        Partial<Profile_template>
      >,
      defaults: result.defaults,
    });
  }

  private handleSave = (
    changes: Partial<FormData>,
    onError: (err: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: FormData,
  ) => {
    const { environment, t } = this.props;
    const { businessId, aosTemplateId } = this.state;

    const toServerData = (
      aosConfigFormData?: Partial<TransformedAosConfigBasic>,
    ) => {
      if (!aosConfigFormData) {
        return {};
      }
      const temp = omit(aosConfigFormData, [
        "weeklyDaypartsData",
        "useGlobalPreference",
        "globalPreference",
      ]);

      const extraData = TimeRangeRanks.fromFormData(values?.aosConfig);
      return {
        ...temp,
        ...extraData,
      };
    };

    if (aosTemplateId) {
      const aosConfig = toServerData(changes.aosConfig);
      return UpdateAosConfigTemplateMutation(
        environment,
        businessId,
        aosTemplateId,
        {
          ...changes,
          aosConfig,
        },
        (response: UpdateAosConfigTemplateMutationResponse) => {
          this.setState({
            template:
              response.updateAosConfigTemplate as unknown as Profile_template,
          });
          toast(t("aosTemplate.saved"));
        },
        onError,
      );
    }

    const aosConfig = toServerData(values?.aosConfig);
    return CreateAosConfigTemplateMutation(
      environment,
      businessId,
      {
        ...values,
        aosConfig,
      },
      () => {
        const { history } = this.props;
        const { stackId } = this.state;
        history.push(
          `/stack/${stackId}/business/${businessId}/profile/aos_templates`,
        );
      },
      onError,
    );
  };

  private async fetchScheduleAosSetting(
    environment: Environment,
    businessId: Id,
    scheduleId: Id,
  ) {
    const result = await fetchQuery<Profile_ScheduleAOSConfigQuery>(
      environment,
      query,
      {
        businessId,
        scheduleId,
      },
    ).toPromise();

    this.setState({
      scheduleAosConfig: result?.schedules?.nodes?.[0] ?? undefined,
    });
  }

  private async fetchTemplateToCloneFrom(
    environment: Environment,
    businessId: Id,
    cloneTemplateId: Id,
  ) {
    const result = await AOSTemplateService.getTemplateById(
      environment,
      businessId,
      cloneTemplateId,
    );

    this.setState({
      cloneTemplateAosConfig: result || undefined,
    });
  }

  render() {
    const {
      validationRules,
      defaults,
      aosTemplateId,
      businessId,
      stackId,
      scheduleId,
      cloneTemplateId,
      cloneTemplateAosConfig,
      modal,
      scheduleAosConfig,
    } = this.state;
    let { template: item } = this.state;
    const { t, ...rest } = this.props;
    const { business } = this.context;

    const isCreate = aosTemplateId == null || cloneTemplateId != null;

    const getDefaultTemplate = (
      aosConfig: Record<string, any> | null = {},
      otherProps = {},
    ) => {
      return {
        templateName: "",
        isDefault: false,
        ...otherProps,
        aosConfig,
      } as Profile_template;
    };

    if (!item) {
      if (scheduleId) {
        if (!scheduleAosConfig) {
          // wait for object load
          return null;
        }

        item = getDefaultTemplate(scheduleAosConfig.aosConfig);
      } else if (cloneTemplateId) {
        if (!cloneTemplateAosConfig) {
          // wait for object load
          return null;
        }

        item = getDefaultTemplate(cloneTemplateAosConfig.aosConfig, {
          templateName: `${cloneTemplateAosConfig.templateName} copy`,
        });
      } else {
        item = getDefaultTemplate(defaults);
      }
    }

    const { aosConfig } = item || {};
    if (!aosConfig) {
      return null;
    }

    const extraData = TimeRangeRanks.toFormData(
      aosConfig.weeklyDayparts as WeeklyDaypartObject,
      aosConfig.daypartRanks as DaypartRanksObject,
    );

    const formBase: FormData = {
      ...item,
      aosConfig: {
        ...aosConfig,
        ...extraData,
      },
    } as FormData;

    const getBreadcrumbTitle = () => {
      if (aosTemplateId) {
        return item?.templateName;
      }

      if (scheduleId) {
        return t("aosTemplate.saveAsNewTemplate");
      }

      if (cloneTemplateId) {
        return t("aosTemplate.cloneTemplate");
      }

      return t("aosTemplate.newTemplate");
    };

    if (item) {
      return (
        <ModalContext.Consumer>
          {(modalProps) => (
            <div className="panel">
              <HeaderPortal>
                <Link to={`/stack/${stackId}/business/${businessId}/profile`}>
                  {business?.businessName}
                </Link>
                <span className="ml-2 mr-2">&gt;</span>
                {!scheduleAosConfig ? (
                  <span>
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/profile/aos_templates`}
                    >
                      <span>{t("aosTemplate.aosTemplates")}</span>
                    </Link>
                  </span>
                ) : (
                  <span>
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/schedule/${scheduleAosConfig.id}/aos`}
                    >
                      <span>{scheduleAosConfig.scheduleName}</span>
                    </Link>
                  </span>
                )}

                <span className="ml-2 mr-2">&gt;</span>
                <span id="schedule-header">{getBreadcrumbTitle()}</span>
              </HeaderPortal>
              <FormLayout<FormData>
                isCreate={isCreate}
                base={formBase}
                onSave={this.handleSave}
                propertyList={properties as unknown as IProperty[]}
                validationRules={validationRules}
                // componentRules={componentRules}
              >
                <FormikContext.Consumer>
                  {(context: FormikContextType<FormData>) => {
                    const { values } = context;

                    return values ? (
                      <>
                        <fieldset>
                          <DynamicInputGroup
                            hideGroupName
                            fields={getSettingsByGroup(
                              getFieldsByNames(
                                properties as unknown as IProperty[],
                                ["templateName", "isDefault"],
                              ),
                            )}
                          />
                        </fieldset>
                        <BasicFields
                          {...rest}
                          values={values}
                          properties={properties as unknown as IProperty[]}
                          t={t}
                          disabled={false}
                        />
                        <AdvancedFields
                          values={values}
                          properties={properties as unknown as IProperty[]}
                          disabled={false}
                        />

                        <FormLayoutFooter
                          isCreate={isCreate}
                          onDelete={() => {
                            this.setState({
                              modal: AOSModalEnum.ComfirmDelete,
                            });

                            modalProps.showModal();
                          }}
                        />

                        <AOSTemplateModal
                          {...rest}
                          modal={modal}
                          businessId={businessId}
                          template={item}
                          t={t}
                          onDeletedCallback={() => {
                            this.props.history.push(
                              `/stack/${stackId}/business/${businessId}/profile/aos_templates`,
                            );
                          }}
                        />
                      </>
                    ) : null;
                  }}
                </FormikContext.Consumer>
              </FormLayout>
            </div>
          )}
        </ModalContext.Consumer>
      );
    }
    return <div>{t("aosTemplate.templateNotFound")}</div>;
  }
}

export default createFragmentContainer(
  withTranslation("aos")(Profile),
  // Each key specified in this object will correspond to a prop available to the component
  {
    template: graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment Profile_template on AosConfigTemplate {
        id
        ### Replaceable content start
        templateName
        isDefault
        aosConfig {
          shiftConfig
          schedulingDayStart
          addUnderstaffingShifts
          weeklyWorkhoursMin
          weeklyWorkhoursMax
          shiftMin
          shiftMax
          shiftMinAbsolute
          shiftGapMin
          shiftMaxStart
          maxRolesPerShift
          minShiftPartLength
          breakTrigger
          breakLength
          breakMinStart
          breakMaxStart
          breakEndPad
          includeRoles
          demandOnly
          bonusRoles
          ignoreBreakRoles
          flexibleRole
          coreObeysAosRules
          skipSchedulingManagers
          skipSchedulingCore
          addCoreBreaks
          optimizeCoreBreaks
          weeklyDayparts
          daypartRanks
          planningOrder
          overstaffingPenalty
          skillPreference
          shiftLengthPreference
          penaltyShortParts
          penaltyShortPartsCutoff
          costEqualLabor
          weightEqualLaborByRating
          applyRule24HoursRest
          maxWorkdaysCalweek
          maxWorkdaysWorkweek
          overstaffingByRole
          understaffingByRole
          aosTimeout
          aosCombinedRoles
          aosOrchestratorConfig
          replanningTimeout
          replanningOrchestratorConfig
        }
        ### Replaceable content finish
      }
    `,
  },
);
