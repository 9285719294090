import { commitMutation, graphql, Environment } from "react-relay";

const mutation = graphql`
  mutation UpdatePayPeriodMutation(
    $businessId: ID!
    $id: ID!
    $payPeriodStatus: PayPeriodStatus!
  ) {
    updatePayPeriod(
      businessId: $businessId
      id: $id
      payPeriodStatus: $payPeriodStatus
    ) {
      id
      payPeriodStatus
    }
  }
`;

export default (
  environment: Environment,
  id: string,
  businessId: string,
  payPeriodStatus: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    businessId,
    id,
    payPeriodStatus,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
