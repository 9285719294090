import Form from "react-bootstrap/Form";
import React, { useEffect, useRef } from "react";
import { FormCheckProps } from "react-bootstrap";

export enum IndeterminateCheckboxState {
  UNCHECKED,
  INDETERMINATE,
  CHECKED,
}

type Props = FormCheckProps & {
  label?: string;
  value: IndeterminateCheckboxState;
  onStateChange: (newValue: IndeterminateCheckboxState) => any;
};

const IndeterminateCheckbox: React.FunctionComponent<Props> = (props) => {
  const { value, onStateChange, label, ...otherProps } = props;
  const checkRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkRef && checkRef.current) {
      checkRef.current.checked = value === IndeterminateCheckboxState.CHECKED;
      checkRef.current.indeterminate =
        value === IndeterminateCheckboxState.INDETERMINATE;
    }
  }, [value]);

  return (
    <Form.Check type="checkbox" className="d-inline-block">
      <Form.Check.Input
        {...otherProps}
        type="checkbox"
        ref={checkRef}
        onChange={() => {
          switch (value) {
            case IndeterminateCheckboxState.UNCHECKED:
            case IndeterminateCheckboxState.INDETERMINATE:
              return onStateChange(IndeterminateCheckboxState.CHECKED);
            case IndeterminateCheckboxState.CHECKED:
            default:
              return onStateChange(IndeterminateCheckboxState.UNCHECKED);
          }
        }}
      />
      <Form.Check.Label>{label}</Form.Check.Label>
    </Form.Check>
  );
};

export default IndeterminateCheckbox;
