/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SkillLevelAssignmentActionEnum = "none" | "warn" | "%future added value";
export type Profile_skillLevel = {
    readonly name: string;
    readonly isDefault: boolean;
    readonly level: string;
    readonly aosLevel: number | null;
    readonly assignmentAction: SkillLevelAssignmentActionEnum;
    readonly " $refType": "Profile_skillLevel";
};
export type Profile_skillLevel$data = Profile_skillLevel;
export type Profile_skillLevel$key = {
    readonly " $data"?: Profile_skillLevel$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Profile_skillLevel">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Profile_skillLevel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aosLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignmentAction",
      "storageKey": null
    }
  ],
  "type": "SkillLevel",
  "abstractKey": null
};
(node as any).hash = '77bdfbbe428911514675ca9ea9a36be7';
export default node;
