import { commitMutation, graphql, Environment } from "react-relay";

const mutation = graphql`
  mutation UpdateScheduleAOSBasicMutation(
    $input: ScheduleUpdateInput!
    $id: ID!
    $businessId: ID!
  ) {
    updateSchedule(businessId: $businessId, id: $id, input: $input) {
      id
      aosConfigSchema
      populateScheduleEnabled
      autoPopulateScheduleEnabled
      autoPopulateScheduleDaysInAdvance
      autoPopulateScheduleAtTime
      labourCostPercentageEnabled
      labourCostPercentage
      aosConfig {
        schedulingDayStart
        addUnderstaffingShifts
        weeklyWorkhoursMin
        weeklyWorkhoursMax
        shiftMin
        shiftMax
        shiftMinAbsolute
        shiftGapMin
        shiftMaxStart
        maxRolesPerShift
        minShiftPartLength
        breakTrigger
        breakLength
        breakMinStart
        breakMaxStart
        breakEndPad
        includeRoles
        demandOnly
        bonusRoles
        ignoreBreakRoles
        flexibleRole
        coreObeysAosRules
        skipSchedulingManagers
        skipSchedulingCore
        addCoreBreaks
        optimizeCoreBreaks
        weeklyDayparts
        daypartRanks
        planningOrder
        overstaffingPenalty
        skillPreference
        shiftLengthPreference
        penaltyShortParts
        penaltyShortPartsCutoff
        costEqualLabor
        weightEqualLaborByRating
        applyRule24HoursRest
        maxWorkdaysCalweek
        maxWorkdaysWorkweek
        overstaffingByRole
        understaffingByRole
        shiftConfig
        aosTimeout
        aosCombinedRoles
        aosOrchestratorConfig
        replanningTimeout
        replanningOrchestratorConfig
      }
    }
  }
`;

export default (
  environment: Environment,
  input: {},
  id: string,
  businessId: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    input,
    id,
    businessId,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
