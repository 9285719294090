/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BusinessQueriesMutations_InternalQueryVariables = {
    searchValue: string;
    pageSize: number;
    after?: string | null | undefined;
};
export type BusinessQueriesMutations_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"BusinessQueriesMutations_ListFragment">;
};
export type BusinessQueriesMutations_InternalQuery = {
    readonly response: BusinessQueriesMutations_InternalQueryResponse;
    readonly variables: BusinessQueriesMutations_InternalQueryVariables;
};



/*
query BusinessQueriesMutations_InternalQuery(
  $searchValue: String!
  $pageSize: Int!
  $after: String
) {
  ...BusinessQueriesMutations_ListFragment
}

fragment BusinessQueriesMutations_ListFragment on InternalQuery {
  businesses(search: $searchValue, first: $pageSize, after: $after) {
    edges {
      node {
        id
        businessName
        shortDescription
        globalBusinessId
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchValue"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchValue"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessQueriesMutations_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BusinessQueriesMutations_ListFragment"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BusinessQueriesMutations_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BusinessConnection",
        "kind": "LinkedField",
        "name": "businesses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Business",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "businessName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "globalBusinessId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "search"
        ],
        "handle": "connection",
        "key": "BusinessTable_businesses",
        "kind": "LinkedHandle",
        "name": "businesses"
      }
    ]
  },
  "params": {
    "cacheID": "ae7ca13de2d424a7b3c34ec15418e846",
    "id": null,
    "metadata": {},
    "name": "BusinessQueriesMutations_InternalQuery",
    "operationKind": "query",
    "text": "query BusinessQueriesMutations_InternalQuery(\n  $searchValue: String!\n  $pageSize: Int!\n  $after: String\n) {\n  ...BusinessQueriesMutations_ListFragment\n}\n\nfragment BusinessQueriesMutations_ListFragment on InternalQuery {\n  businesses(search: $searchValue, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        businessName\n        shortDescription\n        globalBusinessId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a830d417b7e7248efa3d35fb77830950';
export default node;
