/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateGlobalBusinessStackInput = {
    isSelf?: boolean | null | undefined;
    stackDomainName?: string | null | undefined;
};
export type UpdateGlobalBusinessStackMutationVariables = {
    id: string;
    input: UpdateGlobalBusinessStackInput;
};
export type UpdateGlobalBusinessStackMutationResponse = {
    readonly updateGlobalBusinessStack: {
        readonly id: string;
        readonly isSelf: boolean;
        readonly stackDomainName: string;
    };
};
export type UpdateGlobalBusinessStackMutation = {
    readonly response: UpdateGlobalBusinessStackMutationResponse;
    readonly variables: UpdateGlobalBusinessStackMutationVariables;
};



/*
mutation UpdateGlobalBusinessStackMutation(
  $id: ID!
  $input: UpdateGlobalBusinessStackInput!
) {
  updateGlobalBusinessStack(id: $id, input: $input) {
    id
    isSelf
    stackDomainName
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GlobalBusinessStack",
    "kind": "LinkedField",
    "name": "updateGlobalBusinessStack",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSelf",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stackDomainName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateGlobalBusinessStackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateGlobalBusinessStackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e712ff9b4e8c9e180c33b4c9199a1895",
    "id": null,
    "metadata": {},
    "name": "UpdateGlobalBusinessStackMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateGlobalBusinessStackMutation(\n  $id: ID!\n  $input: UpdateGlobalBusinessStackInput!\n) {\n  updateGlobalBusinessStack(id: $id, input: $input) {\n    id\n    isSelf\n    stackDomainName\n  }\n}\n"
  }
};
})();
(node as any).hash = '25e4a1a3a5cee0bdf366fe816238bff0';
export default node;
