import React, { Suspense } from "react";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { Route, RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmploymentMetadataBasicProfile from "./EmploymentMetadataBasicProfile/EmploymentMetadataBasicProfile";
import EmploymentMetadataTimeboxedProfile from "./EmploymentMetadataTimeboxedProfile/EmploymentMetadataTimeboxedProfile";
import Loader from "../../../common/Loader";

type Props = RouteComponentProps & {};

const StyledCard = styled(Card)`
  border: 0;
  border-radius: 0;
`;

export default function EmploymentMetadataLayout({ match }: Props) {
  const { t } = useTranslation("employment");

  return (
    <>
      <Nav variant="tabs" className="layout-tabs">
        <LinkContainer exact to={`${match.url}`}>
          <Nav.Link className="nav-item">{t("metadata.basic.title")}</Nav.Link>
        </LinkContainer>
        <LinkContainer to={`${match.url}/time_boxed`}>
          <Nav.Link className="nav-item">
            {t("metadata.timeboxed.title")}
          </Nav.Link>
        </LinkContainer>
      </Nav>

      <StyledCard body>
        <Suspense fallback={<Loader />}>
          <Route
            exact
            path={`${match.path}`}
            component={EmploymentMetadataBasicProfile}
          />
          <Route
            path={`${match.path}/time_boxed`}
            component={EmploymentMetadataTimeboxedProfile}
          />
        </Suspense>
      </StyledCard>
    </>
  );
}
