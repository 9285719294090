import { graphql } from "react-relay";

export const UpdateBusinessPerformanceSettingsMutation = graphql`
  mutation PerformanceSettingsQueriesMutations_UpdateBusinessPerformanceSettingsMutation(
    $input: BusinessInput!
    $id: ID!
  ) {
    updateBusiness(id: $id, input: $input) {
      id
      ### Replaceable content start
      feedbackReasonEnabled
      feedbackCommentEnabled
      commendationEnabled
      approvalNeededForCommendation
      commendationEmploymentNotifications
      disciplinaryActionEnabled
      approvalNeededForDisciplinaryAction
      disciplinaryActionEmploymentNotifications
      ### Replaceable content finish
    }
  }
`;

export const GetBusinessPerformanceSettingsQuery = graphql`
  query PerformanceSettingsQueriesMutations_PerformanceSettingsQuery(
    $businessId: ID!
  ) {
    businesses(ids: [$businessId]) {
      edges {
        node {
          id
          ### Replaceable content start
          feedbackReasonEnabled
          feedbackCommentEnabled
          commendationEnabled
          approvalNeededForCommendation
          commendationEmploymentNotifications
          disciplinaryActionEnabled
          approvalNeededForDisciplinaryAction
          disciplinaryActionEmploymentNotifications
          ### Replaceable content finish
        }
      }
    }
  }
`;
