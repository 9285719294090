/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQueryVariables = {
    corporateIds?: Array<string> | null | undefined;
};
export type CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQueryResponse = {
    readonly ssoAuthMethods: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly displayName: string;
                readonly code: string;
                readonly authType: string;
                readonly metadataSyncedAt: string | null;
                readonly updatedAt: string;
            } | null;
        } | null> | null;
    };
};
export type CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery = {
    readonly response: CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQueryResponse;
    readonly variables: CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQueryVariables;
};



/*
query CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery(
  $corporateIds: [ID!]
) {
  ssoAuthMethods(corporateIds: $corporateIds) {
    edges {
      node {
        id
        displayName
        code
        authType
        metadataSyncedAt
        updatedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "corporateIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "corporateIds",
        "variableName": "corporateIds"
      }
    ],
    "concreteType": "SSOAuthMethodConnection",
    "kind": "LinkedField",
    "name": "ssoAuthMethods",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SSOAuthMethodEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SSOAuthMethod",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataSyncedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1890cd61784842ea93feda020567ed03",
    "id": null,
    "metadata": {},
    "name": "CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery",
    "operationKind": "query",
    "text": "query CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery(\n  $corporateIds: [ID!]\n) {\n  ssoAuthMethods(corporateIds: $corporateIds) {\n    edges {\n      node {\n        id\n        displayName\n        code\n        authType\n        metadataSyncedAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '93207c486b3c9d9dcb7d74864a06d711';
export default node;
