import React from "react";
import Badge, { BadgeProps } from "react-bootstrap/Badge";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = WithTranslation &
  BadgeProps & {
    employment: {
      readonly acceptedInvite: boolean;
      readonly deleted: boolean;
      readonly during: string | null;
    };
  };

const EmploymentBadge = (props: Props) => {
  const {
    employment: { deleted, acceptedInvite },
    t,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tReady,
    ...restProps
  } = props;
  if (deleted) {
    return (
      <Badge variant="danger" {...restProps}>
        {t("table.rows.status.deleted")}
      </Badge>
    );
  }

  if (!acceptedInvite) {
    return (
      <Badge variant="warning" {...restProps}>
        {t("table.rows.status.invitePending")}
      </Badge>
    );
  }

  return (
    <Badge variant="success" {...restProps}>
      {t("table.rows.status.active")}
    </Badge>
  );
};

export default withTranslation("employment")(EmploymentBadge);
