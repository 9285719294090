import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import isFunction from "lodash/isFunction";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";

import { DurationUnit } from "luxon";
import styled from "styled-components";
import { stringToNumber, convertDuration } from "../../utils/utility";

type Props = {
  size?: "sm" | "lg";
  value?: string | number | null;
  fieldKey: string;
  onChange?: (newValue: string | number | null) => any;
  disabled?: boolean;
  error?: string;
  from?: DurationUnit;
  to?: DurationUnit;
  postfix?: string;
  className?: string;
  allowDecimals?: boolean;
};

const StyledNumberInputGroup = styled(InputGroup)`
  max-width: 120px;
`;

export default function DurationInput(props: Props) {
  const {
    fieldKey,
    value,
    // onChange,
    disabled,
    error,
    from,
    to,
    postfix,
    className,
    allowDecimals = true,
    ...rest
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;
    const v = e.target.value;
    const numValue = stringToNumber(v, null);

    if (isFunction(onChange)) {
      if (isString(numValue)) {
        onChange(v);
        return;
      }

      const newValue = convertDuration(numValue, to, from);
      onChange(newValue);
    }
  };

  const durationValue = convertDuration(value, from, to);
  return (
    <>
      <StyledNumberInputGroup className={className} size={props.size}>
        <Form.Control
          {...rest}
          type="number"
          onKeyDown={(event: any) => {
            if (!allowDecimals && event.key === ".") {
              event.preventDefault();
            }
          }}
          name={fieldKey}
          disabled={disabled}
          isInvalid={error != null}
          value={
            !isUndefined(durationValue) && isNumber(durationValue)
              ? durationValue
              : ""
          }
          onChange={handleChange}
        />
        {postfix ? (
          <InputGroup.Append>
            <InputGroup.Text id="inputGroupAppend">{postfix}</InputGroup.Text>
          </InputGroup.Append>
        ) : null}
      </StyledNumberInputGroup>
    </>
  );
}
