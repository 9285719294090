import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { Translation, withTranslation, WithTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import UnlinkUserFromEmploymentMutation from "../mutations/UnlinkUserFromEmploymentMutation";
import { UnlinkUserFromEmploymentMutationResponse } from "../mutations/__generated__/UnlinkUserFromEmploymentMutation.graphql";
import { ModalProps } from "../../Context/ModalContextLegacy";
import { Id } from "../../../models/common";
import { BusinessContext } from "../../Context/BusinessContext";
import { User } from "./models";
import ServerError from "../../../utils/server-error";

type State = ModalProps & {
  error?: string;
};

class UnlinkUserModal extends React.Component<
  LinkToEmploymentModalProps,
  State
> {
  static contextType = BusinessContext;

  static defaultProps = {};

  constructor(props: LinkToEmploymentModalProps) {
    super(props);
    const temp = { userId: undefined };
    this.state = { ...temp, ...props.modalProps };
  }

  private onOk(e: React.MouseEvent<HTMLButtonElement>) {
    const { onOk, linkedUser } = this.props;
    const { environment, business } = this.context;

    const {
      modalProps: { hideModal },
    } = this.props;

    if (!linkedUser) {
      return;
    }

    UnlinkUserFromEmploymentMutation(
      environment,
      linkedUser.id,
      business.id as Id,
      (response: UnlinkUserFromEmploymentMutationResponse) => {
        const { unlinkUserFromEmployment } = response;
        const { t } = this.props;
        if (unlinkUserFromEmployment) {
          toast(t("employment:linkUserModal.unlinkSuccess"));
          if (isFunction(onOk)) {
            onOk(e, unlinkUserFromEmployment);
          }
          hideModal();
        }
      },
      (error: Error) => {
        const { source } = (error as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        this.setState({
          error: serverError.getErrorDetails(),
        });
      },
    );
  }

  render() {
    const { modalProps, linkedUser } = this.props;
    const { error } = this.state;
    const { show, hideModal } = modalProps;
    if (!linkedUser) {
      return null;
    }
    return (
      <Translation>
        {(t) => (
          <Modal show={show} onHide={hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Unlink User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {linkedUser ? (
                <span>
                  {t("employment:linkUserModal.unlinkFromUser", {
                    email: linkedUser.email,
                  })}
                </span>
              ) : null}
              {error ? (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {error}
                </Form.Control.Feedback>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hideModal}>
                {t("form.actions.cancel")}
              </Button>
              <Button variant="primary" onClick={this.onOk.bind(this)}>
                {t("form.actions.ok")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Translation>
    );
  }
}

type LinkToEmploymentModalProps = typeof UnlinkUserModal.defaultProps &
  WithTranslation & {
    onOk?: (e: React.MouseEvent<HTMLButtonElement>, success: boolean) => void;
    modalProps: State;
    businessId?: Id;
    linkedUser: User | null;
  };

export default withTranslation()(UnlinkUserModal);
