import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Profile from "./Profile";
import { useBusinessContext } from "../../Context/BusinessContext";
import Loader from "../../common/Loader";
import { BusinessProfile_Query } from "./__generated__/BusinessProfile_Query.graphql";
import HeaderPortal from "../../Portal/HeaderPortal";

const BusinessProfileQuery = graphql`
  query BusinessProfile_Query($businessID: ID!) {
    businesses(ids: [$businessID]) {
      edges {
        node {
          ...Profile_business
        }
      }
    }
  }
`;

type Props = RouteComponentProps<{
  business_id: string;
  stack_id: string;
}> & {};

function BusinessProfile({ match, history, location }: Props) {
  const { environment } = useBusinessContext();

  const businessId = match.params.business_id;

  const getProfile = (business: any) => (
    <>
      {business && (
        <HeaderPortal elementId="sub-header-portal" as="span">
          <span className="ml-2 mr-2">&gt;</span>
          <span>General</span>
        </HeaderPortal>
      )}
      <Card body>
        <Profile
          match={match}
          history={history}
          location={location}
          business={business ?? null}
          environment={environment}
        />
      </Card>
    </>
  );

  if (!environment) {
    return null;
  }

  if (!businessId) {
    // Create business
    return getProfile(null);
  }

  return (
    <QueryRenderer<BusinessProfile_Query>
      environment={environment}
      query={BusinessProfileQuery}
      variables={{
        businessID: businessId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>Error! {error.message}</div>;
        }
        if (!props) {
          return <Loader />;
        }

        const nodes = (props?.businesses?.edges ?? []).map((x) => x?.node);

        return getProfile(nodes[0]);
      }}
    />
  );
}

export default BusinessProfile;
