import { graphql } from "react-relay";
import { EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_QueryResponse } from "./__generated__/EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query.graphql";

export const GetEmploymentTypeConfigsQuery = graphql`
  query EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query(
    $businessId: ID!
    $deleted: Boolean
    $search: String
  ) {
    employmentTypeConfigs(
      businessId: $businessId
      deleted: $deleted
      search: $search
    ) {
      nodes {
        id
        ### Replaceable content start
        code
        deleted
        employmentTypeCode
        fixedShiftRestriction
        gmhEnabled
        gmhMinimumDurationError
        gmhMinimumDurationWarning
        isDefault
        name
        shortName
        smallId
        timeClockName
        timeOffComplianceEnabled
        ### Replaceable content end
      }
    }
  }
`;

// Hacky types to avoid false nullability in graphql typings
type EmploymentTypeConfigNodes = Exclude<
  EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_QueryResponse["employmentTypeConfigs"]["nodes"],
  null
>;
export type EmploymentTypeConfigNode = {} & Exclude<
  EmploymentTypeConfigNodes[number],
  null
>;

export const GetSingleEmploymentTypeConfigQuery = graphql`
  query EmploymentTypesQueriesMutations_GetSingleEmploymentTypeConfig_Query(
    $businessId: ID! # $id: ID!
    $deleted: Boolean
    $id: ID!
  ) {
    employmentTypeConfigs(
      businessId: $businessId
      deleted: $deleted
      ids: [$id]
    ) {
      # employmentTypeConfigs(businessId: $businessId, id: $id) {
      nodes {
        id
        ### Replaceable content start
        code
        deleted
        employmentTypeCode
        fixedShiftRestriction
        gmhEnabled
        gmhMinimumDurationError
        gmhMinimumDurationWarning
        isDefault
        name
        shortName
        smallId
        timeClockName
        timeOffComplianceEnabled
        ### Replaceable content end
      }
    }
  }
`;

export const CreateEmploymentTypeConfigMutation = graphql`
  mutation EmploymentTypesQueriesMutations_CreateEmploymentTypeConfig_Mutation(
    $businessId: ID!
    $input: EmploymentTypeConfigCreateInput!
  ) {
    createEmploymentTypeConfig(businessId: $businessId, input: $input) {
      id
      ### Replaceable content start
      code
      deleted
      employmentTypeCode
      fixedShiftRestriction
      gmhEnabled
      gmhMinimumDurationError
      gmhMinimumDurationWarning
      isDefault
      name
      shortName
      smallId
      timeClockName
      timeOffComplianceEnabled
      ### Replaceable content end
    }
  }
`;

export const UpdateEmploymentTypeConfigMutation = graphql`
  mutation EmploymentTypesQueriesMutations_UpdateEmploymentTypeConfig_Mutation(
    $businessId: ID!
    $id: ID!
    $input: EmploymentTypeConfigUpdateInput!
  ) {
    updateEmploymentTypeConfig(
      businessId: $businessId
      id: $id
      input: $input
    ) {
      id
      ### Replaceable content start
      code
      deleted
      employmentTypeCode
      fixedShiftRestriction
      gmhEnabled
      gmhMinimumDurationError
      gmhMinimumDurationWarning
      isDefault
      name
      shortName
      smallId
      timeClockName
      timeOffComplianceEnabled
      ### Replaceable content end
    }
  }
`;

export const DeleteEmploymentTypeConfigMutation = graphql`
  mutation EmploymentTypesQueriesMutations_DeleteEmploymentTypeConfig_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteEmploymentTypeConfig(businessId: $businessId, id: $id)
  }
`;
