/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CorporatesQueryMutations_DeleteCorporateMutation_InternalQueryVariables = {
    id: string;
};
export type CorporatesQueryMutations_DeleteCorporateMutation_InternalQueryResponse = {
    readonly deleteCorporate: boolean;
};
export type CorporatesQueryMutations_DeleteCorporateMutation_InternalQuery = {
    readonly response: CorporatesQueryMutations_DeleteCorporateMutation_InternalQueryResponse;
    readonly variables: CorporatesQueryMutations_DeleteCorporateMutation_InternalQueryVariables;
};



/*
mutation CorporatesQueryMutations_DeleteCorporateMutation_InternalQuery(
  $id: ID!
) {
  deleteCorporate(id: $id)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteCorporate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueryMutations_DeleteCorporateMutation_InternalQuery",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesQueryMutations_DeleteCorporateMutation_InternalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d34d4cddb3bcf0fc2507b941139a198b",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueryMutations_DeleteCorporateMutation_InternalQuery",
    "operationKind": "mutation",
    "text": "mutation CorporatesQueryMutations_DeleteCorporateMutation_InternalQuery(\n  $id: ID!\n) {\n  deleteCorporate(id: $id)\n}\n"
  }
};
})();
(node as any).hash = '8d7c253fa6c96a6d57f03ad4359b7306';
export default node;
