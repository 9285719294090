import React, { useState } from "react";

import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import Footer from "../../../Form/Footer";
import OkCancelModal from "../../../OkCancelModal";
import ApplyTemplateModal from "./ApplyTemplateModal";
import {
  useModalContext,
  withModal,
  WithModalProps,
} from "../../../Context/ModalContextLegacy";
import { useAppRouter } from "../../../hooks/useAppRouter";

enum ModalEnum {
  ResetToDefault,
  ResetToTemplate,
  SaveAsTemplate,
}

type FormData = {
  aosConfig: Record<string, any>;
};

interface MatchParams {
  business_id: string;
  stack_id: string;
  schedule_id: string;
}

type Props = WithModalProps & {
  onReset: () => void;
};

const AOSFooter: React.FunctionComponent<Props> = ({ onReset }) => {
  const router = useAppRouter<MatchParams>();
  const { t } = useTranslation();
  const {
    isSubmitting,
    dirty,
    handleReset,
    values,
    initialValues,
    setFieldValue,
  } = useFormikContext<FormData>();

  const {
    stack_id: stackId,
    business_id: businessId,
    schedule_id: scheduleId,
  } = router.params;

  const { hideModal, showModal } = useModalContext();

  const [modal, setModal] = useState<ModalEnum>();

  const onSaveAsTemplateClicked = () => {
    setModal(ModalEnum.SaveAsTemplate);
    showModal();
  };

  const onResetToDefaultClicked = () => {
    setModal(ModalEnum.ResetToDefault);
    showModal();
  };

  const renderModal = () => {
    switch (modal) {
      case ModalEnum.ResetToDefault:
        return (
          <OkCancelModal
            title={t("aos:setDefaultDialog.title")}
            onOk={() => {
              onReset();
              hideModal();
            }}
          >
            <p>{t("aos:setDefaultDialog.body")}</p>
          </OkCancelModal>
        );

      case ModalEnum.ResetToTemplate:
        return (
          <ApplyTemplateModal
            businessId={businessId}
            onOk={(aosConfigToOverwrite) => {
              const newAosConfig = {
                ...values.aosConfig,
                ...aosConfigToOverwrite.aosConfig,
              };

              setFieldValue("aosConfig", newAosConfig);
              hideModal();
            }}
            currentSettings={initialValues}
          />
        );
      case ModalEnum.SaveAsTemplate: {
        return (
          <OkCancelModal
            title={t("aos:saveAsTemplateDialog.title")}
            okLabel={t("aos:saveAsTemplateDialog.okLabel")}
            onOk={() => {
              router.push(
                `/stack/${stackId}/business/${businessId}/aos_template/create/${scheduleId}`,
              );
            }}
          >
            <p>
              <Trans
                i18nKey="aos:saveAsTemplateDialog.body"
                components={{ bold: <strong /> }}
              />
            </p>
          </OkCancelModal>
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      {renderModal()}
      <Footer>
        <Nav className="mr-auto">
          <Button
            variant="primary"
            onClick={onSaveAsTemplateClicked}
            disabled={dirty}
          >
            {t("aos:actions.saveAsTemplate")}
          </Button>
        </Nav>
        <Nav>
          <DropdownButton
            menuAlign="right"
            className="mr-2"
            id="dropdown-split-variants-secondary"
            variant="secondary"
            title={t("aos:actions.setToDefault")}
            drop="up"
          >
            <Dropdown.Item eventKey="1" onClick={onResetToDefaultClicked}>
              {t("aos:actions.useDefault")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => {
                setModal(ModalEnum.ResetToTemplate);
                showModal();
              }}
            >
              {t("aos:actions.useTemplate")}
            </Dropdown.Item>
          </DropdownButton>
          <Button
            variant="outline-primary"
            onClick={handleReset}
            className="mr-2"
            disabled={!dirty}
          >
            {t("form.actions.reset")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting || !dirty}
          >
            {t("form.actions.save")}
          </Button>
        </Nav>
      </Footer>
    </>
  );
};

export default withModal(AOSFooter);
