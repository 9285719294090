import React, { Suspense } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import MetadataTypesTable from "./MetadataTypesTable";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../Portal/HeaderPortal";
import Searchable from "../../../Searchable/Searchable";
import Loader from "../../../common/Loader";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import { useBusinessContext } from "../../../Context/BusinessContext";
import { useAppRouter } from "../../../hooks/useAppRouter";

export default function MetadataTypes() {
  const router = useAppRouter();
  const { t } = useTranslation("metadata-types");
  const { business } = useBusinessContext();

  const [searchValue, debouncedSearchValue, setSearchValue] =
    useDebouncedValue("");

  const onCreateClick = () => {
    router.push("metadata_types/create");
  };

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[<span>{t("nav.metadataTypes")}</span>]}
        />
      </HeaderPortal>
      <Row className="align-baseline">
        <Col md={5}>
          <Searchable
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            placeholder={t("table.labels.search")}
          />
        </Col>
        <Col md={7} className="d-flex justify-content-end">
          <Button variant="primary" onClick={onCreateClick}>
            {t("table.actions.create")}
          </Button>
        </Col>
      </Row>
      <Card className="mt-4" body>
        <Suspense fallback={<Loader />}>
          {business && (
            <MetadataTypesTable
              search={debouncedSearchValue}
              businessId={business.id}
            />
          )}
        </Suspense>
      </Card>
    </>
  );
}
