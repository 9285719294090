/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteAosConfigTemplateMutationVariables = {
    businessId: string;
    id: string;
};
export type DeleteAosConfigTemplateMutationResponse = {
    readonly deleteAosConfigTemplate: boolean;
};
export type DeleteAosConfigTemplateMutation = {
    readonly response: DeleteAosConfigTemplateMutationResponse;
    readonly variables: DeleteAosConfigTemplateMutationVariables;
};



/*
mutation DeleteAosConfigTemplateMutation(
  $businessId: ID!
  $id: ID!
) {
  deleteAosConfigTemplate(businessId: $businessId, id: $id)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteAosConfigTemplate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAosConfigTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteAosConfigTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ae6b0df71ac1c8aa6ad270cef7b5a391",
    "id": null,
    "metadata": {},
    "name": "DeleteAosConfigTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteAosConfigTemplateMutation(\n  $businessId: ID!\n  $id: ID!\n) {\n  deleteAosConfigTemplate(businessId: $businessId, id: $id)\n}\n"
  }
};
})();
(node as any).hash = 'a4d9a8beff8b70002b8db65e540194d0';
export default node;
